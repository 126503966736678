import {
  EXPERT_UPDATE_PROFILE_FAIL,
  EXPERT_UPDATE_PROFILE_REQUEST,
  EXPERT_UPDATE_PROFILE_RESET,
  EXPERT_UPDATE_PROFILE_SUCCESS,
  EXPERT_REVIEW_CREATE_REQUEST,
  EXPERT_REVIEW_CREATE_SUCCESS,
  EXPERT_REVIEW_CREATE_FAIL,
  EXPERT_REVIEW_CREATE_RESET,
} from "../constants/expertConstants "

export const expertUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERT_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case EXPERT_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case EXPERT_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case EXPERT_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};
export const expertReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERT_REVIEW_CREATE_REQUEST:
      return { loading: true };
    case EXPERT_REVIEW_CREATE_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case EXPERT_REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case EXPERT_REVIEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};