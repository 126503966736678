import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, img, link, description }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title} - Balm - Bringing Happiness To The World</title>
      <meta name="robots" content="noindex, follow" />
      <meta
      name="description"
      content={description}
    />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      <meta property="og:image" content={img} />
      <meta property="og:image:width" content="640" />
      <meta property="og:image:height" content="480" />
      <meta property="og:image" content={img} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="960" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={link} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
