/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import TimeBar from "../FifteenMin/TimeBar/TimeBar";
import "./MeetingScheduler.css";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
// import CalendarGoogle from '../../calendarGoogleApi/CalendarGoogle'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";
import gmeet from "../../data/gmeet.png";

// import TimeSlotContext from '../../App'

function MeetingScheduler() {
  const [isShow, setIsShow] = useState(false);
  const [calendarDetails, setCalendarDetails] = useState({});
  const [details, setDetails] = useState({});
  const [isView, setIsView] = useState(false);

  // event and expert statate
  const [expertTimezone, setExpertTimezone] = useState("");
  const [duration, setDuration] = useState("");
  const dispatch = useDispatch();

  // pramas
  const params = useParams();
  const { id: eventId } = params;
  const { expert } = params;
  const { time } = params;

  // redux
  const addMeetingDetails = useSelector((state) => state.addMeetingDetails);
  const { addMeetingInfo } = addMeetingDetails;

  useEffect(() => {
    if (addMeetingInfo) {
      setDetails(addMeetingInfo);
      setExpertTimezone(addMeetingInfo.expertTimezone.value);
      setDuration(addMeetingInfo.timeDuration.value);
    }
  }, [addMeetingInfo]);

  useEffect(() => {
    if (isShow) {
      showEvents();
    }
  }, [isShow]);

  //   {
  //     "expertIdvalue": "62b83db993899262cb83dd3b",
  //     "userIdvalue": "62dfde9ed915be16e2518869",
  //     "startTime": "2022-08-12T00:00:00.000Z",
  //     "endTime": "2022-08-12T01:00:00.000Z",
  //     "expertTimezone": {
  //         "value": "Africa/Lagos",
  //         "label": "Africa/Lagos GMT+1:00"
  //     },
  //     "userTimezone": "Africa/Lagos",
  //     "expertEmail": "ekeleagbakwuru9@gmail.com",
  //     "userEmail": "ekeleagbakwuru9@gmail.com",
  //     "expertName": "madrid for life",
  //     "userName": "madrid for life",
  //     "finalPrice": 120,
  //     "timeDuration": {
  //         "value": "60",
  //         "label": "60 minutes"
  //     }
  // }

  // fectchinhgtne profiles and event paras end
  //   {
  //     "kind": "calendar#event",
  //     "etag": "\"3319630297472000\"",
  //     "id": "5up7vpidc83no0t0r1ti4pqm1k",
  //     "status": "confirmed",
  //     "htmlLink": "https://www.google.com/calendar/event?eid=NXVwN3ZwaWRjODNubzB0MHIxdGk0cHFtMWsgZWtlbGVhZ2Jha3d1cnU5QG0",
  //     "created": "2022-08-06T19:45:48.000Z",
  //     "updated": "2022-08-06T19:45:48.760Z",
  //     "summary": "Balm Schedule",
  //     "description": "testing balm.ai google meet schedule for expert and customers by stivin",
  //     "location": "Google meet",
  //     "creator": {
  //         "email": "ekeleagbakwuru9@gmail.com",
  //         "self": true
  //     },
  //     "organizer": {
  //         "email": "ekeleagbakwuru9@gmail.com",
  //         "self": true
  //     },
  //     "start": {
  //         "dateTime": "2022-08-12T01:00:00+01:00",
  //         "timeZone": "Africa/Lagos"
  //     },
  //     "end": {
  //         "dateTime": "2022-08-12T02:00:00+01:00",
  //         "timeZone": "Africa/Lagos"
  //     },
  //     "iCalUID": "5up7vpidc83no0t0r1ti4pqm1k@google.com",
  //     "sequence": 0,
  //     "attendees": [
  //         {
  //             "email": "ekeleagbakwuru9@gmail.com",
  //             "organizer": true,
  //             "self": true,
  //             "responseStatus": "needsAction"
  //         }
  //     ],
  //     "hangoutLink": "https://meet.google.com/vsx-xrej-nbd",
  //     "conferenceData": {
  //         "createRequest": {
  //             "requestId": "kz9oj",
  //             "conferenceSolutionKey": {
  //                 "type": "hangoutsMeet"
  //             },
  //             "status": {
  //                 "statusCode": "success"
  //             }
  //         },
  //         "entryPoints": [
  //             {
  //                 "entryPointType": "video",
  //                 "uri": "https://meet.google.com/vsx-xrej-nbd",
  //                 "label": "meet.google.com/vsx-xrej-nbd"
  //             }
  //         ],
  //         "conferenceSolution": {
  //             "key": {
  //                 "type": "hangoutsMeet"
  //             },
  //             "name": "Google Meet",
  //             "iconUri": "https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"
  //         },
  //         "conferenceId": "vsx-xrej-nbd"
  //     },
  //     "reminders": {
  //         "useDefault": false,
  //         "overrides": [
  //             {
  //                 "method": "popup",
  //                 "minutes": 10
  //             },
  //             {
  //                 "method": "email",
  //                 "minutes": 1440
  //             },
  //             {
  //                 "method": "email",
  //                 "minutes": 60
  //             }
  //         ]
  //     },
  //     "eventType": "default"
  // }

  const history = useHistory();
  const backHandler = () => {
    history.goBack();
  };

  const closeconfirm = () => {
    setIsView(false);
    history.push(`/`);
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  function makeid() {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  var eventDetails = {
    summary: "Balm Schedule",
    location: "Google meet",
    description: "balm.ai google meet schedule",
    start: {
      dateTime: details.startTime,
      timeZone: details.userTimezone,
    },
    end: {
      dateTime: details.endTime,
      timeZone: details.userTimezone,
    },
    conferenceData: {
      createRequest: {
        conferenceSolutionKey: {
          type: "hangoutsMeet",
        },
        requestId: makeid(),
      },
    },
    attendees: [{ email: details.expertEmail, organizer: true }],
    reminders: {
      useDefault: false,
      overrides: [
        { method: "email", minutes: 24 * 60 },
        { method: "email", minutes: 60 },
        { method: "popup", minutes: 10 },
      ],
    },
  };

  var gapi = window.gapi;
  // var CLIENT_ID =
  //   "79486496815-n56u6j58qcohk5gl5q547m8n4brotpop.apps.googleusercontent.com";
  // var API_KEY = "AIzaSyBRN8QmjGatLByzim4VAtKjVNfi3A8q_34";
  // var DISCOVERY_DOCS = [
  //   "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  // ];
  // var SCOPES = "https://www.googleapis.com/auth/calendar.events";

  /* global google */

  // testing from web version to

  let tokenClient;
  let gapiInited;
  let gisInited;

  function gapiInit() {
    gapi.client.init({}).then(function () {
      gapi.client.load(
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
      );
      gapiInited = true;
    });
  }

  function gapiLoad() {
    gapi.load("client", gapiInit);
  }

  function showEvents() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id:
        "79486496815-n56u6j58qcohk5gl5q547m8n4brotpop.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/calendar",
      callback: (resp) => {
        if (resp.error !== undefined) {
          throw resp;
        }

        gapi.client.calendar.events
          .insert({
            calendarId: "primary",
            conferenceDataVersion: 1,
            resource: eventDetails,
            sendUpdates: "all",
            maxAttendees: 2,
          })
          .then((calendarAPIResponse) => {
            setCalendarDetails(calendarAPIResponse.result);
            setIsView(true);
            localStorage.removeItem("meetingInfo");
            dispatch({ type: "CLEAR_MEETING_DETAILS" });
            try {
              const result = calendarAPIResponse.result;
              const { data } = Axios.post(
                ` ${process.env.REACT_APP_API_KEY}appointments/`,
                {
                  result,
                  details,
                }
              );
            } catch (error) {
              console.error("error posting details", error);
            }
          })
          .catch((err) => {});
      },
    });

    if (gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: "" });
    }
  }

  useEffect(() => {
    gapiLoad();
    // gisInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // stripe
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  return (
    <div className="container">
      {isView ? (
        <div
          onClick={closeconfirm}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 23,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {calendarDetails.status === "confirmed" ? (
            <div
              style={{
                alignItems: "center",
                width: "80vw",
                height: "80vh",
                flexWrap: "wrap",
                background: "#ffff",
                overflowY: "scroll",
                overflowX: "hidden",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 id="h3-meetingConf">
                  {calendarDetails.summary}&nbsp;{calendarDetails.status}
                </h3>
                {/* <p id="p-meetingConf">You are scheduled with {receiverName}</p> */}
                <p id="p-meetingConf">meeting details are below</p>
                <div className="lower-div-meetingConf" style={{ width: "98%" }}>
                  {/* <h4 id="event-string-h4-meetingConf">🟡 15 Minute Meeting</h4> */}
                  <p id="event-string-p-meetingConf">
                    Address: 📍 {calendarDetails.location}
                  </p>
                  <p id="event-string-p-meetingConf">
                    Start:🗓️ {calendarDetails.start.dateTime}&nbsp;
                  </p>
                  <p id="event-string-p-meetingConf">
                    End:🗓️ {calendarDetails.end.dateTime}&nbsp;
                  </p>
                  <p id="event-string-p-meetingConf">
                    Timezone:🌎 {calendarDetails.end.timeZone}&nbsp;
                  </p>
                  <p id="event-string-p-meetingConf">
                    MeetingLink:{" "}
                    <img
                      src={gmeet}
                      style={{ padding: "2px", width: "30px" }}
                      alt="hh"
                    />
                    <span
                      style={{
                        background: "rgba(2, 182, 179, 0.12)",
                        color: " #1db9aa",
                        padding: "2px",
                      }}
                    >
                      {calendarDetails.hangoutLink}
                    </span>
                  </p>
                  {/* <p id="time-zone-meetingConf">🌎 India Standard Time</p> */}
                  <h3
                    id="event-string-h5-meetingConf"
                    style={{ textAlign: "center", fontSize: "16px" }}
                  >
                    A calendar invitation has been sent to your email address.
                  </h3>

                  <button
                    style={{
                      textAlign: "center",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      color: "blue",
                      margin: "10px",
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p></p>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
      ) : (
        ""
      )}

      <div className="outerdiv-meeting">
        <div className="left-container-meeting">
          <button className="back-button" onClick={backHandler}>
            ⬅
          </button>
          <TimeBar
            time={time}
            tz={timezone}
            expertId={expert}
            eventId={eventId}
          />
        </div>

        <form
          method="POST"
          // onSubmit={submitHandler}
          className="right-container-meeting"
        >
          <div style={{ marginLeft: "10px" }}>
            <p className="meetingp">Enter Details</p>
            <div className="input-container-meeting">
              <label className="meeting-label">Your Email *</label>
              {addMeetingInfo && (
                <input
                  disabled
                  value={addMeetingInfo.userEmail}
                  className={"input-meeting"}
                />
              )}
            </div>

            <div className="input-container-meeting">
              <label className="meeting-label">
                Please share anything that will help prepare for our meeting.
              </label>
              <textarea className="textarea-meeting"></textarea>

              <Elements stripe={stripePromise}>
                <Checkout setIsShow={setIsShow} />
              </Elements>

              <div
                className="input-container-meeting"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                }}
              ></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MeetingScheduler;
