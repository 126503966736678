import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne10 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO title="Encouragement for startup founders" />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Encouragement for startup founders"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      Encouragement for startup founders
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.pexels.com/photos/3277808/pexels-photo-3277808.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Starting a business can be a challenging and rewarding
                      experience, but it can also be stressful and overwhelming
                      at times. Here are a few words of encouragement for
                      startup founders:
                      <ul style={{ listStyle: "number" }}>
                        <li>
                          Believe in yourself and your vision: It's important to
                          have confidence in your abilities and your vision for
                          your business. Remember that you have the skills and
                          determination to make your startup a success.
                        </li>
                        <li>
                          Focus on your goals: Keep your goals in mind and stay
                          focused on what you want to achieve. This can help to
                          keep you motivated and on track as you work to grow
                          your business.
                        </li>
                        <li>
                          Don't be afraid to ask for help: Starting a business
                          can be a complex and challenging process, and it's
                          okay to ask for help when you need it. Seek out
                          resources, advice, and support from trusted sources to
                          help you navigate the challenges of starting a
                          business.
                        </li>
                        <li>
                          Embrace setbacks and learn from them: Setbacks are a
                          normal part of the startup journey, and it's important
                          to embrace them and learn from them. Use setbacks as
                          opportunities to improve and grow your business.
                        </li>
                        <li>
                          Stay positive and stay the course: It's important to
                          stay positive and stay the course, even when things
                          get tough. Remember that starting a business takes
                          time and effort, and it's important to be patient and
                          persistent as you work towards your goals.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Remember that starting a business is a journey, and it
                      takes time and effort to succeed. Seek support when needed
                      and be kind to yourself as you work towards building a
                      successful startup.
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Encouragement-for-one-who-lost-a-loved-one">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                              Encouragement for one who lost a loved one
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Mental-health-for-employees">
                            <span>Mental health for employees</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne10;
