import React from "react";
import { Link } from "react-router-dom";

const ResiliencePost = ({ data, classes, bgWhite }) => {
  return (
    <div
      className={`edu-blog blog-type-2 radius-small resilience-shadow bg-color-white  ${
        bgWhite === "enable" ? "bg-color-white" : ""
      }`}
    >
      <div className="inner">
        <div className="thumbnail">
          <Link to={`/resilience/post/${data.link}`}>
            <img src={data.image} alt="Blog Thumb" />
          </Link>
        </div>
        <div className="content">
          <p className="title">
            <Link to={`/resilience/post/${data.link}`}>{data.title}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResiliencePost;
