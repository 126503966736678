import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ExpertNav = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState(null);
  const [specialization, setSpecialization] = useState("");
  const [loading, setLoading] = useState(true);

  // redux
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const _id = userInfo?._id;

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setImage(userInfo?.profileImg?.url);
      setStatus(userInfo?.accountStatus);
      setSpecialization(userInfo?.specialization);
    }
  }, [userInfo]);
  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setImage(userInfo?.profileImg?.url);
      // setStatus(userInfo.accountStatus);
      setSpecialization(userInfo.specialization);
    }
  }, [userInfo]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAccount = async () => {
    if (_id) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_KEY}expert/accountstatus`,
          { _id },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setStatus(data);
        setLoading(false);
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
      }
    }
  };

  useEffect(() => {
    if (userInfo && _id && userInfo.isExpert) {
      fetchAccount();
    }
  }, [userInfo, _id]);
  return (
    <>
      {userInfo && userInfo.isExpert ? (
        <ul className="mainmenu" style={{ borderTop: "1px solid #606770" }}>
          <li>
            <div
              className="expert-dashboard-nav-top"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="expert-dashboard-img-wrap"
                style={{ width: "8rem", height: "8rem" }}
              >
                <img
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  src={image}
                  alt="Main"
                />
                
              </div>
              <div
                className="expert-dashboard-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6>{name}</h6>
                <p>{specialization}</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p>Account Status: </p>
                  {status && !loading && (
                    <p
                      style={{
                        background: "rgba(77, 230, 138, 0.12)",
                        borderRadius: "5px",
                        color: "green",
                        marginLeft: "0.6rem",
                        padding: "5px",
                      }}
                    >
                      approved
                    </p>
                  )}
                  {!status && !loading && (
                    <p
                      style={{
                        background: "rgba(242, 17, 54, 0.12)",
                        borderRadius: "5px",
                        color: "red",
                        marginLeft: "0.6rem",
                        padding: "5px",
                      }}
                    >
                      pending
                    </p>
                  )}
                </div>
                {!status && !loading && (
                  <p
                    style={{
                      background: "#ebf1fe",
                      borderRadius: "5px",
                      color: "#2a7ade",
                      margin: "0.6rem",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    Please update your profile details, social links and await
                    approved. Ignore if already updated.
                  </p>
                )}
              </div>
            </div>
          </li>
          <li className="">
            <Link to="/expert-dashboard/appointments">Apointments</Link>
          </li>
          <li className="">
            <Link to="/expert-dashboard/profile">Profile Update</Link>
          </li>
          <li className="">
            <Link to="/expert-dashboard/earnings">Earnings</Link>
          </li>
          <li className="">
            <Link to="/expert-dashboard/reviews">Reviews</Link>
          </li>
        </ul>
      ) : (
        " "
      )}
    </>
  );
};
export default ExpertNav;
