import React from 'react';
import Slider from 'react-slick';

let data = [
    {
        name: "S E",
        details: "I booked a Session with an expert, who normally charges $5,000+ for full consultation services, for six virtual consultations. Here’s what happened: we now have the most beautiful working enviromentand his advice saved us $4,000. Best product ever!",
        details2: "I booked a Session with an expert, who normally charges $5,000+ for full consultation services, for six virtual consultations. Here’s what happened: we now have the most beautiful working enviromentand his advice saved us $4,000. Best product ever!",
        designation: "Tech company CEO",
        image: "client-01.png",
        rating: 5
    },
    {
        name: "M J",
        details: "Funke was amazing! She had so much useful information and really gave me so busines ideas and knocked down some common myths in the business. I can’t recommend her enough!",
        details2: "Funke was amazing! She had so much useful information and really gave me so busines ideas and knocked down some common myths in the business. I can’t recommend her enough!",
        designation: "Business Owner",
        image: "client-02.png",
        rating: 5
    },
    {
        name: "A A",
        details: "Sami is an all time wellness expert! I can't believe all the information I received in such a short time... Even offered to meet on a monthly cadence to stay committed and be held accountable. Feel so grateful to have connected with Sami today!",
        details2: "Sami is an all time wellness expert! I can't believe all the information I received in such a short time... Even offered to meet on a monthly cadence to stay committed and be held accountable. Feel so grateful to have connected with Sami today!",
        designation: "Student",
        image: "client-03.png",
        rating: 5
    },
    {
        name: "N O",
        details: "If you’re reading this, wondering whether to schedule a session, just book it! My partner and I have been struggling with our living space the last few months, spending more money than we’d like and having difficulty agreeing on things. This call was such relief.",
        details2: "If you’re reading this, wondering whether to schedule a session, just book it! My partner and I have been struggling with our living space the last few months, spending more money than we’d like and having difficulty agreeing on things. This call was such relief.",
        designation: "Laravel Developer",
        image: "Start-up Owner",
        rating: 5
    },
];

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow slick-arrow" onClick={onClick}><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow slick-arrow" onClick={onClick}><i className="icon-arrow-right-line"></i></button>
}

const TestimonialThree = ( props ) => {
    const sliderSettings = {
        dots: props.dots === "enable" ? true : false,
        infinite: true,
        arrows: props.arrows === "disable" ? false : true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: props.fade === "disable" ? false : true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    data = props.item ? data.slice( 0, props.item ) : data;
  
    const classes = `eduvibe-testimonial-three inner testimonial-card-activation-1 slick-arrow-style-2 ${props.className ? props.className : '' }`;
    return (
        <Slider 
            className={classes}
            { ...sliderSettings }
        >
            { data.map( ( item, i ) => (
                <div 
                    className={ props.itemClass || 'single-card' }
                    key={i}
                >
                    { props.rating !== 'disable' && 
                        <div className="rating eduvibe-course-rating-stars">
                            {[...Array(5)].map((_, index) => {
                                return <i key={index} className={ index < item.rating ? 'on icon-Star' : 'off icon-Star' }></i>
                            })}
                        </div>
                    }
                    <p className="description">“{ props.details2 !=="enable" ? item.details : item.details2 }”</p>
                    <div className="client-info">
                        {/* <div className="thumbnail">
                            <img src={`https://res.cloudinary.com/dxjprordi/image/upload/v1663745238/profil_iflv6m.png`} alt="Client Thumb" />
                        </div> */}
                        <div className="content">
                            <h6 className="title">{ item.name }</h6>
                            <span className="designation">{ item.designation }</span>
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    )
}

export default TestimonialThree;