import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne14 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="Our Innovation story at Balm.ai
"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Our Innovation story at Balm.ai
          "
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">Our Innovation story at Balm.ai</h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small w-100 mb--30"
                        src={`https://images.pexels.com/photos/1136465/pexels-photo-1136465.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      We are building a global solution for mental healthcare,
                      helping young adults recover from depression, trauma,
                      heart break, burn out, addiction, and other mental
                      health-related situations, leveraging expert psychologists
                      to provide on-demand personalized mental health care and
                      therapy for individuals to unleash their inner genius and
                      for organizations to help their workforce stay productive.
                    </p>
                    The Problem:
                    <p>
                      There is a mental health epidemic, which is an even bigger
                      health crisis.
                    </p>
                    <ul style={{ listStyle: "dash" }}>
                      <li>
                        Businesses lose $100B per year in productivity due to
                        this crisis
                      </li>
                      <li>
                        Anxiety disorders cost the global economy US$ 1 trillion
                        each year in lost productivity
                      </li>
                      <li>
                        Over 280 million people globally suffer from depression,
                        which is the leading cause of unhappiness,
                        unproductivity, and dissatisfaction at work which
                        reduces cognitive performance.
                      </li>
                    </ul>
                    <p>
                      The Purpose: We built Balm to heal the world and get rid
                      of this epidemic
                    </p>
                    <p>Mission: .</p>
                    <p>
                      Balm is on a mission to bring happiness to the world. We
                      support organizations to become more productive by
                      providing on-demand, personalized, and private mental
                      health care for their employees. We help professionals
                      become more productive at work and in their personal
                      lives.
                    </p>
                    <p>Solution:</p>
                    <p>
                      Balm is transforming mental health care by creating a
                      seamless experience for behavioral health experts, and
                      employers. Leveraging technology and data, we connect
                      companies and their employees to mental healthcare. Balm
                      is an integrated wellness system that provides support and
                      recommendations to those exposed to mental health risks.
                      Our platform also has prevention workplace programs to
                      enable organizations to put in place a support system to
                      help people reduce and manage stress at work.{" "}
                    </p>
                    <p>
                      The benefit: With Balm;
                      <ul style={{ listStyle: "dash" }}>
                        <li>
                          businesses enable their employees to have direct and
                          personal mental health access to experts across the
                          world;{" "}
                        </li>
                        <li>
                          behavioral health providers would have a software
                          solution for real-time mental healthcare data and
                          diagnosis to proffer the right treatment and
                          intervention.
                        </li>
                        <li>
                          We would leverage the dataset to improve the
                          algorithms for the psychometric assessment tools and
                          conversational chatbot to provide accurate and
                          real-time access to mental healthcare.{" "}
                        </li>
                      </ul>
                    </p>
                    <p>
                      The results: People Transformation, Performance
                      Optimization, and Culture Elevation
                      <ul style={{ listStyle: "dash" }}>
                        <li>
                          Businesses get 80-90% employee engagement and
                          performance using health systems that enable
                          organizations to offer a work culture that prioritizes
                          the mental health of their workforce,{" "}
                        </li>
                        <li>
                          Empowered workforce, culture, and enabling work
                          environment for employees to be their best selves.
                        </li>
                      </ul>
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Balm uses artificial intelligence to offer personalized
                      recommendations and predictive analysis. We provide
                      real-time accessible and affordable mental healthcare . We
                      leverage artificial intelligence to understand the
                      triggers of the mental health situation to give for more
                      reliable intervention. visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      started.”
                    </blockquote>
                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Our-mission-at-Balm">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>Our mission at Balm.ai</span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Mental-Illness-is-an-Epidemic-that-theratens-our-existence">
                            <span>
                              Mental Illness is an Epidemic that theratens our
                              existence
                            </span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne14;
