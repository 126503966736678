import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne18 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="Our BHAG at Balm

"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Our BHAG at Balm

          "
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">Our BHAG at Balm</h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://res.cloudinary.com/dxjprordi/image/upload/v1654257821/balm/My_project_nrhmv7.jpg`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Mental health is an epidemic that threatens our existence.
                      Many struggle with their mental health. Depression, is the
                      leading cause of unhappiness, unproductivity, and
                      dissatisfaction at work which reduces cognitive
                      performance. We live in a world where over 600 million
                      people suffer from depression. These are the world’s best
                      and brightest minds with untapped potentials, just in need
                      of light.
                      <br />
                      Businesses lose $100B per year in productivity due to this
                      crisis, Anxiety disorders cost the global economy US$ 1
                      trillion each year in lost productivity
                    </p>
                    <p>
                      We are using digital solutions to expand the reach of
                      mental health services, making it 10X faster to speak with
                      a therapist and experts.
                    </p>
                    <p>
                      We believe everyone should have access to affordable,
                      private, and on-demand mental healthcare and with your
                      passion, skills, and experience, you have what it takes to
                      make the world a better place.
                    </p>
                    <p>
                      Our big audacious health goals at Balm require radical
                      collaboration, and when achieved, billions of young adults
                      will be improved. Balm is transforming mental health care
                      by creating a seamless experience therapy, void of the
                      associated stigma. Behavioral health experts would be
                      empowered, while making the world a better place.
                    </p>

                    <blockquote className="blockquote-style-1">
                      “Balm is healing the world. Join us as an expert as we
                      join forces to heal others, at your convenience. With
                      Balm, you can find purpose, impact and earn while doing
                      what you love. . Visit
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      started”
                    </blockquote>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Use-Balm-to-manage-your-Advisory">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                              Use Balm to manage your Advisory, Therapy and
                              Counselling Practice
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Psychological-support-for-you-and-your-employees">
                            <span>
                              Psychological support for you and your employees
                            </span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne18;
