import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import Nav from "./Nav";
import ResponsiveMenu from "./ResponsiveMenu";
import { signOut } from "firebase/auth";
import { signout } from "../../actions/userActions";
import { auth } from "../../firebase";

const HeaderTwo = ({ styles, disableSticky, searchDisable, buttonStyle }) => {
  const [name, setName] = useState(" ");
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  // redux
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const history = useHistory();

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
    }
  }, [userInfo]);

  const redirectTo = () => {
    if (userInfo && userInfo.isExpert) {
      history.push("/expert-dashboard/appointments");
    } else if (userInfo && !userInfo.isExpert) {
      history.push("/user-dashboard/profile");
    } else {
      history.push("/user/signin");
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // console.log("Signed out successfully");
      })
      .catch((error) => {});
  };

  const logOut = () => {
    handleLogout();
    dispatch(signout());
  };

  const logIn = () => {
    history.push("/user/signin");
  };

  // const sticky = HeaderSticky(200);
  const classes = `header-default sticky`;
  const stickyStatus = disableSticky ? "header-sticky" : " header-sticky";
  return (
    <>
      <header
        className={`edu-header ${stickyStatus} ${styles || ""} ${
          classes || ""
        }`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="align-items-center container"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="col-lg-4 col-xl-2 col-md-6 col-6">
            <div className="logo">
              <Link to={process.env.PUBLIC_URL + "/"}>
                <img
                  className="logo-light"
                  src="https://res.cloudinary.com/balmai/image/upload/v1658584166/balm-web/My_project_4_1_1_qsw5mj.png"
                  alt="Main Logo"
                />
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-xl-7 d-none d-xl-block">
            <nav className="mainmenu-nav d-none d-lg-block">
              <Nav />
            </nav>
          </div>

          <div className="col-lg-8 col-xl-3 col-md-6 col-6">
            <div className="header-right d-flex justify-content-end">
              <div className="header-menu-bar">
                <div className="quote-icon quote-user d-none d-md-block ml--15 ml_sm--5">
                  {userInfo ? (
                    <button
                      onClick={redirectTo}
                      className={`edu-btn btn-medium left-icon header-button ${
                        buttonStyle || ""
                      }`}
                    >
                      <i className="ri-user-line"></i>
                      {name.substring(0, 8)}..
                    </button>
                  ) : (
                    <Link
                      className={`edu-btn btn-medium left-icon header-button ${
                        buttonStyle || ""
                      }`}
                      to="/user/signin"
                    >
                      <i className="ri-user-line"></i>Signup/Login
                    </Link>
                  )}
                </div>


              </div>
              <div className="mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none">
                <div className="hamberger">
                  <button
                    data-tip="menu"
                    className="white-box-icon hamberger-button header-menu"
                    onClick={onCanvasHandler}
                  >
                    <i className="ri-menu-line"></i>
                  </button>
                </div>
              </div>
              {userInfo && (
                <div className="quote-icon quote-search">
                  <button
                    data-tip="sign out"
                    className="white-box-icon search-trigger header-search"
                    onClick={logOut}
                  >
                    <i className="ri-logout-circle-r-line"></i>
                  </button>
                </div>
              )}
              {!userInfo && (
                <div className="quote-icon quote-search">
                  <button
                    data-tip="sign in"
                    className="white-box-icon search-trigger header-search"
                    onClick={logIn}
                  >
                    <i className="ri-login-circle-line"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <ReactTooltip />
      <ResponsiveMenu
        show={offcanvasShow}
        onClose={onCanvasHandler}
        // showSearch={searchPopup}
        // onSearch={onSearchHandler}
      />
    </>
  );
};

export default HeaderTwo;
