import React from "react";

const Login = () => {
  return (
    <div>
      <div
        id="g_id_onload"
        data-client_id="79486496815-n56u6j58qcohk5gl5q547m8n4brotpop"
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri="https://www.balm.ai./user/signin"
        // data-auto_select="true"
        data-itp_support="true"
      ></div>

      <div
        class="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left"
      ></div>
    </div>
  );
};

export default Login;
