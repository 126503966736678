import React from "react";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import ScrollAnimation from "react-animate-on-scroll";
import experts from "./expertData.json";
import { Link } from "react-router-dom";
const OurExperts = (props) => {
  return (
    <div>
      {" "}
      <div className="eduvibe-about-one-team edu-team-area edu-section-gap team-area-shape-position bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <SectionTitle
                classes="text-start"
                title={props.title}
                color={props.color}
              />
            </div>
          </div>
          <div className="row row--20">
            {experts.map((data, index) => (
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
                className="col-lg-3 col-md-6 col-sm-6 col-6 mt--45"
                key={data.id}
              >
                <div className="edu-instructor-grid edu-instructor-1">
                  <div className="edu-instructor">
                    <div className="thumbnail">
                      <img
                        style={{
                          borderRadius: "50%",
                          height: "140px",
                          width: "140px",
                        }}
                        src={`${data.image}`}
                        alt="Team Member"
                      />
                    </div>
                  </div>

                  <h5 className="title">
                    <Link to={`#`}>{data?.name}</Link>
                  </h5>
                </div>
              </ScrollAnimation>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurExperts;
