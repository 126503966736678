import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne6 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO title="How to over come heartbreak" />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="How to over come heartbreak"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">How to over come heartbreak</h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.pexels.com/photos/7341894/pexels-photo-7341894.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Heartbreak can be a difficult and painful experience, but
                      it is possible to heal and move on. Here are a few steps
                      you can take to help overcome heartbreak:
                      <ul style={{ listStyle: "number" }}>
                        <li>
                          Allow yourself to feel your emotions: It's natural to
                          feel a range of emotions after a break-up, including
                          sadness, anger, and grief. Allow yourself to feel
                          these emotions and don't try to suppress them. It's
                          important to give yourself time to heal and process
                          your feelings.
                        </li>
                        <li>
                          Take care of yourself: It's important to prioritize
                          self-care during this difficult time. This can include
                          getting enough sleep, eating a healthy diet,
                          exercising, and engaging in activities that bring you
                          joy and relaxation.
                        </li>
                        <li>
                          Seek support: Surround yourself with supportive
                          friends and family who can offer emotional and
                          practical support. It can also be helpful to seek the
                          support of a therapist or counselor who can provide a
                          safe and supportive space to work through your
                          emotions..
                        </li>
                        <li>
                          Find healthy ways to cope: It's important to find
                          healthy ways to cope with the pain and stress of
                          heartbreak. This can include engaging in activities
                          that bring you joy, such as hobbies or creative
                          pursuits, or finding healthy ways to cope with stress,
                          such as through exercise or relaxation techniques.
                        </li>
                        <li>
                          Give yourself time: Healing from heartbreak takes
                          time, and it's important to be patient with yourself
                          and to recognize that it's okay to have ups and downs.
                          Remember that it's normal to feel a range of emotions
                          after a break-up, and it's important to give yourself
                          the time and space you need to heal and move on.
                        </li>
                      </ul>
                    </p>

                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/How-to-make-a-good-marital-decision">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>How to make a good marital decision</span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/How-to-get-married-to-the-right-person">
                            <span>How to get married to the right person</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne6;
