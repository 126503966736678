/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { FaEye } from "react-icons/fa";

const Appointments = () => {
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState([]);
  // redux
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const { email } = userInfo;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAccount = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}appointments/expert?expert=${email}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      setAppointment(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error("❌  error!", message);
    }
  };

  useEffect(() => {
    if (userInfo && email) {
      fetchAccount();
    }
  }, [userInfo, email]);

  return (
    <>
      <h6>Appointments</h6>
      <div className="expert-apointments-table">
        <div>
          <Table className="responsive-table">
            <Thead>
              <Tr>
                <Th style={{ textAlign: "center" }}>Customer's Name</Th>
                <Th style={{ textAlign: "center" }}>Appt Date</Th>
                <Th style={{ textAlign: "center" }}>Completed ?</Th>
                <Th style={{ textAlign: "center" }}>Paid Amount</Th>
                <Th style={{ textAlign: "center" }}>Actions</Th>
              </Tr>
            </Thead>
            {appointment && appointment.length > 0 && (
              <Tbody className="ttbody">
                {appointment.map((data, index) => (
                  <Tr key={index}>
                    <Td>
                      <div
                        className="row-table"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{data.userName}</p>
                      </div>
                    </Td>
                    <Td>
                      <div
                        className="column-table"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{new Date(data.time).toDateString()}</p>
                        <p style={{ color: "#20c997" }}>
                          {new Date(data.time).toLocaleTimeString()}
                        </p>
                      </div>
                    </Td>

                    <Td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{data.isComplete ? "Yes" : "No"}</p>
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>${data.amount}</p>
                      </div>
                    </Td>
                    <Td>
                      <div
                        className="action-button"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <label className="label-view">
                          <a
                            href={data.googleMeetLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaEye /> view
                          </a>
                        </label>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
          <div className="">
            {loading ? (
              <div style={{ padding: "3rem" }}>
                <div className="loading-bar">
                  <i
                    className={"fa fa-spinner fa-spin"}
                    style={{ margin: "1rem" }}
                  ></i>
                  loading
                </div>
              </div>
            ) : (
              ""
            )}
            {!loading && appointment.length < 1 && (
              <div style={{ padding: "3rem" }}>
                <div className="warning-bar">No Appointments Yet</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Appointments;
