import React, { useRef } from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";

const Privacy = () => {
  // const goTo1 = () =>
  //   window.scrollTo({
  //     top: to1.current.offsetTop - 100,
  //     behavior: "smooth",
  //   });

  return (
    <>
      <SEO title="Privacy Policy" />
      <Layout>
        <BreadcrumbOne
          title="Privacy Policy"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Privacy Policy"
        />
        <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="content">
                <div className="thumbnail mb--50">
                  <img
                    className="radius-small w-100"
                    src="https://res.cloudinary.com/balmai/image/upload/v1656794336/balm-web/sage-friedman-HS5CLnQbCOc-unsplash_kh6bm9.jpg"
                    alt="Privacy Policy"
                  />
                </div>

                <h5 className="title">PRIVACY NOTICE</h5>
                <span>Last updated June 12th, 2024</span>
                <p>
                  Balm.ai ("we" or "us") respects the privacy of our users
                  ("you" or "your"). This Privacy Policy describes how we
                  collect, use, and disclose your information when you use our
                  platform ("Platform"), including any related websites, mobile
                  applications, and services.
                </p>

                <div>
                  <p></p>
                  <h5 className="title">1. Information We Collect</h5>
                  <p>
                    We collect several types of information when you use the
                    Platform:
                  </p>
                  <ul>
                    <li>
                      Personal Information: This includes information that can
                      be used to identify you, such as your name, email address,
                      phone number, and username.
                    </li>
                    <li>
                      Data You Upload: You may upload health data to the
                      Platform, such as information from fitness trackers,
                      wearables, and health apps.
                    </li>
                    <li>
                      Usage Data: We collect information about how you use the
                      Platform, such as the pages you visit, the features you
                      use, and the time you spend on the Platform.
                    </li>
                    <li>
                      Device Information: We may collect information about the
                      device you use to access the Platform, such as your device
                      type, operating system, IP address, and browser type.
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title">2. How We Use Your Information</h5>
                  <p>We use your information to:</p>
                  <ul>
                    <li>Provide and improve the Platform and its services.</li>
                    <li>
                      Analyze your uploaded data to generate personalized
                      insights and reports.
                    </li>
                    <li>
                      Connect you with appropriate health professionals if you
                      choose (optional).
                    </li>
                    <li>
                      Send you important information about the Platform, such as
                      service updates and security alerts.
                    </li>
                    <li>Respond to your inquiries and requests.</li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title">Sharing Your Information</h5>
                  <ul>
                    <li>
                      We may share your information with third-party service
                      providers who help us operate the Platform. These service
                      providers are contractually obligated to keep your
                      information confidential and to use it only for the
                      purposes we have specified.
                    </li>
                    <li>
                      We may also share your information with health
                      professionals if you choose to connect with them through
                      the Platform. However, we will only share your information
                      with your explicit consent.
                    </li>
                    <li>
                      We will not share your information with any other third
                      party without your consent, except as required by law.
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title"> Data Security</h5>
                  <ul>
                    <li>
                      We take reasonable steps to protect your information from
                      unauthorized access, disclosure, alteration, or
                      destruction. However, no security measures are perfect,
                      and we cannot guarantee the absolute security of your
                      information.
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title"> 5. Your Choices</h5>
                  <p>You have several choices regarding your information:</p>
                  <ul>
                    <li>
                      You can choose not to upload any data to the Platform.
                    </li>
                    <li>
                      You can access and update your profile information at any
                      time.
                    </li>
                    <li>
                      You can choose not to receive marketing communications
                      from us.
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title">6. Children's Privacy</h5>
                  <ul>
                    <li>
                      The Platform is not intended for children under the age of
                      18. We do not knowingly collect information from children
                      under 18.
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title">7. International Transfers</h5>
                  <ul>
                    <li>
                      Your information may be transferred to and processed in
                      countries other than your own. These countries may have
                      different data protection laws than your own country.
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title">8. Changes to This Privacy Policy</h5>
                  <ul>
                    <li>
                      We may update this Privacy Policy from time to time.
                    </li>
                    <li>
                      We will notify you of any changes by posting the revised
                      Privacy Policy on the Platform.
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title">
                    9. How can you review, update, or delete the Data we collect
                    from you?
                  </h5>
                  <ul>
                    <li>
                      To request to review, update, or delete your personal
                      information, please send a mail to{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:support@balm.ai"
                      >
                        support@balm.ai
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p></p>
                  <h5 className="title">10. Contact Us</h5>
                  <ul>
                    <li>
                      If you have any questions about this Privacy Policy,
                      please contact us at{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:support@balm.ai"
                      >
                        support@balm.ai
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Privacy;
