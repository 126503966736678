import React from "react";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import ScrollAnimation from "react-animate-on-scroll";

const About1 = (props) => {
  return (
    <div className="edu-counterup-and-course-area">
      {/* <CounterUpOne /> */}

      <div className="edu-course-area eduvibe-home-three-course counterup-overlay-top bg-image edu-section-gapTop edu-section-gapBottom">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-10">
              <SectionTitle classes="text-start" title={props.title} />
            </div>
            <div className="row g-5 mt--25" style={{ overflow: "hidden" }}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h5 style={{ color: "#385aa2" }}>About Balm:</h5>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6>
                  Balm is an integrated wellness platform that enables an
                  organization to put in place a support system to help their
                  managers and employees reduce and manage stress at work. Our
                  platform connects companies and their employees to
                  professional, psychological and mental support services.
                </h6>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4>
                  <ul>
                    <li>
                      <h6>Implement a healthy company culture</h6>
                    </li>
                    <li>
                      <h6>Get access to a personalized team of experts</h6>
                    </li>
                    <li>
                      <h6>
                        {" "}
                        Demonstrate compassion and empathy for your employees.
                      </h6>
                    </li>
                  </ul>
                </h4>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About1;
