import React from "react";
import { MdForward } from "react-icons/md";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import ScrollAnimation from "react-animate-on-scroll";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const NextSteps = (props) => {
  const items = [
    {
      title: "1",
      info: "Balm is introduced to your company and company decides on a pricing plan      ",
      next: true,
    },
    {
      title: "2",
      info: "Company subscribes monthly via the Stripe payment link below      ",
      next: true,
    },
    {
      title: "3",
      info: "Balm sends a service level agreement for review and execution      ",
      next: true,
    },
    {
      title: "4",
      info: "Balm schedules a call with the People Ops Team or HR to design the training programs      ",
      next: true,
    },
    {
      title: "5",
      info: "Balm executes on the program and shares a feedback with the management.",
      next: false,
    },
  ];
  const pricingInfo = [
    {
      title: "Basic      ",
      info: "https://buy.stripe.com/bIYcQF82M0oXdDa28t       ",
      amt: "$200",
    },
    {
      title: "Standard      ",
      info: "https://buy.stripe.com/00g3g50AkfjRbv24gC       ",
      amt: "$300",
    },
    {
      title: "Premium      ",
      info: "https://buy.stripe.com/00gg2R2IsdbJ56EbJ6       ",
      amt: "$1500",
    },
    {
      title: "Customized Payment      ",
      info: "https://buy.stripe.com/00g7wlbeY6Nl9mU6oN       ",
      amt: "Insert amount      ",
    },
  ];
  return (
    <div>
      {" "}
      <div className="eduvibe-about-one-team edu-team-area edu-section-gap team-area-shape-position bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <SectionTitle classes="text-start" title={props.title} />
            </div>
          </div>
          <div className="row g-5 mt--20">
            {items.map((data, i) => (
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                className={`col-lg-4 col-md-4 col-sm-6 col-6 `}
                animateOnce={true}
                key={i}
              >
                <div style={{ display: "flex" }}>
                  <div className="service-card service-card-4">
                    <div className="inner">
                      <div className="content">
                        <h6 className="title">
                          <a href={data.link}>{data.title}</a>
                        </h6>
                        <p className="description">{data.info}</p>
                      </div>
                    </div>
                  </div>
                  {data.next && (
                    <div className="next_arrow_wrap">
                      <MdForward className="next_arrow_icon" />
                    </div>
                  )}
                </div>
              </ScrollAnimation>
            ))}
          </div>
          <hr style={{width: '100%', height: "20px", background: "#fff"}}/>
          <div className="row g-5 mt--20">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <h6 style={{ textAlign: "center" }}>
                We believe your employees’ mental health is the key to
                sustainable growth, for both your teams and your company. We
                provide alternative therapy and mentorship for your team or
                clients, for better retention, increase productivity, employee
                motivation, job satisfaction, and engagement.
              </h6>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <h6 style={{ textAlign: "center" }}>
                With Balm, your employees are happier, more productive, have
                more job satisfaction, and engagement via a psychological
                support program, coaching, and mentoring for improved
                professional development of your team, while promoting
                organizational success, as well as improved organizational
                culture.
              </h6>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <h6 style={{ textAlign: "center" }}>
                Let’s help you create an empowered workforce, culture, and
                enabling work environment for your employees to be their best
                selves.
              </h6>
            </ScrollAnimation>
            <div
              className="read-more-btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h5>To get started, schedule a free consultation</h5>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <a
                  className="edu-btn"
                  href="https://calendly.com/balmhealth/sp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Started <i className="icon-arrow-right-line-right"></i>
                </a>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextSteps;
