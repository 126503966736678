import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";

const TermsOfService = () => {
  return (
    <>
      <SEO title="Terms Of Service" />
      <Layout>
        <BreadcrumbOne
          title="Terms Of Service"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Terms Of Service"
        />
        <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="content">
                <div className="thumbnail mb--50">
                  <img
                    className="radius-small w-100"
                    src="https://res.cloudinary.com/balmai/image/upload/v1656794380/balm-web/william-farlow-IevaZPwq0mw-unsplash_zkcbau.jpg"
                    alt="Privacy Policy"
                  />
                </div>

                <h4 className="title">Terms & Service</h4>
                <h6 className="title">Last updated: June 12th, 2024</h6>
                <p>
                  These Terms of Service ("Terms") govern your access to and use
                  of the Balm.ai platform ("Platform"), including any related
                  websites, mobile applications, emails, texts, calls, and
                  services offered by Balm.ai ("we" or "us").
                </p>
                <p>
                  Please read these Terms carefully before using the Platform.
                  By accessing or using the Platform, you agree to be bound by
                  these Terms. If you disagree with any part of these Terms, you
                  may not access or use the Platform.
                </p>

                <h6 className="title">1. Use of the Platform</h6>
                <ul>
                  <li>
                    You must be at least 18 years old and have the legal
                    capacity to enter into a binding agreement to use the
                    Platform.
                  </li>
                  <li>
                    You are responsible for creating a secure account and
                    maintaining the confidentiality of your account credentials.
                    You are also responsible for all activity that occurs under
                    your account.
                  </li>
                  <li>
                    You agree to use the Platform in a lawful and responsible
                    manner and in accordance with these Terms. You will not use
                    the Platform for any purpose that is prohibited by law or
                    these Terms.
                  </li>
                  <li>Have the right to confidentiality</li>
                  <li>
                    Have the right to raise questions about our psychotherapy
                    approach or your employees progress at any time.
                  </li>
                </ul>

                <h6 className="title">2. User Data</h6>
                <ul>
                  <li>
                    You represent and warrant that you own all rights, titles,
                    and interests in the data you upload to the Platform ("Your
                    Data").
                  </li>
                  <li>
                    You grant us a non-exclusive, royalty-free license to use
                    Your Data to provide you with the services offered on the
                    Platform. This includes analyzing your data to generate
                    insights and reports, and to connect you with appropriate
                    health professionals if you choose.
                  </li>
                  <li>
                    We will take reasonable steps to protect the confidentiality
                    of Your Data. However, you acknowledge that no security
                    measures are perfect and we cannot guarantee the absolute
                    security of Your Data.
                  </li>
                </ul>

                <h6 className="title">3. Third-Party Service</h6>
                <ul>
                  <li>
                    The Platform may integrate with or link to third-party
                    websites and services. Your use of these third-party
                    services is governed by their own terms of service and
                    privacy policies. We are not responsible for the content or
                    practices of any third-party services.
                  </li>
                </ul>

                <h6 className="title">4. Disclaimers</h6>
                <ul>
                  <li>
                    The Platform may integrate with or link to third-party
                    websites and services. Your use of these third-party
                    services is governed by their own terms of service and
                    privacy policies. We are not responsible for the content or
                    practices of any third-party services.
                  </li>
                </ul>

                <h6 className="title">4. Disclaimers</h6>
                <ul>
                  <li>
                    The Platform and the information provided through it are for
                    informational purposes only and do not constitute medical
                    advice. You should always consult with a qualified
                    healthcare professional before making any decisions about
                    your health.
                  </li>
                  <li>
                    We make no guarantees or warranties about the accuracy,
                    completeness, or timeliness of the information provided on
                    the Platform. The information is provided "as is" and
                    without warranties of any kind, express or implied.
                  </li>
                </ul>

                <h6 className="title">5. Limitation of Liability</h6>
                <ul>
                  <li>
                    To the maximum extent permitted by law, we will not be
                    liable for any damages arising out of or related to your use
                    of the Platform. This includes, but is not limited to,
                    direct, indirect, incidental, consequential, punitive, or
                    special damages.
                  </li>
                </ul>

                <h6 className="title">6. Intellectual Property</h6>
                <ul>
                  <li>
                    The Platform and all content and materials on the Platform
                    are protected by copyright, trademark, and other
                    intellectual property laws. You may not copy, modify,
                    distribute, transmit, display, perform, reproduce, publish,
                    license, or create derivative works from any content or
                    materials on the Platform without our prior written consent.
                  </li>
                </ul>

                <h6 className="title">7. Termination</h6>
                <ul>
                  <li>
                    We may terminate your access to the Platform at any time and
                    for any reason, without notice. You may also terminate your
                    account at any time.
                  </li>
                </ul>

                <h6 className="title">8. Governing Law</h6>
                <ul>
                  <li>
                    These Terms will be governed by and construed in accordance
                    with the laws of the United States, without regard to its
                    conflict of law principles.
                  </li>
                </ul>

                <h6 className="title">9. Entire Agreement</h6>
                <ul>
                  <li>
                    These Terms constitute the entire agreement between you and
                    us regarding your use of the Platform.
                  </li>
                </ul>

                <h6 className="title">10. Severability</h6>
                <ul>
                  <li>
                    If any provision of these Terms is held to be invalid or
                    unenforceable, such provision shall be struck and the
                    remaining provisions shall remain in full force and effect.
                  </li>
                </ul>

                <h6 className="title">11. Changes to These Terms</h6>
                <ul>
                  <li>
                    We reserve the right to modify these Terms at any time. We
                    will notify you of any changes by posting the revised Terms
                    on the Platform. Your continued use of the Platform after
                    the revised Terms are posted constitutes your agreement to
                    the revised Terms.
                  </li>
                </ul>

                <h6 className="title">12 Disclaimers</h6>
                <ul>
                  <li>
                    Balm.ai is not a hospital or a healthcare provider. The
                    Platform and the information provided through it are for
                    informational purposes only and do not constitute medical
                    advice. You should always consult with a qualified
                    healthcare professional before making any decisions about
                    your health.
                  </li>
                  <li>
                    Emergency Disclaimer: Balm.ai is not a substitute for
                    emergency medical care. If you are experiencing a medical
                    emergency, please contact your emergency provider in your
                    country or seek help immediately.
                  </li>
                  <li>
                    We make no guarantees or warranties about the accuracy,
                    completeness, or timeliness of the information provided on
                    the Platform. The information is provided "as is" and
                    without warranties of any kind, express or implied.
                  </li>
                </ul>

                <h6 className="title">13 Clients rights</h6>
                <ul>
                  <li>You have the right to refuse the service or output</li>
                  <li>
                    You have the right to change practitioner in subsequent
                    sessions
                  </li>
                  <li>
                    You have the right and responsibility to choose a
                    practitioner that best suits your needs
                  </li>
                  <li>You have the right to confidentiality</li>
                </ul>

                <h6 className="title">14 Indemnity</h6>
                <ul>
                  <li>
                    We reserve the right immediately to terminate your use of
                    the Balm.ai platform if You breach or We have reasonable
                    grounds to believe that You are likely to breach these terms
                    and conditions or You otherwise engage in conduct which We
                    determine in Our sole discretion to be unacceptable.
                  </li>
                  <li>
                    We are not responsible for any damage caused by any of the
                    recommendations or professionals (coaches, therapists,
                    counsellors, nutritionists, dietitians, psychiatrists,
                    specialists, psychologists or experts) regardless of if we
                    have learned firsthand or been briefed our representative
                    the potential for loss or damage.
                  </li>
                  <li>
                    We do not offer any grantees that the material contained on
                    Balm.ai or any of the functions contained in or any of the
                    platform will work without interruption or delay or will be
                    without errors, free of viruses or bugs or work compatable
                    with any other program or other material.
                  </li>
                </ul>

                
                <h6 className="title">15 General Conditions</h6>
                <ul>
                  <li>
                  If any court or regulator decides that any provision of these terms and conditions is invalid or otherwise unenforceable, such provisions shall be severed and deleted from these terms and conditions and the remainder of these terms and conditions shall continue to have full force and effect.
                  </li>
                  <li>
                  These terms and conditions and the Balm.ai site are governed by United States of America’s law. Disputes can be dealt with by the American courts.
                  </li>
                  <li>
                  These terms and conditions apply between us and users and providers. No other person is entitled to benefit under these Terms and Conditions.
                  </li>
                  <li>This agreement requires Balm to obtain your approval acknowledging that you were provided with this information. Your signup, sign in and/or purchase via our payment gateway (stripe) or bank transfers indicates that you have read or listened to the information in this Services Agreement and that you understand it and agree to abide by its terms during your professional relationship with Balm or on behalf of your employees.</li>
                  <li>Your sign up, sign in and/or payment indicates you accept total responsibility in accordance with these terms and conditions.</li>
                  <li>An electronic copy of this agreement may be substituted for and will be legally binding as the original agreement.</li>
                  <li>You hereby authorize Balm, to provide its services to you and/or your organization</li>
                  <li>This agreement constitutes informed consent without exception.</li>
                </ul>

                <h6 className="title">16. Contact Us</h6>
                <ul>
                  <li>
                  If you have any questions about these Terms, please contact us at support@balm.ai
                  </li>
                </ul>

                {/* ends */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TermsOfService;
