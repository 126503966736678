import React from "react";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import ScrollAnimation from "react-animate-on-scroll";

const About2 = (props) => {
  return (
    <div className="edu-counterup-and-course-area">
      {/* <CounterUpOne /> */}

      <div className="edu-course-area  bg-image edu-section-gapTop edu-section-gapBottom">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-10">
              <SectionTitle classes="text-start" title={props.title} />
            </div>
            <div className="row g-5 mt--25" style={{ overflow: "hidden" }}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6>
                  Some of the group training programs for your managers and
                  employees would include:
                  <ul style={{ listStyle: "number" }}>
                    <li>
                      <h6>
                        <span style={{ color: "#385aa2" }}>
                          Stress management:
                        </span>
                        This includes techniques for reducing stress, such as
                        time management, relaxation techniques (such as deep
                        breathing or meditation), and exercise.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <span style={{ color: "#385aa2" }}>
                          Work-life balance:
                        </span>
                        Employees would learn how to manage the demands of work
                        and other commitments, such as family or personal
                        interests.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <span style={{ color: "#385aa2" }}>
                          Coping with difficult emotions:{" "}
                        </span>
                        Employees may need help with managing difficult emotions
                        such as anxiety, depression, or anger. This could
                        include information on how to recognize the signs of
                        these emotions, and strategies for coping.{" "}
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <span style={{ color: "#385aa2" }}>
                          Building healthy relationships:{" "}
                        </span>
                        This would include topics such as communication skills,
                        conflict resolution, and how to build supportive
                        relationships at work.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <span style={{ color: "#385aa2" }}>Self-care:</span>This
                        would include topics such as nutrition, sleep, and
                        exercise, as well as ways to prioritize one's own
                        well-being.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        <span style={{ color: "#385aa2" }}>Seeking help: </span>
                        It's important for employees to know that it's okay to
                        ask for help when they're struggling, and to understand
                        the resources available to them (such as Employee
                        Assistance Programs, counseling services, or other
                        support).
                      </h6>
                    </li>
                  </ul>
                </h6>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About2;
