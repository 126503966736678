import React from "react";

import { Link } from "react-router-dom";

import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne4 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO title="The Art of Financial Discpline" />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="The Art of Financial Discpline"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">The Art of Financial Discpline</h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.pexels.com/photos/5849563/pexels-photo-5849563.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Being financially disciplined involves setting and
                      sticking to a budget, saving money, and making smart
                      financial decisions. Here are a few tips for being
                      financially disciplined:
                      <ul style={{ listStyle: "number" }}>
                        <li>
                          Set a budget: Determine how much money you have coming
                          in and going out each month. This will help you to see
                          where your money is going and where you can make
                          changes to save money.
                        </li>
                        <li>
                          Set financial goals: Having specific goals, such as
                          saving for retirement or paying off debt, can help you
                          to stay focused and motivated to make smart financial
                          decisions.
                        </li>
                        <li>
                          Track your spending: Keep track of your spending to
                          see where your money is going. This can help you to
                          identify areas where you might be able to cut back and
                          save money.
                        </li>
                        <li>
                          Make a plan: Develop a plan for achieving your
                          financial goals. This can include setting up a budget
                          and creating a savings plan.
                        </li>
                        <li>
                          Save money: Make saving money a priority by setting
                          aside a portion of your income each month. Consider
                          setting up automatic transfers to a savings account to
                          make saving easier.
                        </li>
                        <li>
                          Avoid unnecessary expenses: Be mindful of your
                          spending and try to avoid unnecessary expenses. This
                          can include things like eating out, buying unnecessary
                          items, and overspending on entertainment.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Remember that being financially disciplined takes time and
                      effort, but it can be very rewarding in the long run.
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Empowering-you-to-take-control-of-your-mental-health">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                              Empowering you to take control of your mental
                              health.
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/How-to-make-a-good-marital-decision">
                            <span>How to make a good marital decision</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne4;
