import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne15 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="Mental Illness is an Epidemic that theratens our existence
"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Mental Illness is an Epidemic that theratens our existence
          "
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      Mental Illness is an Epidemic that theratens our existence
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.unsplash.com/photo-1584931423298-c576fda54bd2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8ZXBpZGVtaWN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Mental health issues is an epidemic that threatens our
                      existence. We live in a world where over 600 million
                      people suffer from depression and many struggle with their
                      mental health. 71% of adults reported at least one symptom
                      of stress, such as a headache or feeling overwhelmed or
                      anxious.
                    </p>
                    <p>
                      In a self-achieving world like ours, not being able to
                      keep up to demands can lead to depression, unhappiness,
                      high blood pressure, and other diseases. Stress, anxiety,
                      limited resources to meet demand, can also accumulate and
                      lead to burnout.
                    </p>
                    <p>
                      We understand how you feel and you are not alone. We
                      believe you deserve to live a purposeful, productive, and
                      prosperous life by having access to real-time care and
                      support tailored to you, to connect your mind, spirit, and
                      soul in the pursuit of greatness.
                    </p>

                    <blockquote className="blockquote-style-1">
                      “With Balm, you have the ability to dream and bring that
                      dream to life by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions or solutions to challenges you are currently
                      experiencing. visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      Mental Health is not a disgrace. Balm is on a mission to
                      bring happiness to the world. We make it 10x faster to
                      speak with a therapist. We are always here to listen to
                      you.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Our-Innovation-story-at-Balm">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>Our Innovation story at Balm.ai</span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Importance-of-Mental-Health-programs-for-your-employees">
                            <span>
                              Importance of Mental Health programs for your
                              employees
                            </span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne15;
