import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne3 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO title="Empowering you to take control of your mental health" />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Empowering you to take control of your mental health."
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      Empowering you to take control of your mental health.
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.unsplash.com/photo-1497634763913-2ea08bf9de5d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZW1wb3dlcmluZyUyMG1lbnRhbCUyMGhlYWx0aHxlbnwwfDB8MHx8&auto=format&fit=crop&w=1000&q=60`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      It is common to need support on your journey to better
                      mental health. Mental health issues can be complex and
                      often require personalized treatment plans. Seeking
                      support from a mental health professional or other trusted
                      source can be an important step in improving your mental
                      health and well-being.
                    </p>
                    <p>
                      There are many different types of support that can be
                      helpful on your journey to better mental health. This can
                      include therapy, which can provide a safe and supportive
                      environment for exploring and addressing mental health
                      issues. Other types of support that may be helpful include
                      support groups, which can provide a sense of community and
                      connection with others who are experiencing similar
                      challenges, and self-care practices, which can help to
                      improve overall physical and mental well-being.
                    </p>
                    <p>
                      It's also important to build a strong support network of
                      friends, family, and colleagues who can provide emotional
                      and practical support as you work towards better mental
                      health. It's okay to ask for help when you need it and to
                      accept support from others. Remember that improving mental
                      health is a journey and it takes time and effort. Seek
                      support when needed and be patient with yourself as you
                      work towards better mental health.
                    </p>
                    <p>
                      There are a few steps you can take to empower yourself to
                      take control of your mental health:
                      <ul style={{ listStyle: "number" }}>
                        <li>
                          Educate yourself: Learning about mental health and the
                          different treatment options available can help you
                          feel more informed and in control of your own mental
                          health journey.
                        </li>
                        <li>
                          Seek professional help: If you are struggling with
                          your mental health, it's important to seek support
                          from a mental health professional. They can help you
                          identify the root causes of your mental health issues
                          and develop a treatment plan that is tailored to your
                          needs.
                        </li>
                        <li>
                          Practice self-care: Taking care of yourself is
                          essential for maintaining good mental health. This can
                          include getting enough sleep, exercising regularly,
                          eating a healthy diet, and engaging in activities that
                          bring you joy and relaxation.
                        </li>
                        <li>
                          Set goals and work towards them: Setting goals and
                          working towards them can give you a sense of purpose
                          and accomplishment, which can be helpful for improving
                          your mental health. Be sure to set realistic goals and
                          break them down into smaller, achievable steps.
                        </li>
                        <li>
                          Build a support network: Surrounding yourself with
                          supportive people can help to improve your mental
                          health and well-being. This can include friends,
                          family, and colleagues, as well as mental health
                          professionals and support groups.
                        </li>
                        <li>
                          Find healthy ways to cope with stress: Stress is a
                          normal part of life, but it's important to find
                          healthy ways to cope with it. This can include
                          practices like mindfulness, relaxation techniques, and
                          physical activity.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Remember that improving mental health is a journey and it
                      takes time and effort. It's also important to be kind to
                      yourself and to recognize that it's okay to have ups and
                      downs. Seek support when needed and be patient with
                      yourself as you work towards better mental health.
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Support-needed-on-your-journey to-better-mental-health">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                              Support needed on your journey to better mental
                              health.
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/The-Art-of-Financial-Discipline">
                            <span>The Art of Financial Discpline</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne3;
