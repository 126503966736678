import { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updateUserProfile } from "../../actions/userActions";

const BasicInfo = () => {
  // cloudinary
  const [loadingid, setLoadingid] = useState(false);
  const [cloudImage, setCloudImage] = useState({});
  // serverstates
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImg, setProfileImg] = useState({});
  const [phone, setPhone] = useState();
  const [gender, setGender] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  // health goals
  const [healthGoals, setHealthGoals] = useState("");
  // demographics
  const [dob, setDob] = useState("");
  const [race, setRace] = useState(""); //race/ethnicity
  const [language, setLanguage] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [genotype, setGenotype] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  // biodata
  const [bloodPressure, setBloodPressure] = useState("");
  const [pulse, setPulse] = useState("");
  const [temperature, setTemperature] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bmi, setBmi] = useState(0);
  // Allergies
  const [allergies, setAllergies] = useState("");
  // social history
  const [tobaccoUse, setTobaccoUse] = useState("");
  const [alcoholUse, setAlcoholUse] = useState("");
  const [substanceUse, setSubstanceUse] = useState("");
  // lifestyle
  const [exercise, setExercise] = useState("");
  const [junkFood, setJunkFood] = useState("");
  const [water, setWater] = useState("");
  const [walks, setWalks] = useState("");
  const [healthyMeals, setHealthyMeals] = useState("");
  const [sleep, setSleep] = useState("");
  // medications
  const [medication1, setMedication1] = useState("");
  const [date1, setDate1] = useState("");
  const [medication2, setMedication2] = useState("");
  const [date2, setDate2] = useState("");
  const [medication3, setMedication3] = useState("");
  const [date3, setDate3] = useState("");
  // active health problems
  const [healthProblem1, setHealthProblem1] = useState("");
  const [dateDiagnosed1, setDateDiagnosed1] = useState("");
  const [healthProblem2, setHealthProblem2] = useState("");
  const [dateDiagnosed2, setDateDiagnosed2] = useState("");
  const [healthProblem3, setHealthProblem3] = useState("");
  const [dateDiagnosed3, setDateDiagnosed3] = useState("");
  // resolved health problems
  const [resolvedProblem1, setResolvedProblem1] = useState("");
  const [resolvedDate1, setResolvedDate1] = useState("");
  const [resolvedProblem2, setResolvedProblem2] = useState("");
  const [resolvedDate2, setResolvedDate2] = useState("");
  const [resolvedProblem3, setResolvedProblem3] = useState("");
  const [resolvedDate3, setResolvedDate3] = useState("");
  // immunizations
  const [immunization1, setImmunization1] = useState("");
  const [dateTaken1, setDateTaken1] = useState("");
  const [immunization2, setImmunization2] = useState("");
  const [dateTaken2, setDateTaken2] = useState("");
  const [immunization3, setImmunization3] = useState("");
  const [dateTaken3, setDateTaken3] = useState("");

  // redux
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { userInfo } = userSignin;

  useEffect(() => {
    if (userUpdateProfile.success) {
      // toast.success("profile updated successfully")
    }
  }, [userUpdateProfile]);

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setEmail(userInfo?.email);
      setPhone(userInfo.phone);
      setProfileImg(userInfo?.profileImg);
      setGender(userInfo.gender);
      setAddress1(userInfo.address1);
      setAddress2(userInfo.address2);
      setCity(userInfo.city);
      setState(userInfo.state);
      setCountry(userInfo.country);
      // health goals
      setHealthGoals(userInfo?.healthGoals);
      // demographics
      setDob(userInfo?.dob);
      setRace(userInfo?.race);
      setLanguage(userInfo?.language);
      setMaritalStatus(userInfo?.maritalStatus);
      setGenotype(userInfo?.genotype);
      setBloodGroup(userInfo?.bloodGroup);
      // biodata
      setBloodPressure(userInfo?.bloodPresure);
      setPulse(userInfo?.pulse);
      setTemperature(userInfo?.temperature);
      setWeight(userInfo?.weight);
      setHeight(userInfo?.height);
      setBmi(userInfo?.bmi);
      // allergies
      setAllergies(userInfo?.allergies);
      // social history
      setTobaccoUse(userInfo?.tobaccoUse);
      setAlcoholUse(userInfo?.alcoholUse);
      setSubstanceUse(userInfo?.substanceUse);
      // lifestyle
      setExercise(userInfo?.exercise);
      setJunkFood(userInfo?.junkFood);
      setWater(userInfo?.water);
      setWalks(userInfo?.walks);
      setHealthyMeals(userInfo?.healthyMeals);
      setSleep(userInfo?.sleep);
      // medications
      setMedication1(userInfo?.Medication1);
      setDate1(userInfo?.date1);
      setMedication2(userInfo?.Medication2);
      setDate2(userInfo?.date2);
      setMedication3(userInfo?.Medication3);
      setDate3(userInfo?.date3);
      // active health problems
      setHealthProblem1(userInfo?.healthProblem1);
      setDateDiagnosed1(userInfo?.dateDiagnosed1);
      setHealthProblem2(userInfo?.healthProblem2);
      setDateDiagnosed2(userInfo?.dateDiagnosed2);
      setHealthProblem3(userInfo?.healthProblem3);
      setDateDiagnosed3(userInfo?.dateDiagnosed3);
      // resolved health problems
      setResolvedProblem1(userInfo?.resolvedProblem1);
      setResolvedDate1(userInfo?.resolvedDate1);
      setResolvedProblem2(userInfo?.resolvedProblem2);
      setResolvedDate2(userInfo?.resolvedDate2);
      setResolvedProblem3(userInfo?.resolvedProblem3);
      setResolvedDate3(userInfo?.resolvedDate3);
      // immunizations
      setImmunization1(userInfo?.immunization1);
      setDateTaken1(userInfo?.dateTaken1);
      setImmunization2(userInfo?.immunization2);
      setDateTaken2(userInfo?.dateTaken2);
      setImmunization3(userInfo?.immunization3);
      setDateTaken3(userInfo?.dateTaken3);

      // uploads
      setCloudImage(userInfo?.profileImg);
    }
  }, [userInfo]);

  // cloudinary upload
  const fileUploadAndResize = (e) => {
    let id = cloudImage?.public_id;

    let file = e.target.files;
    if (file) {
      setLoadingid(true);
      for (let i = 0; i < file.length; i++) {
        Resizer.imageFileResizer(
          file[i],
          720,
          720,
          "JPEG",
          50,
          0,
          (uri) => {
            console.log(uri);
            axios
              .post(` ${process.env.REACT_APP_API_KEY}uploads`, {
                image: uri,
                id,
              })
              .then((res) => {
                setLoadingid(false);
                setCloudImage(res.data);
                setProfileImg(res.data);
              })
              .catch((err) => {
                setLoadingid(false);
              });
          },
          "base64"
        );
      }
    }
  };

  // submit
  const submitHandler = (e) => {
    e.preventDefault();

    let user = {
      _id: userInfo._id,
      name,
      phone,
      profileImg,
      gender,
      address1,
      address2,
      city,
      state,
      country,
      // health goals
      healthGoals,
      // demographics
      dob,
      race,
      language,
      maritalStatus,
      genotype,
      bloodGroup,
      // biodata
      bloodPressure,
      pulse,
      temperature,
      weight,
      height,
      bmi,
      // allergies
      allergies,
      // social history
      tobaccoUse,
      alcoholUse,
      substanceUse,
      // lifestyle
      exercise,
      junkFood,
      water,
      walks,
      healthyMeals,
      sleep,
      // medications
      medication1,
      date1,
      medication2,
      date2,
      medication3,
      date3,
      // active health problems
      healthProblem1,
      dateDiagnosed1,
      healthProblem2,
      dateDiagnosed2,
      healthProblem3,
      dateDiagnosed3,
      // resolved health problems
      resolvedProblem1,
      resolvedDate1,
      resolvedProblem2,
      resolvedDate2,
      resolvedProblem3,
      resolvedDate3,
      // immunization
      immunization1,
      dateTaken1,
      immunization2,
      dateTaken2,
      immunization3,
      dateTaken3,
    };
    dispatch(updateUserProfile(user));
  };
  return (
    <>
      {/* basic */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Personal Details</h6>
          <div className="expert-basic-profile">
            <div className="expert-basic-proile-img">
              {loadingid ? (
                <>loading...</>
              ) : (
                <img
                  src={
                    cloudImage?.url ||
                    `https://res.cloudinary.com/balmai/image/upload/v1658915462/uploadPH_gwz3sl.jpg`
                  }
                  alt="Upload"
                />
              )}
            </div>
            <div className="expert-basic-profile-btn">
              <input
                type="file"
                multiple
                onChange={fileUploadAndResize}
                accept="images/*"
                className="btn btn-primary"
                style={{ color: "white" }}
                placeholder="Upload photo"
              />

              <p>Allowed JPG, GIF or PNG. Max size of 2MB</p>
            </div>
          </div>
          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Full Name *</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Phone Number *</label>
                <input
                  type="tel"
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Email *</label>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  disabled
                />
              </div>
              <div className="form-input-box">
                <label>Gender *</label>
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">select</option>
                  <option value="male">male</option>
                  <option value="female">female</option>
                </select>
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                {/* <label>Last Name *</label>
                <input type="text" className="form-control" /> */}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* contact details */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Contact Address</h6>
          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Address Line 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>State / Province</label>
                <input
                  type="text"
                  className="form-control"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Country</label>
                <input
                  type="text"
                  className="form-control"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* health goals */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Health Goals</h6>
          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>What are your health goals?</label>
                <textarea
                  cols={6}
                  type="text"
                  className="form-control"
                  value={healthGoals}
                  onChange={(e) => setHealthGoals(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* demographics */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Demographics</h6>
          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Date of Birth</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Race / Ethnicity</label>
                <input
                  type="text"
                  className="form-control"
                  value={race}
                  onChange={(e) => setRace(e.target.value)}
                />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Language</label>
                <input
                  type="text"
                  className="form-control"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Marital Status</label>
                <input
                  type="text"
                  className="form-control"
                  value={maritalStatus}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Genotype</label>
                <input
                  type="text"
                  className="form-control"
                  value={genotype}
                  onChange={(e) => setGenotype(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Blood Group</label>
                <input
                  type="text"
                  className="form-control"
                  value={bloodGroup}
                  onChange={(e) => setBloodGroup(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* biodata */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Biodata</h6>
          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Blood Pressure</label>
                <input
                  type="text"
                  className="form-control"
                  value={bloodPressure}
                  onChange={(e) => setBloodPressure(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Pulse</label>
                <input
                  type="text"
                  className="form-control"
                  value={pulse}
                  onChange={(e) => setPulse(e.target.value)}
                />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Temperature</label>
                <input
                  type="text"
                  className="form-control"
                  value={temperature}
                  onChange={(e) => setTemperature(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Weight</label>
                <input
                  type="text"
                  className="form-control"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Height</label>
                <input
                  type="text"
                  className="form-control"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Body Mass Index</label>
                <input
                  type="text"
                  className="form-control"
                  value={bmi}
                  onChange={(e) => setBmi(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Allergies */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Allergies</h6>
          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>List fruits or food allergies</label>
                <textarea
                  cols={6}
                  type="text"
                  className="form-control"
                  value={allergies}
                  onChange={(e) => setAllergies(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Social History */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Social History</h6>

          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Tobacco Use</label>
                <select
                  value={tobaccoUse}
                  onChange={(e) => setTobaccoUse(e.target.value)}
                >
                  <option value="">select</option>
                  <option value="Active">Active</option>
                  <option value="Ocassional">Ocassional</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="form-input-box">
                <label>Alcohol Use</label>
                <select
                  value={alcoholUse}
                  onChange={(e) => setAlcoholUse(e.target.value)}
                >
                  <option value="">select</option>
                  <option value="Active">Active</option>
                  <option value="Ocassional">Ocassional</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Substance Use</label>
                <select
                  value={substanceUse}
                  onChange={(e) => setSubstanceUse(e.target.value)}
                >
                  <option value="">select</option>
                  <option value="Active">Active</option>
                  <option value="Ocassional">Ocassional</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* life style */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Life Style</h6>

          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Do you often exercise?</label>
                <select
                  value={exercise}
                  onChange={(e) => setExercise(e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="Not really">Not really</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="form-input-box">
                <label>
                  Do you often eat fast food, junk food, processed food or dink
                  Soda?
                </label>
                <select
                  value={junkFood}
                  onChange={(e) => setJunkFood(e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="Not really">Not really</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Do you often drink water</label>
                <select
                  value={water}
                  onChange={(e) => setWater(e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="Not really">Not really</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="form-input-box">
                <label>Do you often take walks</label>
                <select
                  value={walks}
                  onChange={(e) => setWalks(e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="Not really">Not really</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Do you often eat health meals</label>
                <select
                  value={healthyMeals}
                  onChange={(e) => setHealthyMeals(e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="Not really">Not really</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="form-input-box">
                <label>Do you sleep at least 7 hours a day</label>
                <select
                  value={sleep}
                  onChange={(e) => setSleep(e.target.value)}
                >
                  <option value="Yes">Yes</option>
                  <option value="Not really">Not really</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* medications */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Medications</h6>

          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Medication Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={medication1}
                  onChange={(e) => setMedication1(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date adminitered</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={date1}
                  onChange={(e) => setDate1(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Medication name</label>
                <input
                  type="text"
                  className="form-control"
                  value={medication2}
                  onChange={(e) => setMedication2(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date adminitered</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={date2}
                  onChange={(e) => setDate2(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Medication name</label>
                <input
                  type="text"
                  className="form-control"
                  value={medication3}
                  onChange={(e) => setMedication3(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date adminitered</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={date3}
                  onChange={(e) => setDate3(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Active health problems */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Active health problems</h6>

          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Health problem</label>
                <input
                  type="text"
                  className="form-control"
                  value={healthProblem1}
                  onChange={(e) => setHealthProblem1(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date Diagonosed</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={dateDiagnosed1}
                  onChange={(e) => setDateDiagnosed1(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Health problem</label>
                <input
                  type="text"
                  className="form-control"
                  value={healthProblem2}
                  onChange={(e) => setHealthProblem2(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date Diagonosed</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={dateDiagnosed2}
                  onChange={(e) => setDateDiagnosed2(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Health problem</label>
                <input
                  type="text"
                  className="form-control"
                  value={healthProblem3}
                  onChange={(e) => setHealthProblem3(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date Diagonosed</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={dateDiagnosed3}
                  onChange={(e) => setDateDiagnosed3(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Resolved health problems */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Resolved health problems</h6>

          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Health problem</label>
                <input
                  type="text"
                  className="form-control"
                  value={resolvedProblem1}
                  onChange={(e) => setResolvedProblem1(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date Resolved</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={resolvedDate1}
                  onChange={(e) => setResolvedDate1(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Health problem</label>
                <input
                  type="text"
                  className="form-control"
                  value={resolvedProblem2}
                  onChange={(e) => setResolvedProblem2(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date Resolved</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={resolvedDate2}
                  onChange={(e) => setResolvedDate2(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Health problem</label>
                <input
                  type="text"
                  className="form-control"
                  value={resolvedProblem3}
                  onChange={(e) => setResolvedProblem3(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date Resolved</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={resolvedDate3}
                  onChange={(e) => setResolvedDate3(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Immunizattions */}
      <div className="expert-basic-info">
        <div className="title">
          <h6>Immunizations</h6>

          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Immunizations taken</label>
                <input
                  type="text"
                  className="form-control"
                  value={immunization1}
                  onChange={(e) => setImmunization1(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date administered</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={dateTaken1}
                  onChange={(e) => setDateTaken1(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Immunizations taken</label>
                <input
                  type="text"
                  className="form-control"
                  value={immunization2}
                  onChange={(e) => setImmunization2(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date administered</label>
                <input
                  type="text"
                  placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={dateTaken2}
                  onChange={(e) => setDateTaken2(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Immunizations taken</label>
                <input
                  type="text"
                  className="form-control"
                  value={immunization3}
                  onChange={(e) => setImmunization3(e.target.value)}
                />
              </div>
              <div className="form-input-box">
                <label>Date administered</label>
                <input
                  type="text"
                   placeholder="dd/mm/yyyy"
                  className="form-control"
                  value={dateTaken3}
                  onChange={(e) => setDateTaken3(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <button
        className="rn-btn edu-btn  mb--30"
        type="submit"
        onClick={submitHandler}
      >
        {userUpdateProfile.loading ? "Updating..." : "Save Changes"}
      </button>
    </>
  );
};

export default BasicInfo;
