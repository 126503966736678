/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import GoogleBtn from "../../data/GoogleSvg";
import { toast } from "react-toastify";

export default function Checkout({ setIsShow }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [eventPrice, setEventPrice] = useState();
  const [code, setCode] = useState();
  const [discount, setDiscount] = useState();
  const [amount, setAmount] = useState();
  const [discountLoading, setDiscountLoading] = useState(false);

  // redux
  const addMeetingDetails = useSelector((state) => state.addMeetingDetails);
  const { addMeetingInfo } = addMeetingDetails;

  useEffect(() => {
    if (addMeetingInfo) {
      setEventPrice(Number(addMeetingInfo?.finalPrice));
      setAmount(addMeetingInfo?.finalPrice);
    }
  }, [addMeetingInfo]);

  useEffect(() => {
    setError(null);
    const timer = setTimeout(() => {
      if (amount) {
        fetch(`${process.env.REACT_APP_API_KEY}create-payment-intent`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodType: "card",
            currency: "usd",
            amt: amount,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setClientSecret(data.clientSecret);
          });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [amount]);

  const cardStyle = {
    style: {
      marginBottom: "20px",
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    setError(null);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      // setIsShow(true);
    }
  };
  const handleShow = (e) => {
    e.preventDefault();
    setIsShow(true);
  };
  // check discount
  const handleDiscountSubmit = async (event) => {
    event.preventDefault();
    setDiscountLoading(true);
    setError(null);

    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}discount?code=${code}&amount=${addMeetingInfo?.finalPrice}`
    );
    const data = await response.json();
    if (data.error) {
      setError(data.error);
      toast.error(data.error);
      setDiscountLoading(false);
    } else {
      setDiscount(data.discount);
      setAmount(data.newAmount);
      setDiscountLoading(false);
    }
  };

  return (
    <>
      <form id="payment-form" className="pay-ment-form">
        <p style={{ marginBottom: "6px" }}>Enter coupon code ?</p>

        {eventPrice > 0 && (
          <div>
            <form>
              <input
                placeholder="enter discount code"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                style={{
                  marginBottom: "5px",
                  borderRadius: "3.5px",
                  border: "1px solid rgba(50, 50, 93, 0.1)",
                }}
              />
              <button
                onClick={handleDiscountSubmit}
                className="edu-btn"
                type="submit"
              >
                {discountLoading ? "Loading..." : "Apply"}
              </button>
            </form>
            <p style={{ marginTop: "10px" }}>Total: ${amount}</p>
            <CardElement
              id="card-element"
              options={cardStyle}
              onChange={handleChange}
            />
          </div>
        )}

        <hr />
        {!succeeded && eventPrice > 0 && (
          <Button
            disabled={processing || disabled || succeeded}
            id="submit"
            type="submit"
            onClick={handleSubmit}
            className="pay-button"
          >
            <span id="button-text">
              {processing ? (
                <i
                  className={"fa fa-spinner fa-spin"}
                  style={{ margin: "1rem" }}
                ></i>
              ) : (
                `Pay ${amount ? `$${amount}` : "again"}`
              )}
            </span>
          </Button>
        )}

        {error && (
          <div style={{ marginTop: "10px" }} className="error-bar" role="alert">
            {error}
          </div>
        )}
        {succeeded && (
          <div
            style={{ marginTop: "10px" }}
            className="success-bar"
            role="alert"
          >
            <p
              className={succeeded ? "result-message" : "result-message hidden"}
            >
              Payment succeeded! <br />
              Sing in with your google account below <br />
              to complete your booking and add meeting to your calendar.
            </p>
            <button
              onClick={handleShow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#4285F4",
                marginTop: "5px",
                height: "41px",
                paddingTop: "-5px",
                outline: "none",
                border: "none",
                borderRadius: "3px",
                paddingRight: "8px",
              }}
            >
              <GoogleBtn style={{ marginLeft: "-8px", marginRight: "18px" }} />
              Sign in with Google
            </button>
          </div>
        )}
        {eventPrice === 0 && (
          <div>
            <p>
              Sing in with your google account below <br />
              to complete your booking and add meeting to your calendar.
            </p>
            <button
              onClick={handleShow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#4285F4",
                marginTop: "5px",
                height: "41px",
                paddingTop: "-5px",
                outline: "none",
                border: "none",
                borderRadius: "3px",
                paddingRight: "8px",
              }}
            >
              <GoogleBtn style={{ marginLeft: "-8px", marginRight: "18px" }} />
              Sign in with Google
            </button>
          </div>
        )}
      </form>
    </>
  );
}
