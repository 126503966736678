const flatDeep = function( arr, d = 1 ) {
    return d > 0 ? arr.reduce( ( acc, val ) => acc.concat( Array.isArray (val ) ? flatDeep( val, d - 1 ) : val ), [] ) : arr.slice();
}

const slugify = function( text ) {
    return text
        .toString()
        .toLowerCase()
        .replace( /\s+/g, '-' ) // Replace spaces with -
        .replace( /[^\w-]+/g, '' ) // Remove all non-word chars
        .replace( /--+/g, '-' ) // Replace multiple - with single -
        .replace( /^-+/, '' ) // Trim - from start of text
        .replace( /-+$/, '' ) // Trim - from end of text
}

const containsObject = function( obj, list ) {
    var i;
    for ( i = 0; i < list.length; i++ ) {
        if ( list[i].slug === obj.slug ) {
            return i;
        }
    }
    return -1;
}

const formatPrice = ( number ) => {
    return new Intl.NumberFormat( 'en-US', {
        style: 'currency',
        currency: 'USD'
    } ).format( number )
}
  
const getUniqueValues = ( data, type, withAll = 'active' ) => {
    let unique = data.map( ( item ) => item[type] );
    if ( withAll === 'active' ) {
        return ['all', ...new Set( unique )];
    } else {
        return [...new Set( unique )];
    }
}

const getUniqueValues2 = ( data, itemName ) => {
    const items = data.map(item => {
        return item.itemName;
    } );

    let singleCatArray = flatDeep( items );
    let itemArray = [];
    singleCatArray.forEach( cat => {
        const obj = {
            title: cat.trim(),
            slug: slugify( cat ),
            count: 1
        }
        const objIndex = containsObject( obj, itemArray );
        if( objIndex !== -1 ){
            const prevCount = itemArray[objIndex].count;
            itemArray[objIndex] = {
                title: cat.trim(),
                slug: slugify( cat ),
                count: prevCount + 1
            }
        } else {
            itemArray.push( obj );
        }
    } );
}

const my_timezones=[
    { value: "Africa/Abidjan", label: "Africa/Abidjan GMT+0:00" },
    { value: "Africa/Accra", label: "Africa/Accra GMT+0:00" },
    { value: "Africa/Addis_Ababa", label: "Africa/Addis_Ababa GMT+3:00" },
    { value: "Africa/Algiers", label: "Africa/Algiers GMT+1:00" },
    { value: "Africa/Asmara", label: "Africa/Asmara GMT+3:00" },
    { value: "Africa/Bamako", label: "Africa/Bamako GMT+0:00" },
    { value: "Africa/Bangui", label: "Africa/Bangui GMT+1:00" },   
    { value: "Africa/Banjul", label: "Africa/Banjul GMT+0:00" },   
    { value: "Africa/Bissau", label: "Africa/Bissau GMT+0:00" },
    { value: "Africa/Blantyre", label: "Africa/Blantyre GMT+2:00" },
    { value: "Africa/Brazzaville ", label: "Africa/Brazzaville GMT+1:00" },
    { value: "Africa/Bujumbura", label: "Africa/Bujumbura GMT+2:00" },
    { value: "Africa/Cairo", label: "Africa/Cairo GMT+2:00" },
    { value: "Africa/Casablanca", label: "Africa/Casablanca GMT+0:00" },
    { value: "Africa/Ceuta", label: "Africa/Ceuta GMT+1:00" },
    { value: "Africa/Conakry", label: "Africa/Conakry GMT+0:00" },
    { value: "Africa/Dakar", label: "Africa/Dakar GMT+0:00" },
    { value: "Africa/Dar_es_Salaam", label: "Africa/Dar_es_Salaam GMT+3:00" },
    { value: "Africa/Djibouti", label: "Africa/Djibouti GMT+3:00" },
    { value: "Africa/Douala", label: "Africa/Douala GMT+1:00" },
    { value: "Africa/El_Aaiun", label: "Africa/El_Aaiun GMT+0:00" },
    { value: "Africa/Freetown", label: "Africa/Freetown GMT+0:00" },
    { value: "Africa/Gaborone", label: "Africa/Gaborone GMT+2:00" },
    { value: "Africa/Harare", label: "Africa/Harare GMT+2:00" },
    { value: "Africa/Johannesburg", label: "Africa/Johannesburg GMT+2:00" },
    { value: "Africa/Juba", label: "Africa/Juba GMT+3:00" },
    { value: "Africa/Kampala", label: "Africa/Kampala GMT+3:00" },
    { value: "Africa/Khartoum", label: "Africa/Khartoum GMT+2:00" },
    { value: "Africa/Kigali", label: "Africa/Kigali GMT+2:00" },
    { value: "Africa/Kinshasa", label: "Africa/Kinshasa GMT+1:00" },
    { value: "Africa/Lagos", label: "Africa/Lagos GMT+1:00" },
    { value: "Africa/Libreville", label: "Africa/Libreville GMT+1:00" },
    { value: "Africa/Lome", label: "Africa/Lome GMT+0:00" },
    { value: "Africa/Luanda", label: "Africa/Luanda GMT+1:00" },
    { value: "Africa/Lubumbashi", label: "Africa/Lubumbashi GMT+2:00" },
    { value: "Africa/Lusaka", label: "Africa/Lusaka GMT+2:00" },
    { value: "Africa/Malabo", label: "Africa/Malabo GMT+1:00" },
    { value: "Africa/Maputo", label: "Africa/Maputo GMT+2:00" },
    { value: "Africa/Maseru", label: "Africa/Maseru GMT+2:00" },
    { value: "Africa/Mbabane", label: "Africa/Mbabane GMT+2:00" },
    { value: "Africa/Mogadishu", label: "Africa/Mogadishu GMT+3:00" },
    { value: "Africa/Monrovia", label: "Africa/Monrovia GMT+0:00" },
    { value: "Africa/Nairobi", label: "Africa/Nairobi GMT+3:00" },
    { value: "Africa/Ndjamena", label: "Africa/Ndjamena GMT+1:00" },
    { value: "Africa/Niamey", label: "Africa/Niamey GMT+1:00" },
    { value: "Africa/Nouakchott", label: "Africa/Nouakchott GMT+0:00" },
    { value: "Africa/Ouagadougou", label: "Africa/Ouagadougou GMT+0:00" },
    { value: "Africa/Porto-Novo", label: "Africa/Porto-Novo GMT+1:00" },
    { value: "Africa/Sao_Tome", label: "Africa/Sao_Tome GMT+0:00" },
    { value: "Africa/Timbuktu", label: "Africa/Timbuktu GMT+0:00" },
    { value: "Africa/Tripoli", label: "Africa/Tripoli GMT+2:00" },
    { value: "Africa/Tunis", label: "Africa/Tunis GMT+1:00" },
    { value: "Africa/Windhoek", label: "Africa/Windhoek GMT+2:00" },
    { value: "America/Adak", label: "America/Adak GMT-10:00" },
    { value: "America/Anchorage", label: "America/Anchorage GMT-9:00" },
    { value: "America/Anguilla", label: "America/Antigua GMT-4:00" },
    { value: "America/Anguilla", label: "America/Anguilla GMT-4:00" },
    { value: "America/Araguaina", label: "America/Araguaina GMT-3:00" },
    { value: "America/Argentina/Buenos_Aires", label: "America/Argentina/Buenos_Aires GMT-3:00" },
    { value: "America/Argentina/Catamarca", label: "America/Argentina/Catamarca GMT-3:00" },
    { value: "America/Argentina/ComodRivadavia", label: "America/Argentina/ComodRivadavia GMT-3:00" },
    { value: "America/Argentina/Cordoba", label: "America/Argentina/Cordoba GMT-3:00" },
    { value: "America/Argentina/Jujuy", label: "America/Argentina/Jujuy GMT-3:00" },
    { value: "America/Argentina/La_Rioja", label: "America/Argentina/La_Rioja GMT-3:00" },
    { value: "America/Argentina/Mendoza", label: "America/Argentina/Mendoza GMT-3:00" },
    { value: "America/Argentina/Rio_Gallegos", label: "America/Argentina/Rio_Gallegos GMT-3:00" },
    { value: "America/Argentina/Salta", label: "America/Argentina/Salta GMT-3:00" },
    { value: "America/Argentina/San_Juan", label: "America/Argentina/San_Juan GMT-3:00" },
    { value: "America/Argentina/San_Luis", label: "America/Argentina/San_Luis GMT-3:00" },
    { value: "America/Argentina/Tucuman", label: "America/Argentina/Tucuman GMT-3:00" },
    { value: "America/Argentina/Ushuaia", label: "America/Argentina/Ushuaia GMT-3:00" },
    { value: "America/Aruba", label: "America/Aruba GMT-4:00" },
    { value: "America/Asuncion", label: "America/Asuncion GMT-4:00" },
    { value: "America/Bahia_Banderas", label: "America/Bahia_Banderas GMT-6:00" },
    { value: "America/Barbados", label: "America/Barbados GMT-4:00" },
    { value: "America/Belem", label: "America/Belem GMT-3:00" },
    { value: "America/Belize", label: "America/Belize GMT-6:00" },
    { value: "America/Blanc-Sablon", label: "America/Blanc-Sablon GMT-4:00" },
    { value: "America/Boa_Vista", label: "America/Boa_Vista GMT-7:00" },
    { value: "America/Bogota", label: "America/Bogota GMT-5:00" },
    { value: "America/Boise", label: "America/Boise GMT-7:00" },
    { value: "America/Cambridge_Bay", label: "America/Cambridge_Bay GMT-7:00" },
    { value: "America/Campo_Grande", label: "America/Campo_Grande GMT-4:00" },
    { value: "America/Cancun", label: "America/Cancun GMT-5:00" },
    { value: "America/Caracas", label: "America/Caracas GMT-4:00" },
    { value: "America/Cayenne", label: "America/Cayenne GMT-3:00" },
    { value: "America/Cayman", label: "America/Cayman GMT-5:00" },
    { value: "America/Chicago", label: "America/Chicago GMT-6:00" },
    { value: "America/Chihuahua", label: "America/Chihuahua GMT-7:00" },
    { value: "America/Cordoba", label: "America/Cordoba GMT-3:00" },
    { value: "America/Costa_Rica", label: "America/Costa_Rica GMT-6:00" },
    { value: "America/Creston", label: "America/Creston GMT-7:00" },
    { value: "America/Cuiaba", label: "America/Cuiaba GMT-4:00" },
    { value: "America/Curacao", label: "America/Curacao GMT-4:00" },
    { value: "America/Danmarkshavn", label: "America/Danmarkshavn GMT+0:00" },
    { value: "America/Dawson", label: "America/Dawson GMT-8:00" },
    { value: "America/Dawson_Creek", label: "America/Dawson_Creek GMT-7:00" },
    { value: "America/Denver", label: "America/Denver GMT-7:00" },
    { value: "America/Detroit", label: "America/Detroit GMT-5:00" },
    { value: "America/Dominica", label: "America/Dominica GMT-4:00" },
    { value: "America/Edmonton", label: "America/Edmonton GMT-7:00" },
    { value: "America/Eirunepe", label: "America/Eirunepe GMT-5:00" },
    { value: "America/El_Salvador", label: "America/El_Salvador GMT-6:00" },
    { value: "America/Fort_Nelson", label: "America/Fort_Nelson GMT-7:00" },
    { value: "America/Fortaleza", label: "America/Fortaleza GMT-3:00" },
    { value: "America/Glace_Bay", label: "America/Glace_Bay GMT-4:00" },
    { value: "America/Godthab", label: "America/Godthab GMT-3:00" },
    { value: "America/Goose_Bay", label: "America/Goose_Bay GMT-4:00" },
    { value: "America/Grand_Turk", label: "America/Grand_Turk GMT-5:00" },
    { value: "America/Grenada", label: "America/Grenada GMT-4:00" },
    { value: "America/Guadeloupe", label: "America/Guadeloupe GMT-4:00" },
    { value: "America/Guatemala", label: "America/Guatemala GMT-6:00" },
    { value: "America/Guayaquil", label: "America/Guayaquil GMT-5:00" },
    { value: "America/Guyana", label: "America/Guyana GMT-4:00" },
    { value: "America/Halifax", label: "America/Halifax GMT-4:00" },
    { value: "America/Havana", label: "America/Havana GMT-5:00" },
    { value: "America/Hermosillo", label: "America/Hermosillo GMT-7:00" },
    { value: "America/Indiana/Indianapolis", label: "America/Indiana/Indianapolis GMT-5:00" },
    { value: "America/Indianapolis", label: "America/Indianapolis GMT-5:00" },
    { value: "America/Indiana/Knox", label: "America/Indiana/Knox GMT-6:00" },
    { value: "America/Indiana/Marengo", label: "America/Indiana/Marengo GMT-5:00" },
    { value: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg GMT-5:00" },
    { value: "America/Indiana/Tell_City", label: "America/Indiana/Tell_City GMT-6:00" },
    { value: "America/Indiana/Vevay", label: "America/Indiana/Vevay GMT-5:00" },
    { value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes GMT-5:00" },
    { value: "America/Indiana/Winamac", label: "America/Indiana/Winamac GMT-5:00" },
    { value: "America/Inuvik", label: "America/Inuvik GMT-7:00" },
    { value: "America/Iqaluit", label: "America/Iqaluit GMT-5:00" },
    { value: "America/Jamaica", label: "America/Jamaica GMT-5:00" },
    { value: "America/Jujuy", label: "America/Jujuy GMT-3:00" },
    { value: "America/Juneau", label: "America/Juneau GMT-9:00" },
    { value: "America/Kentucky/Louisville", label: "America/Kentucky/Louisville GMT-5:00" },
    { value: "America/Kentucky/Monticello", label: "America/Kentucky/Monticello GMT-5:00" },
    { value: "America/Knox-IN", label: "America/Knox_IN GMT-6:00" },
    { value: "America/Kralendijk", label: "America/Kralendijk GMT-5:00" },
    { value: "America/La_Paz", label: "America/La_Paz GMT-4:00" },
    { value: "America/Lima", label: "America/Lima GMT-5:00" },
    { value: "America/Los_Angeles", label: "America/Los_Angeles GMT-8:00" },
    { value: "America/Louisville", label: "America/Louisville GMT-5:00" },
    { value: "America/Lower_Princes", label: "America/Lower_Princes GMT-3:00" },
    { value: "America/Maceio", label: "America/Maceio GMT-3:00" },
    { value: "America/Managua", label: "America/Managua GMT-6:00" },
    { value: "America/Manaus", label: "America/Manaus GMT-4:00" },
    { value: "America/Marigot", label: "America/Marigot GMT-4:00" },
    { value: "America/Martinique", label: "America/Martinique GMT-4:00" },
    { value: "America/Matamoros", label: "America/Matamoros GMT-6:00" },
    { value: "America/Mazatlan", label: "America/Mazatlan GMT-7:00" },
    { value: "America/Mendoza", label: "America/Mendoza GMT-3:00" },
    { value: "America/Menominee", label: "America/Menominee GMT-6:00" },
    { value: "America/Merida", label: "America/Merida GMT-6:00" },
    { value: "America/Metlakatla", label: "America/Metlakatla GMT-9:00" },
    { value: "America/Mexico_City", label: "America/Mexico_City GMT-6:00" },
    { value: "America/Miquelon", label: "America/Miquelon GMT-3:00" },
    { value: "America/Moncton", label: "America/Moncton GMT-4:00" },
    { value: "America/Monterrey", label: "America/Monterrey GMT-6:00" },
    { value: "America/Montevideo", label: "America/Montevideo GMT-3:00" },
    { value: "America/Montreal", label: "America/Montreal GMT-5:00" },
    { value: "America/Montserrat", label: "America/Montserrat GMT-4:00" },
    { value: "America/Nassau", label: "America/Nassau GMT-5:00" },
    { value: "America/New_York", label: "America/New_York GMT-5:00" },
    { value: "America/Nipigon", label: "America/Nipigon GMT-5:00" },
    { value: "America/Nome", label: "America/Nome GMT-9:00" },
    { value: "America/Noronha", label: "America/Noronha GMT-2:00" },
    { value: "America/North_Dakota/Beulah", label: "America/North_Dakota/Beulah GMT-6:00" },
    { value: "merica/North_Dakota/Center", label: "merica/North_Dakota/Center GMT-6:00" },
    { value: "America/North_Dakota/New_Salem", label: "America/North_Dakota/New_Salem GMT-6:00" },
    { value: "America/Ojinaga", label: "America/Ojinaga GMT-7:00" },
    { value: "America/Panama", label: "America/Panama GMT-5:00" },
    { value: "America/Pangnirtung", label: "America/Pangnirtung GMT-7:00" },
    { value: "America/Paramaribo", label: "America/Paramaribo GMT-3:00" },
    { value: "America/Phoenix", label: "America/Phoenix GMT-7:00" },
    { value: "America/Port-au-Prince", label: "America/Port-au-Prince GMT-5:00" },
    { value: "America/Port_of_Spain", label: "America/Port_of_Spain GMT-4:00" },
    { value: "America/Porto_Acre", label: "America/Porto_Acre GMT-5:00" },
    { value: "America/Porto_Velho", label: "America/Porto_Velho GMT-4:00" },
    { value: "America/Puerto_Rico", label: "America/Puerto_Rico GMT-4:00" },
    { value: "America/Punta_Arenas", label: "America/Punta_Arenas GMT-3:00" },
    { value: "America/Rainy_River", label: "America/Rainy_River GMT-6:00" },
    { value: "America/Rankin_Inlet", label: "America/Rankin_Inlet GMT-6:00" },
    { value: "America/Recife", label: "America/Recife GMT-3:00" },
    { value: "America/Regina", label: "America/Regina GMT-6:00" },
    { value: "America/Resolute", label: "America/Resolute GMT-6:00" },
    { value: "America/Rio_Branco", label: "America/Rio_Branco GMT-5:00" },
    { value: "America/Rosario", label: "America/Rosario GMT-3:00" },
    { value: "America/Santa_Isabel", label: "America/Santa_Isabel GMT-8:00" },
    { value: "America/Santarem", label: "America/Santarem GMT-3:00" },
    { value: "America/Santiago", label: "America/Santiago GMT-4:00" },
    { value: "America/Santo_Domingo", label: "America/Santo_Domingo GMT-4:00" },
    { value: "America/Sao_Paulo", label: "America/Sao_Paulo GMT-3:00" },
    { value: "America/Scoresbysund", label: "America/Scoresbysund GMT-1:00" },
    { value: "America/Shiprock", label: "America/Shiprock GMT-7:00" },
    { value: "America/Sitka", label: "America/Sitka GMT-9:00" },
    { value: "America/St_Barthelemy", label: "America/St_Barthelemy GMT-4:00" },
    { value: "America/St_Johns", label: "America/St_Johns GMT-4:00" },
    { value: "America/St_Kitts", label: "America/St_Kitts GMT-4:00" },
    { value: "America/St_Lucia", label: "America/St_Lucia GMT-4:00" },
    { value: "America/St_Thomas", label: "America/St_Thomas GMT-4:00" },
    { value: "America/St_Vincent", label: "America/St_Vincent GMT-4:00" },
    { value: "America/Swift_Current", label: "America/Swift_Current GMT-6:00" },
    { value: "America/Tegucigalpa", label: "America/Tegucigalpa GMT-6:00" },
    { value: "America/Thule", label: "America/Thule GMT-4:00" },
    { value: "America/Thunder_Bay", label: "America/Thunder_Bay GMT-5:00" },
    { value: "America/Tijuana", label: "AAmerica/Tijuana GMT-8:00" },
    { value: "America/Toronto", label: "America/Toronto GMT-5:00" },
    { value: "America/Tortola", label: "America/Tortola GMT-4:00" },
    { value: "America/Vancouver", label: "America/Vancouver GMT-8:00" },
    { value: "America/Virgin", label: "America/Virgin GMT-4:00" },
    { value: "America/Whitehorse", label: "America/Whitehorse GMT-8:00" },
    { value: "America/Winnipeg", label: "America/Winnipeg GMT-6:00" },
    { value: "America/Yakutat", label: "America/Yakutat GMT-9:00" },
    { value: "America/Yellowknife", label: "America/Yellowknife GMT-7:00" },
    { value: "Antarctica/Casey", label: "Antarctica/Casey GMT+8:00" },
    { value: "Antarctica/Davis", label: "Antarctica/Davis GMT+7:00" },
    { value: "Antarctica/DumontDUrville", label: "Antarctica/DumontDUrville GMT+10:00" },
    { value: "Antarctica/Macquarie", label: "Antarctica/Macquarie GMT+11:00" },
    { value: "Antarctica/Mawson", label: "Antarctica/Mawson GMT+5:00" },
    { value: "Antarctica/McMurdo", label: "Antarctica/McMurdo GMT+12:00" },
    { value: "Antarctica/Palmer", label: "Antarctica/Palmer GMT-3:00" },
    { value: "Antarctica/Rothera", label: "Antarctica/Rothera GMT-3:00" },
    { value: "Antarctica/Syowa", label: "Antarctica/Syowa GMT+3:00" },
    { value: "Antarctica/Troll", label: "Antarctica/Troll GMT+0:00" },
    { value: "Antarctica/Vostok", label: "Antarctica/Vostok GMT+6:00" },
    { value: "Arctic/Longyearbyen", label: "Arctic/Longyearbyen GMT+1:00" },
    { value: "Asia/Aden", label: "Asia/Aden" },
    { value: "Asia/Almaty", label: "Asia/Almaty" },
    { value: "Asia/Amman", label: "Asia/Amman" },
    { value: "Asia/Anadyr", label: "Asia/Anadyr" },
    { value: "Asia/Aqtau", label: "Asia/Aqtau" },
    { value: "Asia/Aqtobe", label: "Asia/Aqtobe" },
    { value: "Asia/Ashgabat", label: "Asia/Ashgabat" },
    { value: "Asia/Baghdad", label: "Asia/Baghdad" },
    { value: "Asia/Bahrain", label: "Asia/Bahrain" },
    { value: "Asia/Baku", label: "Asia/Baku" },
    { value: "Asia/Bangkok", label: "Asia/Bangkok" },
    { value: "Asia/Beirut", label: "Asia/Beirut" },
    { value: "Asia/Bishkek", label: "Asia/Bishkek" },
    { value: "Asia/Brunei", label: "Asia/Brunei" },
    { value: "Asia/Chita", label: "Asia/Chita" },
    { value: "Asia/Choibalsan", label: "Asia/Choibalsan" },
    { value: "Asia/Colombo", label: "Asia/Colombo" },
    { value: "Asia/Damascus", label: "Asia/Damascus" },
    { value: "Asia/Dhaka", label: "Asia/Dhaka" },
    { value: "Asia/Dili", label: "Asia/Dili" },
    { value: "Asia/Dubai", label: "Asia/Dubai" },
    { value: "Asia/Dushanbe", label: "Asia/Dushanbe" },
    { value: "Asia/Gaza", label: "Asia/Gaza" },
    { value: "Asia/Hebron", label: "Asia/Hebron" },
    { value: "Asia/Ho_Chi_Minh", label: "Asia/Ho_Chi_Minh" },
    { value: "Asia/Hong_Kong", label: "Asia/Hong_Kong" },
    { value: "Asia/Hovd", label: "Asia/Hovd" },
    { value: "Asia/Irkutsk", label: "Asia/Irkutsk" },
    { value: "Asia/Jakarta", label: "Asia/Jakarta" },
    { value: "Asia/Jayapura", label: "Asia/Jayapura" },
    { value: "Asia/Jerusalem", label: "Asia/Jerusalem" },
    { value: "Asia/Kabul", label: "Asia/Kabul" },
    { value: "Asia/Kamchatka", label: "Asia/Kamchatka" },
    { value: "Asia/Kathmandu", label: "Asia/Kathmandu" },
    { value: "Asia/Khandyga", label: "Asia/Khandyga" },
    { value: "Asia/Kolkata", label: "Asia/Kolkata" },
    { value: "Asia/Krasnoyarsk", label: "Asia/Krasnoyarsk" },
    { value: "Asia/Kuala_Lumpur", label: "Asia/Kuala_Lumpur" },
    { value: "Asia/Kuching", label: "Asia/Kuching" },
    { value: "Asia/Kuwait", label: "Asia/Kuwait" },
    { value: "Asia/Macau", label: "Asia/Macau" },
    { value: "Asia/Magadan", label: "Asia/Magadan" },
    { value: "Asia/Makassar", label: "Asia/Makassar" },
    { value: "Asia/Manila", label: "Asia/Manila" },
    { value: "Asia/Muscat", label: "Asia/Muscat" },
    { value: "Asia/Nicosia", label: "Asia/Nicosia" },
    { value: "Asia/Novokuznetsk", label: "Asia/Novokuznetsk" },
    { value: "Asia/Novosibirsk", label: "Asia/Novosibirsk" },
    { value: "Asia/Omsk", label: "Asia/Omsk" },
    { value: "Asia/Oral", label: "Asia/Oral" },
    { value: "Asia/Phnom_Penh", label: "Asia/Phnom_Penh" },
    { value: "Asia/Pontianak", label: "Asia/Pontianak" },
    { value: "Asia/Pyongyang", label: "Asia/Pyongyang" },
    { value: "Asia/Qatar", label: "Asia/Qatar" },
    { value: "Asia/Qyzylorda", label: "Asia/Qyzylorda" },
    { value: "Asia/Rangoon", label: "Asia/Rangoon" },
    { value: "Asia/Riyadh", label: "Asia/Riyadh" },
    { value: "Asia/Sakhalin", label: "Asia/Sakhalin" },
    { value: "Asia/Samarkand", label: "Asia/Samarkand" },
    { value: "Asia/Seoul", label: "Asia/Seoul" },
    { value: "Asia/Shanghai", label: "Asia/Shanghai" },
    { value: "Asia/Singapore", label: "Asia/Singapore" },
    { value: "Asia/Srednekolymsk", label: "Asia/Srednekolymsk" },
    { value: "Asia/Taipei", label: "Asia/Taipei" },
    { value: "Asia/Tashkent", label: "Asia/Tashkent" },
    { value: "Asia/Tbilisi", label: "Asia/Tbilisi" },
    { value: "Asia/Tehran", label: "Asia/Tehran" },
    { value: "Asia/Thimphu", label: "Asia/Thimphu" },
    { value: "Asia/Tokyo", label: "Asia/Tokyo" },
    { value: "Asia/Ulaanbaatar", label: "Asia/Ulaanbaatar" },
    { value: "Asia/Urumqi", label: "Asia/Urumqi" },
    { value: "Asia/Ust-Nera", label: "Asia/Ust-Nera" },
    { value: "Asia/Vientiane", label: "Asia/Vientiane" },
    { value: "Asia/Vladivostok", label: "Asia/Vladivostok" },
    { value: "Asia/Yakutsk", label: "Asia/Yakutsk" },
    { value: "Asia/Yekaterinburg", label: "Asia/Yekaterinburg" },
    { value: "Asia/Yerevan", label: "Asia/Yerevan" },
    { value: "Atlantic/Azores", label: "Atlantic/Azores" },
    { value: "Atlantic/Bermuda", label: "Atlantic/Bermuda" },
    { value: "Atlantic/Canary", label: "Atlantic/Canary" },
    { value: "Atlantic/Cape_Verde", label: "Atlantic/Cape_Verde" },
    { value: "Atlantic/Faroe", label: "Atlantic/Faroe" },
    { value: "Atlantic/Madeira", label: "Atlantic/Madeira" },
    { value: "Atlantic/Reykjavik", label: "Atlantic/Reykjavik" },
    { value: "Atlantic/South_Georgia", label: "Atlantic/South_Georgia" },
    { value: "Atlantic/St_Helena", label: "Atlantic/St_Helena" },
    { value: "Atlantic/Stanley", label: "Atlantic/Stanley" },
    { value: "Australia/Adelaide", label: "Australia/Adelaide" },
    { value: "Australia/Brisbane", label: "Australia/Brisbane" },
    { value: "Australia/Broken_Hill", label: "Australia/Broken_Hill" },
    { value: "Australia/Currie", label: "Australia/Currie" },
    { value: "Australia/Darwin", label: "Australia/Darwin" },
    { value: "Australia/Eucla", label: "Australia/Eucla" },
    { value: "Australia/Hobart", label: "Australia/Hobart" },
    { value: "Australia/Lindeman", label: "Australia/Lindeman" },
    { value: "Australia/Lord_Howe", label: "Australia/Lord_Howe" },
    { value: "Australia/Melbourne", label: "Australia/Melbourne" },
    { value: "Australia/Perth", label: "Australia/Perth" },
    { value: "Australia/Sydney", label: "Australia/Sydney" },
    { value: "Europe/Amsterdam", label: "Europe/Amsterdam" },
    { value: "Europe/Andorra", label: "Europe/Andorra" },
    { value: "Europe/Athens", label: "Europe/Athens" },
    { value: "Europe/Belgrade", label: "Europe/Belgrade" },
    { value: "Europe/Berlin", label: "Europe/Berlin" },
    { value: "Europe/Bratislava", label: "Europe/Bratislava" },
    { value: "Europe/Brussels", label: "Europe/Brussels" },
    { value: "Europe/Bucharest", label: "Europe/Bucharest" },
    { value: "Europe/Budapest", label: "Europe/Budapest" },
    { value: "Europe/Busingen", label: "Europe/Busingen" },
    { value: "Europe/Chisinau", label: "Europe/Chisinau" },
    { value: "Europe/Copenhagen", label: "Europe/Copenhagen" },
    { value: "Europe/Dublin", label: "Europe/Dublin" },
    { value: "Europe/Gibraltar", label: "Europe/Gibraltar" },
    { value: "Europe/Guernsey", label: "Europe/Guernsey" },
    { value: "Europe/Helsinki", label: "Europe/Helsinki" },
    { value: "Europe/Isle_of_Man", label: "Europe/Isle_of_Man" },
    { value: "Europe/Istanbul", label: "Europe/Istanbul" },
    { value: "Europe/Jersey", label: "Europe/Jersey" },
    { value: "Europe/Kaliningrad", label: "Europe/Kaliningrad" },
    { value: "Europe/Kiev", label: "Europe/Kiev" },
    { value: "Europe/Lisbon", label: "Europe/Lisbon" },
    { value: "Europe/Ljubljana", label: "Europe/Ljubljana" },
    { value: "Europe/Luxembourg", label: "Europe/Luxembourg" },
    { value: "Europe/Madrid", label: "Europe/Madrid" },
    { value: "Europe/Malta", label: "Europe/Malta" },
    { value: "Europe/Mariehamn", label: "Europe/Mariehamn" },
    { value: "Europe/Minsk", label: "Europe/Minsk" },
    { value: "Europe/Monaco", label: "Europe/Monaco" },
    { value: "Europe/Moscow", label: "Europe/Moscow" },
    { value: "Europe/Oslo", label: "Europe/Oslo" },
    { value: "Europe/Paris", label: "Europe/Paris" },
    { value: "Europe/Podgorica", label: "Europe/Podgorica" },
    { value: "Europe/Prague", label: "Europe/Prague" },
    { value: "Europe/Riga", label: "Europe/Riga" },
    { value: "Europe/Rome", label: "Europe/Rome" },
    { value: "Europe/Samara", label: "Europe/Samara" },
    { value: "Europe/San_Marino", label: "Europe/San_Marino" },
    { value: "Europe/Sarajevo", label: "Europe/Sarajevo" },
    { value: "Europe/Simferopol", label: "Europe/Simferopol" },
    { value: "Europe/Skopje", label: "Europe/Skopje" },
    { value: "Europe/Sofia", label: "Europe/Sofia" },
    { value: "Europe/Stockholm", label: "Europe/Stockholm" },
    { value: "Europe/Tallinn", label: "Europe/Tallinn" },
    { value: "Europe/Tirane", label: "Europe/Tirane" },
    { value: "Europe/Uzhgorod", label: "Europe/Uzhgorod" },
    { value: "Europe/Vaduz", label: "Europe/Vaduz" },
    { value: "Europe/Vatican", label: "Europe/Vatican" },
    { value: "Europe/Vienna", label: "Europe/Vienna" },
    { value: "Europe/Vilnius", label: "Europe/Vilnius" },
    { value: "Europe/Volgograd", label: "Europe/Volgograd" },
    { value: "Europe/Warsaw", label: "Europe/Warsaw" },
    { value: "Europe/Zagreb", label: "Europe/Zagreb" },
    { value: "Europe/Zaporozhye", label: "Europe/Zaporozhye" },
    { value: "Europe/Zurich", label: "Europe/Zurich" },
    { value: "Indian/Antananarivo", label: "Indian/Antananarivo" },
    { value: "Indian/Chagos", label: "Indian/Chagos" },
    { value: "Indian/Christmas", label: "Indian/Christmas" },
    { value: "Indian/Cocos", label: "Indian/Cocos" },
    { value: "Indian/Comoro", label: "Indian/Comoro" },
    { value: "Indian/Kerguelen", label: "Indian/Kerguelen" },
    { value: "Indian/Maldives", label: "Indian/Maldives" },
    { value: "Indian/Mauritius", label: "Asia/Beirut" },
    { value: "Indian/Mayotte", label: "Indian/Mayotte" },
    { value: "Indian/Reunion", label: "Indian/Reunion" },
    { value: "Pacific/Apia", label: "Pacific/Apia" },
    { value: "Pacific/Auckland", label: "Pacific/Auckland" },
    { value: "Pacific/Bougainville", label: "Pacific/Bougainville" },
    { value: "Pacific/Chatham", label: "Pacific/Chatham" },
    { value: "Pacific/Chuuk", label: "Pacific/Chuuk" },
    { value: "Pacific/Easte", label: "Pacific/Easte" },
    { value: "Pacific/Efate", label: "Pacific/Efate" },
    { value: "Pacific/Enderbury", label: "Pacific/Enderbury" },
    { value: "Pacific/Fakaofo", label: "Pacific/Fakaofo" },
    { value: "Pacific/Fiji", label: "Pacific/Fiji" },
    { value: "Pacific/Funafuti", label: "Pacific/Funafuti" },
    { value: "Pacific/Galapagos", label: "Pacific/Galapagos" },
    { value: "Pacific/Gambier", label: "Pacific/Gambier" },
    { value: "Pacific/Guadalcanal", label: "Pacific/Guadalcanal" },
    { value: "Pacific/Guam", label: "Pacific/Guam" },
    { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
    { value: "Pacific/Johnston", label: "Pacific/Johnston" },
    { value: "Pacific/Kiritimati", label: "Pacific/Kiritimati" },
    { value: "Pacific/Kosrae", label: "Pacific/Kosrae" },
    { value: "Pacific/Kwajalein", label: "Pacific/Kwajalein" },
    { value: "Pacific/Majuro", label: "Pacific/Majuro" },
    { value: "Pacific/Marquesas", label: "Pacific/Marquesas" },
    { value: "Pacific/Midway", label: "Pacific/Midway" },
    { value: "Pacific/Nauru", label: "Pacific/Nauru" },
    { value: "Pacific/Niue", label: "Pacific/Niue" },
    { value: "Pacific/Norfolk", label: "Pacific/Norfolk" },
    { value: "Pacific/Noumea", label: "Pacific/Noumea" },
    { value: "Pacific/Pago_Pago", label: "Pacific/Pago_Pago" },
    { value: "Pacific/Palau", label: "Pacific/Palau" },
    { value: "Pacific/Pitcairnt", label: "Pacific/Pitcairn" },
    { value: "Pacific/Pohnpei", label: "Pacific/Pohnpei" },
    { value: "Pacific/Port_Moresby", label: "Pacific/Port_Moresby" },
    { value: "Pacific/Rarotonga", label: "Pacific/Rarotonga" },
    { value: "Pacific/Saipan", label: "Pacific/Saipan" },
    { value: "Pacific/Tahiti", label: "Pacific/Tahiti" },
    { value: "Pacific/Tarawa", label: "Pacific/Tarawa" },
    { value: "Pacific/Tongatapu", label: "Pacific/Tongatapu" },
    { value: "Pacific/Wake", label: "Pacific/Wake" },
    { value: "Pacific/Wallis", label: "Pacific/Wallis" },
    { value: "UTC", label: "UTC" }
    ];
    // length 142
// length 142
var languages_list = [
    {key:"Afrikaans",cat:"af"},
    {key:"Albanian - shqip",cat:"sq"},
    {key:"Amharic - አማርኛ",cat:"am"},
    {key:"Arabic - العربية",cat:"ar"},
    {key:"Aragonese - aragonés",cat:"an"},
    {key:"Armenian - հայերեն",cat:"hy"},
    {key:"Asturian - asturianu",cat:"ast"},
    {key:"Azerbaijani - azərbaycan dili",cat:"az"},
    {key:"Basque - euskara",cat:"eu"},
    {key:"Belarusian - беларуская",cat:"be"},
    {key:"Bengali - বাংলা",cat:"bn"},
    {key:"Bosnian - bosanski",cat:"bs"},
    {key:"Breton - brezhoneg",cat:"br"},
    {key:"Bulgarian - български",cat:"bg"},
    {key:"Catalan - català",cat:"ca"},
    {key:"Central Kurdish - کوردی (دەستنوسی عەرەبی)",cat:"ckb"},
    {key:"Chinese - 中文",cat:"zh"},
    {key:"Chinese (Hong Kong) - 中文（香港）",cat:"zh-HK"},
    {key:"Chinese (Simplified) - 中文（简体）",cat:"zh-CN"},
    {key:"Chinese (Traditional) - 中文（繁體）",cat:"zh-TW"},
    {key:"Corsican",cat:"co"},
    {key:"Croatian - hrvatski",cat:"hr"},
    {key:"Czech - čeština",cat:"cs"},
    {key:"Danish - dansk",cat:"da"},
    {key:"Dutch - Nederlands",cat:"nl"},
    {key:"English",cat:"en"},
    {key:"English (Australia)",cat:"en-AU"},
    {key:"English (Canada)",cat:"en-CA"},
    {key:"English (India)",cat:"en-IN"},
    {key:"English (New Zealand)",cat:"en-NZ"},
    {key:"English (South Africa)",cat:"en-ZA"},
    {key:"English (United Kingdom)",cat:"en-GB"},
    {key:"English (United States)",cat:"en-US"},
    {key:"Esperanto - esperanto",cat:"eo"},
    {key:"Estonian - eesti",cat:"et"},
    {key:"Faroese - føroyskt",cat:"fo"},
    {key:"Filipino",cat:"fil"},
    {key:"Finnish - suomi",cat:"fi"},
    {key:"French - français",cat:"fr"},
    {key:"French (Canada) - français (Canada)",cat:"fr-CA"},
    {key:"French (France) - français (France)",cat:"fr-FR"},
    {key:"French (Switzerland) - français (Suisse)",cat:"fr-CH"},
    {key:"Galician - galego",cat:"gl"},
    {key:"Georgian - ქართული",cat:"ka"},
    {key:"German - Deutsch",cat:"de"},
    {key:"German (Austria) - Deutsch (Österreich)",cat:"de-AT"},
    {key:"German (Germany) - Deutsch (Deutschland)",cat:"de-DE"},
    {key:"German (Liechtenstein) - Deutsch (Liechtenstein)",cat:"de-LI"},
    {key:"German (Switzerland) - Deutsch (Schweiz)",cat:"de-CH"},
    {key:"Greek - Ελληνικά",cat:"el"},
    {key:"Guarani",cat:"gn"},
    {key:"Gujarati - ગુજરાતી",cat:"gu"},
    {key:"Hausa",cat:"ha"},
    {key:"Hawaiian - ʻŌlelo Hawaiʻi",cat:"haw"},
    {key:"Hebrew - עברית",cat:"he"},
    {key:"Hindi - हिन्दी",cat:"hi"},
    {key:"Hungarian - magyar",cat:"hu"},
    {key:"Icelandic - íslenska",cat:"is"},
    {key:"Indonesian - Indonesia",cat:"id"},
    {key:"Interlingua",cat:"ia"},
    {key:"Irish - Gaeilge",cat:"ga"},
    {key:"Italian - italiano",cat:"it"},
    {key:"Italian (Italy) - italiano (Italia)",cat:"it-IT"},
    {key:"Italian (Switzerland) - italiano (Svizzera)",cat:"it-CH"},
    {key:"Japanese - 日本語",cat:"ja"},
    {key:"Kannada - ಕನ್ನಡ",cat:"kn"},
    {key:"Kazakh - қазақ тілі",cat:"kk"},
    {key:"Khmer - ខ្មែរ",cat:"km"},
    {key:"Korean - 한국어",cat:"ko"},
    {key:"Kurdish - Kurdî",cat:"ku"},
    {key:"Kyrgyz - кыргызча",cat:"ky"},
    {key:"Lao - ລາວ",cat:"lo"},
    {key:"Latin",cat:"la"},
    {key:"Latvian - latviešu",cat:"lv"},
    {key:"Lingala - lingála",cat:"ln"},
    {key:"Lithuanian - lietuvių",cat:"lt"},
    {key:"Macedonian - македонски",cat:"mk"},
    {key:"Malay - Bahasa Melayu",cat:"ms"},
    {key:"Malayalam - മലയാളം",cat:"ml"},
    {key:"Maltese - Malti",cat:"mt"},
    {key:"Marathi - मराठी",cat:"mr"},
    {key:"Mongolian - монгол",cat:"mn"},
    {key:"Nepali - नेपाली",cat:"ne"},
    {key:"Norwegian - norsk",cat:"no"},
    {key:"Norwegian Bokmål - norsk bokmål",cat:"nb"},
    {key:"Norwegian Nynorsk - nynorsk",cat:"nn"},
    {key:"Occitan",cat:"oc"},
    {key:"Oriya - ଓଡ଼ିଆ",cat:"or"},
    {key:"Oromo - Oromoo",cat:"om"},
    {key:"Pashto - پښتو",cat:"ps"},
    {key:"Persian - فارسی",cat:"fa"},
    {key:"Polish - polski",cat:"pl"},
    {key:"Portuguese - português",cat:"pt"},
    {key:"Portuguese (Brazil) - português (Brasil)",cat:"pt-BR"},
    {key:"Portuguese (Portugal) - português (Portugal)",cat:"pt-PT"},
    {key:"Punjabi - ਪੰਜਾਬੀ",cat:"pa"},
    {key:"Quechua",cat:"qu"},
    {key:"Romanian - română",cat:"ro"},
    {key:"Romanian (Moldova) - română (Moldova)",cat:"mo"},
    {key:"Romansh - rumantsch",cat:"rm"},
    {key:"Russian - русский",cat:"ru"},
    {key:"Scottish Gaelic",cat:"gd"},
    {key:"Serbian - српски",cat:"sr"},
    {key:"Serbo - Croatian",cat:"sh"},
    {key:"Shona - chiShona",cat:"sn"},
    {key:"Sindhi",cat:"sd"},
    {key:"Sinhala - සිංහල",cat:"si"},
    {key:"Slovak - slovenčina",cat:"sk"},
    {key:"Slovenian - slovenščina",cat:"sl"},
    {key:"Somali - Soomaali",cat:"so"},
    {key:"Southern Sotho",cat:"st"},
    {key:"Spanish - español",cat:"es"},
    {key:"Spanish (Argentina) - español (Argentina)",cat:"es-AR"},
    {key:"Spanish (Latin America) - español (Latinoamérica)",cat:"es-419"},
    {key:"Spanish (Mexico) - español (México)",cat:"es-MX"},
    {key:"Spanish (Spain) - español (España)",cat:"es-ES"},
    {key:"Spanish (United States) - español (Estados Unidos)",cat:"es-US"},
    {key:"Sundanese",cat:"su"},
    {key:"Swahili - Kiswahili",cat:"sw"},
    {key:"Swedish - svenska",cat:"sv"},
    {key:"Tajik - тоҷикӣ",cat:"tg"},
    {key:"Tamil - தமிழ்",cat:"ta"},
    {key:"Tatar",cat:"tt"},
    {key:"Telugu - తెలుగు",cat:"te"},
    {key:"Thai - ไทย",cat:"th"},
    {key:"Tigrinya - ትግርኛ",cat:"ti"},
    {key:"Tongan - lea fakatonga",cat:"to"},
    {key:"Turkish - Türkçe",cat:"tr"},
    {key:"Turkmen",cat:"tk"},
    {key:"Twi",cat:"tw"},
    {key:"Ukrainian - українська",cat:"uk"},
    {key:"Urdu - اردو",cat:"ur"},
    {key:"Uyghur",cat:"ug"},
    {key:"Uzbek - o‘zbek",cat:"uz"},
    {key:"Vietnamese - Tiếng Việt",cat:"vi"},
    {key:"Walloon - wa",cat:"wa"},
    {key:"Welsh - Cymraeg",cat:"cy"},
    {key:"Western Frisian",cat:"fy"},
    {key:"Xhosa",cat:"xh"},
    {key:"Yiddish",cat:"yi"},
    {key:"Yoruba - Èdè Yorùbá",cat:"yo"},
    {key:"Zulu - isiZulu",cat:"zu"}
];

export { flatDeep, slugify, my_timezones, languages_list, containsObject, formatPrice, getUniqueValues, getUniqueValues2 }