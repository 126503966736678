export const data = {
  title: "Become a Weight winner today ",
  steps: [
    {
      title: "Weight Loss ",
      description:
        "Balm.ai offers a personalized approach to weight management, taking into account your needs and goals, helping you reduce your level of stress and anxiety",
    },
    {
      title: "Long-Term Success",
      description:
        "We believe in sustainable weight management that promotes overall health and well-being.",
    },
    {
      title: "Positive Impact & Incentives",
      description:
        "Our approach empowers you to make lasting changes and achieve your weight goals.",
    },
  ],
};

export const data2 = {
  title: "Group Support",
  steps: [
    {
      title: "Peer Connection",
      description:
        "Connect with others on a similar journey, share your experiences, and learn from each other.",
    },
    {
      title: "Shared Motivation",
      description:
        "Find inspiration and motivation from the success stories and experiences of others within the community.",
    },
    {
      title: "Accountability Partners",
      description:
        "Connect with individuals who understand your challenges and can provide support and accountability.",
    },
  ],
};

export const data3 = {
  title: "Practical Nutrition Education",
  steps: [
    {
      title: "Personalized Nutrition Plans",
      description:
        "Work with a registered dietitian to develop a personalized nutrition plan that meets your individual needs and preferences.",
    },
    {
      title: "Healthy Eating Habits",
      description:
        "Learn to make sustainable lifestyle changes that support your long-term health and well-being.",
    },
    {
      title: "Mindful Eating",
      description:
        "Develop strategies for mindful eating to improve your relationship with food and enhance your overall health.",
    },
  ],
};

export const data4 = {
  title: "Meaningful Incentive",
  steps: [
    {
      title: "Reward System",
      description:
        "Receive rewards and recognition for reaching your weight milestones and achieving your wellness goals.",
    },
    {
      title: "Positive Feedback",
      description:
        "Receive regular positive feedback and encouragement to motivate you and keep you on track.",
    },
    {
      title: "Sense of Accomplishment",
      description:
        "Experience the satisfaction of achieving your weight goals and making positive changes in your life.",
    },
  ],
};
