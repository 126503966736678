import React from "react";

const AboutOne = (props) => {
  return (
    <div
      className={`edu-blog-widget ${
        props.style2 === "enable" ? "edu-about-widget-2" : ""
      } widget-about ${props.extraClass || ""}`}
    >
      <div className="inner">
        <h5 className="widget-title">About Me</h5>
        <div className="content">
          <div className="about-wrapper">
            <div className="thumbnail">
              <img
                src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                alt="Blog Images"
              />
            </div>
            <div className="about-content">
              <h6 className="title">Nonyelim Okolie</h6>
              <p>
                A goal-driven executive, with over eight years of extensive
                experience in management, and business leadership, executing a
                business vision that improve performance, profitability, and
                growth.
              </p>
              <ul className="social-share icon-transparent justify-content-center">
                <li>
                  <a href="https://www.linkedin.com/in/nonye/">
                    <i className="icon-linkedin"></i>
                  </a>
                </li>

                <li>
                  <a href="https://twitter.com/NonyelimEkpe">
                    <i className="icon-Twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
