/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Hero = () => {
  const scrollToAssessment = () => {
    // Find the element with the ID "assessment" and scroll to it smoothly
    const assessmentSection = document.getElementById("assessment");
    if (assessmentSection) {
      assessmentSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="slider-area banner-style-5 bg-image">
        <div className="wrapper d-flex ">
          <div className="container">
            <div className="banner-5-padding">
              <div className="row g-5 ">
                <div className="col-lg-12 order-2 order-lg-1">
                  <div className="inner pt--100 pt_md--0 pt_sm--0">
                    <div className="content text-start">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <h1 className="title">Balanced Life Assessment</h1>
                      </ScrollAnimation>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <p className="description">
                          Get a quick and honest snapshot of your life balance
                          in 5 minutes.
                        </p>
                      </ScrollAnimation>
                      <div className="read-more-btn">
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                        >
                      
                          <a
                            className="edu-btn"
                            onClick={scrollToAssessment}
                            style={{ cursor: "pointer" }}
                          >
                            Get Started{" "}
                            <i className="icon-arrow-right-line-right"></i>
                          </a>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="assessment"
        style={{ background: "#fff", paddingTop: 40, paddingBottom: 20 }}
      >
        <div className="container">
          <div>
            <div className="bottom-color">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfo6E0GJLPFcwoOYTpGvS4EJ9VRs9K2jquMbtS-u36-s_ykAA/viewform?embedded=true"
                  width="640"
                  height="4656"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
