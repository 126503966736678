import React from "react";
// import './Button.css'

function Button({ children, onClick }) {
  return (
    <div>
      <button className="edu-btn" onClick={onClick}>
        {children}
      </button>
    </div>
  );
}

export default Button;
