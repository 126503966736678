import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBRN8QmjGatLByzim4VAtKjVNfi3A8q_34",
    authDomain: "balm-ai-355108.firebaseapp.com",
    projectId: "balm-ai-355108",
    storageBucket: "balm-ai-355108.appspot.com",
    messagingSenderId: "79486496815",
    appId: "1:79486496815:web:fcd4f5d63517bf199996da"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export {auth, provider}