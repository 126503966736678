import React from "react";
// import SectionTitle from "../sectionTitle/SectionTitle";

const NewsLetterTwo = () => {
  return (
    <div
      className="eduvibe-home-three-newsletter edu-newsletter-area newsletter-style-4 edu-section-gap bg-image"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner text-center">
              <SectionTitle
                classes="text-center"
                slogan="Subscribe Newsletter"
                title="Get Every Latest News"
              />
              <div className="newsletter-form newsletter-form-style-1 mt--60 mt_md--30 mt_sm--30 position-relative">
                <div className="input-box text-start">
                  <input
                    className="large-input-box"
                    type="email"
                    placeholder="Enter your mail address"
                  />
                  <div className="mail-icon">
                    <i className="icon-mail-open-line"></i>
                  </div>
                  <button className="edu-btn">
                    Subscribe<i className="icon-arrow-right-line-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        <iframe
          title="Sign Up for Newsletter"
          src="https://thebalm.substack.com/embed"
          // width="480"
          height="320"
          style={{ border: "1px solid #eee", background: "white" }}
          // style="border:1px solid #EEE; background:white;"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
};

export default NewsLetterTwo;
