import React from "react";
import { Helmet } from "react-helmet";
// import Slider from "react-slick";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import ResiliencePost from "../../components/post/ResiliencePost";
// import PaginationOne from '../../components/pagination/PaginationOne';
import ResilienceData from "../../data/resilience/ResilienceData.json";


const ResiliencePosts = () => {
  return (
    <>
      <SEO
        title="Resilience "
        img="https://res.cloudinary.com/balmai/image/upload/v1675802285/trainingprogram_lkogsy.jpg"
        link="https://Balm.ai/resilience"
        description="Get latest news and help articles from us"
      />
      <Helmet>
        <meta property="og:title" content="Resilience" />
        <meta
          property="og:description"
          content="Get latest news and help articles from us"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675802285/trainingprogram_lkogsy.jpg"
        />
        <meta property="og:url" content="https://Balm.ai/resilience" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Resilience" />
        <meta
          name="twitter:description"
          content="Get latest news and help articles from us"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675802285/trainingprogram_lkogsy.jpg"
        />
      </Helmet>
      <Layout>
        <BreadcrumbOne
          title="Resilience "
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Resilience "
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="row g-5">
                  {ResilienceData.map((item) => (
                    <div className="col-lg-3 col-md-4 col-6" key={item.id}>
                      <ResiliencePost data={item} />
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-lg-12 mt--60">
                    {/* <PaginationOne /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                {/* <aside className="edu-Resilience-sidebar">
                  
                  <CategoryOne extraClass="mt--40" />
                  <LatestPostOne extraClass="mt--40" />
                  <AboutOne extraClass="mt--40" />
                  <TagOne extraClass="mt--40" />
                
                </aside> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Latest From News</span>
              
                </div>

                <Slider
                  className="eduvibe-post-one-carousel-wrapper mt--40 mb--50 edu-slick-button"
                  {...ResilienceCarouselParams}
                >
                  {PostData.map((item) => (
                    <div
                      className="eduvibe-post-one-carousel-item"
                      key={item.id}
                    >
                      <PostOne data={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    </>
  );
};

export default ResiliencePosts;
