import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CgProfile } from "react-icons/cg";
import { MdSchedule } from "react-icons/md";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { BiSupport } from "react-icons/bi";
import { GrResources } from "react-icons/gr";
import Layout from "../../common/Layout";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";

import Appointments from "../../components/user/Appointments";

const UserAppointments = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  // redux

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  useEffect(() => {
    if (userInfo) {
      setName(userInfo?.name);
      setImage(userInfo?.profileImg?.url);
    }
  }, [userInfo]);

  return (
    <>
      <SEO title="my dashboard" />

      <Layout>
        <BreadcrumbOne
          title="My Dashboard"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Dashboard"
        />
        <div className="expert-dashboard">
          <div>
            <aside className="expert-dashboard-nav">
              <div className="expert-dashboard-nav-top">
                <div className="expert-dashboard-img-wrap">
                  <img src={image} alt="profile" />
                </div>
                <div className="expert-dashboard-details">
                  <h6>{name}</h6>
                  <p></p>
                </div>
              </div>
              {/* <hr style={{width: "90%", height: "5px", color: 'red'}} /> */}
              <nav
                className="expert-dashboard-navigation"
                style={{ borderTop: "1px solid lightgray" }}
              >
                <ul className="expert-dashboard-nav-ul">
                  <Link
                    to="/user-dashboard/appointments"
                    className="expert-dashboard-nav-item active "
                  >
                    {" "}
                    <BsFillCalendarCheckFill /> Apointments
                  </Link>
                  <Link
                    to="/user-dashboard/profile"
                    className="/expert-dashboard-nav-item "
                  >
                    <CgProfile /> Profile Update
                  </Link>

                  <Link
                    to="/experts/market-place"
                    className="expert-dashboard-nav-item"
                  >
                    <MdSchedule /> Book a session
                  </Link>
                  <a
                    href="https://drive.google.com/drive/u/4/folders/1fq6_EGde8uGAc6CZnnHXKioA6XZOxb26"
                    target="_blank"
                    rel="noreferrer"
                    className="expert-dashboard-nav-item"
                  >
                    <GrResources /> Resources
                  </a>
                  <a
                    href="https://talk-with-ai-d29.vercel.app/chat"
                    target="_blank"
                    rel="noreferrer"
                    className="expert-dashboard-nav-item"
                  >
                    <HiOutlineChatBubbleLeftRight /> Fertility AI Chatbot
                  </a>
                  <a
                    href="mailto:hello@balm.ai"
                    target="_blank"
                    rel="noreferrer"
                    className="expert-dashboard-nav-item"
                  >
                    <BiSupport /> Contact support
                  </a>
                </ul>
              </nav>
            </aside>
          </div>
          <div className="expert-dashboard-body">
            {/* <BasicInfo/> */}
            <Appointments />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UserAppointments;
