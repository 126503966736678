import React from "react";
import "./howitworks.css";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "..//sectionTitle/SectionTitle";

const HowItWorks = ({ data }) => {
  return (
    <div className="container how-it-works-wrap">
      <SectionTitle slogan={data.title} title="" />

      <div className="how-it-works">
        {data.steps.map((item, index) => (
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
            className="how-it-works-card"
            key={index}
          >
            <h6>{item?.title}</h6>
            <p>{item?.description}</p>
          </ScrollAnimation>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
