import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="How to find your path to mental wellness"
        img="https://images.pexels.com/photos/8560020/pexels-photo-8560020.jpeg?auto=compress&cs=tinysrgb&w=400"
        description="Here are a few tips for finding your path to mental
      wellness"
        link="https://balm.ai/blog/post/How-to-find-your-path-to-mental-wellness"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="How to find your path to mental wellness"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      How to find your path to mental wellness
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.pexels.com/photos/8560020/pexels-photo-8560020.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Here are a few tips for finding your path to mental
                      wellness:
                      <ul style={{ listStyle: "number" }}>
                        <li>
                          Seek help when needed: If you are struggling with your
                          mental health, it's important to seek support from a
                          mental health professional or other trusted source.
                          Therapy can be an especially helpful resource for
                          improving mental wellness.
                        </li>
                        <li>
                          Practice self-care: Taking care of yourself is
                          essential for maintaining good mental health. This can
                          include getting enough sleep, exercising regularly,
                          eating a healthy diet, and engaging in activities that
                          bring you joy and relaxation.
                        </li>
                        <li>
                          Build a support network: Surrounding yourself with
                          supportive people can help to improve your mental
                          health and well-being. This can include friends,
                          family, and colleagues, as well as mental health
                          professionals and support groups.
                        </li>
                        <li>
                          Set goals and work towards them: Setting goals and
                          working towards them can give you a sense of purpose
                          and accomplishment, which can be helpful for improving
                          your mental wellness. Be sure to set realistic goals
                          and break them down into smaller, achievable steps.
                        </li>
                        <li>
                          Find healthy ways to cope with stress: Stress is a
                          normal part of life, but it's important to find
                          healthy ways to cope with it. This can include
                          practices like mindfulness, relaxation techniques, and
                          physical activity.
                        </li>
                        <li>
                          Seek out activities that bring you joy: Engaging in
                          activities that bring you joy and fulfillment can help
                          to improve your mental health and well-being. This can
                          include hobbies, creative pursuits, and social
                          activities.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Remember that improving mental wellness is a journey and
                      it takes time and effort. It's also important to be kind
                      to yourself and to recognize that it's okay to have ups
                      and downs. Seek support when needed and be patient with
                      yourself as you work towards better mental health.
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Expert-Tips-on-How-to-Take-Care-of-Your-Mental-Health-as-a-Founder">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                            10 Expert Tips on How to Take Care of Your Mental Health as a Founder
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Companies-With-Excellent-Mental-Health-Benefit-Programs">
                            <span>
                            10 Expert Tips on How to Take Care of Your Mental Health as a Founder
                            </span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne;
