import React from "react";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import SEO from "../../common/SEO";
import Hero from "./Hero";
import BmiCalculator from "./BmiCalculator";

const Bmi = () => {
  const ref = useRef(null);

  return (
    <>
      <SEO
        title="Body Mass Index"
        img="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        link="https://Balm.ai/business"
        description="Get a quick and honest snapshot of your life balance
                          in 5 minutes"
      />
      <Helmet>
        <meta property="og:title" content="Body Mass Index " />
        <meta
          property="og:description"
          content="Get a quick and honest snapshot of your life balance
                          in 5 minutes"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        />
        <meta property="og:url" content="https://Balm.ai/business" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Body Mass Index" />
        <meta
          name="twitter:description"
          content="Get a quick and honest snapshot of your life balance
                          in 5 minutes"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        />
      </Helmet>
      <HeaderTwo styles="header-transparent header-style-2" />
      {/* <Hero refToLastComp={ref} /> */}
      <BmiCalculator />

      <FooterOne />
    </>
  );
};

export default Bmi;
