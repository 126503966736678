import React from "react";

const ChangePassword = () => {
  return (
    <>
      <div className="expert-basic-info">
        <div className="title">
          <h6>Change Password</h6>
          <form>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Current Password</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>New Password</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Confirm Password</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </form>
        </div>
        <button
          className="rn-btn edu-btn  mb--30"
          type="submit"
          // style={{ width: "20rem" }}
        >
          Save Changes
        </button>
      </div>
    </>
  );
};

export default ChangePassword;
