import React from "react";

import { Link } from "react-router-dom";

import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne9 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO title="Encouragement for one who lost a loved one" />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Encouragement for one who lost a loved one"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      Encouragement for one who lost a loved one
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.pexels.com/photos/5064673/pexels-photo-5064673.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Losing a loved one can be a difficult and overwhelming
                      experience. It's natural to feel a range of emotions,
                      including sadness, grief, and anger. Here are a few words
                      of encouragement for someone who has lost a loved one:
                      <ul style={{ listStyle: "number" }}>
                        <li>
                          It's okay to feel your emotions: Allow yourself to
                          feel your emotions and give yourself time to heal.
                          It's important to give yourself permission to feel and
                          process your emotions, even if they are difficult.
                        </li>
                        <li>
                          Seek support: It can be helpful to seek support from
                          friends, family, and a mental health professional as
                          you navigate the grieving process. Surrounding
                          yourself with supportive people can help to provide
                          comfort and a sense of connection during this
                          difficult time.
                        </li>
                        <li>
                          Take care of yourself: It's important to prioritize
                          self-care and give yourself time to rest and recharge.
                          This can include getting enough sleep, eating a
                          healthy diet, and engaging in activities that bring
                          you joy and relaxation.
                        </li>
                        <li>
                          Remember that it's okay to have ups and downs: Grief
                          is a process and it can be normal to have ups and
                          downs. It's important to be patient with yourself and
                          recognize that it's okay to have good days and bad
                          days.
                        </li>
                        <li>
                          Find ways to honor your loved one: Find ways to honor
                          your loved one and keep their memory alive. This can
                          include sharing stories and memories with others,
                          participating in activities that were meaningful to
                          them, or creating a memorial in their honor.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Remember that everyone's grieving process is different,
                      and it's important to do what works best for you. Seek
                      support when needed and be kind to yourself as you
                      navigate this difficult time.
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/How-do-you-identify-a-person-who-is-depressed">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                              How do you identify a person who is depressed
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Encouragement-for-startup-founders">
                            <span>Encouragement for startup founders</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne9;
