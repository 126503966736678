import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne8 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO title="How do you identify a person who is depressed" />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="How do you identify a person who is depressed"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      How do you identify a person who is depressed
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.unsplash.com/photo-1520206319821-0496cfdeb31e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8ZGVwcmVzc2VkfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=1000&q=60`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Depression is a common mental health disorder that can
                      have a significant impact on an individual's well-being.
                      It is characterized by persistent feelings of sadness,
                      hopelessness, and a lack of interest in activities that
                      were previously enjoyable. Some other common signs of
                      depression may include:
                      <ul style={{ listStyle: "number" }}>
                        <li>
                          Changes in mood: A person who is depressed may
                          experience significant changes in their mood, such as
                          feeling consistently sad, irritable, or anxious.
                        </li>
                        <li>
                          Changes in sleep patterns: A person who is depressed
                          may experience changes in their sleep patterns, such
                          as difficulty falling asleep or staying asleep, or
                          sleeping too much.
                        </li>
                        <li>
                          Changes in appetite: A person who is depressed may
                          experience changes in their appetite, such as a
                          decrease in appetite or an increase in appetite.
                        </li>
                        <li>
                          Difficulty concentrating: A person who is depressed
                          may have difficulty concentrating or making decisions.
                        </li>
                        <li>
                          Loss of energy: A person who is depressed may feel
                          tired or have a lack of energy.
                        </li>
                        <li>
                          Feelings of worthlessness or guilt: A person who is
                          depressed may feel excessively guilty or worthless.
                        </li>
                        <li>
                          Thoughts of death or suicide: A person who is
                          depressed may have thoughts of death or suicide.
                        </li>
                      </ul>
                    </p>
                    <p>
                      If you are concerned that someone you know may be
                      depressed, it is important to encourage them to seek
                      support from a mental health professional. If you or
                      someone you know is experiencing thoughts of suicide, it
                      is important to seek help immediately by contacting a
                      suicide prevention hotline or seeking emergency medical
                      attention.
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/How-to-get-married-to-the-right-person">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>How to make a good marital decision</span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Encouragement-for-one-who-lost-a-loved-one">
                            <span>
                              Encouragement for one who lost a loved one
                            </span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne8;
