import React, { useState, useEffect } from "react";
import { signInWithPopup } from "firebase/auth";
import axios from "axios";
import { toast } from "react-toastify";
import SEO from "../../common/SEO";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { signin } from "../../actions/userActions";
import "./SigninEmail.css";
import { auth, provider } from "../../firebase";

function UserSigninEmail({ location }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(false);
  const [redirect, setRedirect] = useState("/user-dashboard/profile");
  const [termsAccepted, setTermsAccepted] = useState(false); // New state variable

  // redux
  const history = useHistory();
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;

  const handleGoogleSignIn = (e) => {
    e.preventDefault();
    const checkExpert = { isExpert: false };
    signInWithPopup(auth, provider)
      .then((result) => {
        const userObject = result.user;
        if (userObject) {
          dispatch(
            signin(
              userObject.email,
              userObject.displayName,
              { public_id: userObject.uid, url: userObject.photoURL },
              checkExpert,
              userObject.uid
            )
          );
        } else {
          toast.error("❌ Sign-in failed. Try again later.");
          history.push("/user/signin");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    if (location && location.state && location.state.from) {
      const from = location.state.from;
      setRedirect(from);
    }
  }, [location]);

  useEffect(() => {
    if (userInfo && !userInfo.isExpert && userInfo.message !== "ok") {
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  // email sign-in
  const submitHandler = async (e) => {
    e.preventDefault();
    if (email.split("@")[1] !== "gmail.com") {
      toast.error("❌ Email must be a Gmail address.");
    } else if (password.length < 8) {
      toast.error("❌ Password must be at least 8 characters.");
    } else {
      setLoad(true);
      const name = email.split("@")[0];
      const checkExpert = { isExpert: false };
      const profileImg = {
        public_id: "12455853754",
        url: "https://res.cloudinary.com/balmai/image/upload/v1668871675/profil_ulvgaq.png",
      };
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_KEY}users/signinemail`,
          { email, name, password, profileImg, checkExpert }
        );
        if (data._id) {
          dispatch({ type: "USER_SIGNIN_SUCCESS", payload: data });
        }
        if (data.message === "ok") {
          setLoad(false);
          setAlert(true);
        }
      } catch (error) {
        setLoad(false);
        toast.error("❌ Invalid email or password.");
      }
    }
  };

  const buttonStyle = {
    width: "90%",
    backgroundColor: "white",
    color: "black",
    padding: "10px 20px",
    borderRadius: "4px",
    border: "0.5px solid #ccc",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyle = {
    marginRight: "10px",
  };

  return (
    <div id="main-div">
      <SEO title="Login" />

      <div
        className="login-register-page-wrapper2 bg-color-white"
        style={{ paddingTop: "20px" }}
      >
        <div className="container checkout-page-style">
          <div className="conatainer">
            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
              <div className="logo" style={{ width: "100" }}>
                <Link to={"/"} style={{ width: "70%" }}>
                  <img
                    style={{ width: "40%", height: "auto" }}
                    className="logo-light"
                    src="https://res.cloudinary.com/balmai/image/upload/v1658584166/balm-web/My_project_4_1_1_qsw5mj.png"
                    alt="Main Logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="row g-5"
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: "40px",
              marginBottom: "80px",
            }}
          >
            <div className="col-lg-6">
              <div className="signup">
                <h4 style={{ color: "white" }}>Welcome to Balm.ai</h4>

                <form className="inner-signup">
                  <div
                    id="signin-Google-Button"
                    style={{ textAlign: "center" }}
                  >
                    Sign in to begin your health transformation journey
                  </div>

                  <div
                    id="signin-Google-Button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {alert && (
                      <div className="success-bar">
                        A verification mail has been sent to your email
                      </div>
                    )}
                    {(loading || load) && !alert && (
                      <div className="loading-bar">
                        <i
                          className={"fa fa-spinner fa-spin"}
                          style={{ margin: "1rem" }}
                        ></i>
                        Signing In...
                      </div>
                    )}
                    {!loading && !load && !alert && (
                      <button
                        style={buttonStyle}
                        onClick={(e) => handleGoogleSignIn(e)}
                      >
                        <span style={iconStyle}>
                          <img src="/google.svg" alt="google" width="25px" />
                        </span>
                        Sign in with Google
                      </button>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3 id="h3">Enter your email to continue.</h3>
                    <input
                      type="email"
                      value={email}
                      required
                      className="input-signin"
                      placeholder="Email address"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    <input
                      required
                      type="password"
                      value={password}
                      className="input-signin"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                    <div
                      style={{
                        width: "89%",
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        marginTop: 10,
                        marginBottom: 5,
                      }}
                    >
                      <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        checked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                      />
                      <label htmlFor="terms" className="existing-account">
                        By proceeding, you consent to our <Link to='/terms-of-service/balm'>Terms of Service</Link>  and <Link to='/privacy-policy/balm' >
                        Privacy Policy</Link>
                      </label>
                    </div>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <button
                        className="edu-btn btn-medium left-icon header-button mb--10"
                        type="submit"
                        value="Submit"
                        disabled={alert || load || !termsAccepted}
                        onClick={(e) => submitHandler(e)}
                      >
                        Continue
                      </button>
                      <p className="existing-account" disabled={true}>
                        Want to log in as an expert instead?
                        <Link to="/expert/signin" id="a-signup">
                          {" "}
                          Yes
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSigninEmail;
