import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Nav = () => {
  const [name, setName] = useState();

  // const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  useEffect(() => {
    if (userInfo) {
      setName(userInfo);
    }
  }, [userInfo]);
  return (
    <ul className="mainmenu">
      {/* <li className="">
        <Link to="/">Home</Link>
      </li> */}

      <li className="">
        <Link to="/experts/market-place">Find Experts</Link>
      </li>
      {/* {name ? (
        ""
      ) : (
        <li className="">
          <Link to="/expert/signin">Become an Expert</Link>
        </li>
      )} */}
      <li className="">
        <Link to="/expert/signin">For Providers</Link>
      </li>
      <li className="">
        <Link to="/bmi">BMI</Link>
      </li>
      <li className="">
        <Link to="/business">For Business</Link>
      </li>

      <li className="">
        <Link to="/blog/posts">Blog</Link>
      </li>
    </ul>
  );
};
export default Nav;
