/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FcGlobe, FcGraduationCap, FcHeadset } from "react-icons/fc";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SEO from "../../../common/SEO";
import Layout from "../../../common/Layout";
import "./expertpage.css";
import Axios from "axios";
import Select from "react-select";
import profile from "../../../data/profil.png";
import Calendar from "react-calendar";

import CourseData from "../../../data/course/CourseData.json";
import { createReview } from "../../../actions/expertActions";

const durationOptions = [
  { value: "40", label: "40 minutes" },
  { value: "80", label: "80 minutes" },
];

function ExpertPage(props) {
  const [clicked, setClicked] = useState(1);
  const [active, setActive] = useState(false);
  const [duration, setDuration] = useState({});
  const [date, setDate] = useState();
  const [reviews, setReviews] = useState([]);

  const [expert, setExpert] = useState({});
  const dispatch = useDispatch();

  // final states
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [expertIdvalue, setExpertIdvalue] = useState({});
  const [expertTimezone, setExpertTimezone] = useState({});
  const [userIdvalue, setUserIdvalue] = useState("");
  const [userTimezone, setUserTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [expertEmail, setExpertEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [expertName, setExpertName] = useState("");
  const [expertLanguage, setExpertLanguage] = useState([]);
  const [userName, setUserName] = useState("");
  const [finalPrice, setFinalPrice] = useState();
  const [timeDuration, setTimeDuration] = useState({});

  // prams
  const history = useHistory();
  const params = useParams();
  const { id: expertId } = params;
  // tomorrws date
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() - 1);

  // redux
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const expertReviewCreate = useSelector((state) => state.expertReviewCreate);
  const {
    loading: loadingReviewCreate,
    error: errorReviewCreate,
    success: successReviewCreate,
  } = expertReviewCreate;
  useEffect(() => {
    if (errorReviewCreate && !loadingReviewCreate) {
      toast.error(errorReviewCreate);
      setRating("");
      setComment("");
    }
  }, [errorReviewCreate]);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    if (userInfo && expertId) {
      setUserName(userInfo.name);
      setUserEmail(userInfo.email);
      setUserTimezone(timezone);
      setExpertIdvalue(expertId);
      setUserIdvalue(userInfo._id);
    }
  }, [userInfo, expertId, timezone]);

  useEffect(() => {
    if (duration) {
      setTimeDuration(duration);
    }
    if (expert && duration.value === "40") {
      setFinalPrice(expert.fortyMin);
    }
    if (expert && duration.value === "60") {
      setFinalPrice(Number(expert.sixtyMin));
    }
    if (expert && duration.value === "80") {
      setFinalPrice(Number(expert.eigtyMin));
    }
  }, [duration, expert]);

  // function changeTimeZone(date, timeZone) {
  //   if (typeof date === "string") {
  //     return new Date(
  //       new Date(date).toLocaleString("en-US", {
  //         timeZone,
  //       })
  //     );
  //   }

  //   return new Date(
  //     date.toLocaleString("en-US", {
  //       timeZone,
  //     })
  //   );
  // }

  // expert
  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const { data } = await Axios.get(
        ` ${process.env.REACT_APP_API_KEY}expert/${expertId}`
      );
      dispatch({ type: "FETCH_SUCCESS" });
      setExpert(data);
      if (!data.isExpert) {
        history.push("/");
      }
    } catch (error) {
      dispatch({
        type: "FETCH_FAIL",
      });
      toast.error("❌ something went wrong", error);
      history.push("/");
    }
  };
  useEffect(() => {
    if (expertId) {
      fetchData();
    }
  }, [expertId]);

  // submithandlere

  const meetingScheduleHandler = async (e) => {
    e.preventDefault();
    let data = {
      expertIdvalue,
      userIdvalue,
      startTime,
      endTime,
      expertTimezone,
      userTimezone,
      expertEmail,
      userEmail,
      expertName,
      userName,
      finalPrice,
      timeDuration,
    };
    if (!date) {
      toast.warn("❌ Please Select Date 😔");
    } else if (!duration) {
      toast.warn("❌ Please Select Time Duration 😔");
    } else if (!clicked) {
      toast.warn("❌ Please Select Time 😔");
    } else if (date.getTime() < new Date().getTime()) {
      toast.warn("❌ Please Select another Date 😔");
    } else if (!userInfo) {
      toast.info("📍 Please Sign in to Schedule 😔");
      history.push("/user/signin", { from: `/expert/${expertId}` });
    } else if (userInfo.isExpert) {
      toast.info("📍 Please Sign up with user account to Schedule 😔");
      history.push("/user/signin", { from: `/expert/${expertId}` });
    } else if (!expertIdvalue) {
      toast.warn("❌ error! please check your network  and try again.😔");
    } else if (!expert) {
      toast.warn("❌ Network error!, check your network and try again 😔");
    } else if (finalPrice < 0) {
      toast.warn("❌ Please select another duration 😔");
    } else if (
      !startTime ||
      !endTime ||
      !userIdvalue ||
      !expertTimezone ||
      !userTimezone ||
      !expertEmail ||
      !userEmail ||
      !expertName ||
      !userName ||
      // !finalPrice ||
      !timeDuration
    ) {
      toast.error(
        "❌ error! please check your options/network  and try again.😔"
      );
    } else {
      await dispatch({ type: "MEETING_DETAILS_SUCCESS", payload: data });
      localStorage.setItem("meetingInfo", JSON.stringify(data));
      let path = `/meeting/expert=${expertId}/event=62b8470d93899262cb83dd55`;
      history.push(path);
    }
  };

  // handle click
  const handleClick = (e) => {
    e.preventDefault();
    setClicked(e.target.value);
    setActive(true);

    if (date && clicked) {
      var numberOfMlSeconds = date.getTime();
      var addMlSeconds = 1000 * 60 * parseInt(e.target.value);
      var endtime = parseInt(e.target.value) + parseInt(duration.value);
      var addEndMlSeconds = 1000 * 60 * endtime;
      var startDateObj = new Date(numberOfMlSeconds + addMlSeconds);
      var endDateObj = new Date(numberOfMlSeconds + addEndMlSeconds);
      setStartTime(startDateObj.toISOString());
      setEndTime(endDateObj.toISOString());
    }
  };

  useEffect(() => {
    if (expert) {
      setExpertTimezone(expert.timezone);
      setExpertEmail(expert.email);
      setExpertName(expert.name);
      setExpertLanguage(expert.language);
      setReviews(expert.reviews);
    }
  }, [expert]);

  const courses = CourseData.map((course) => {
    return {
      ...course,
    };
  }).filter((course) => course.instructor.length > 0);

  const [contentTab, setContentTab] = useState(false);

  const handleTab = () => {
    setContentTab(!contentTab);
  };

  // REVIEWS
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (successReviewCreate) {
      toast.success("✅ review successfully created😊");
      setRating("");
      setComment("");
      dispatch({ type: "PRODUCT_REVIEW_CREATE_RESET" });
      fetchData();
    }
  }, [dispatch, expertId, successReviewCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (comment && rating) {
      dispatch(
        createReview(expertId, {
          rating,
          comment,
          name: userInfo?.name,
          img: userInfo?.profileImg?.url,
        })
      );
    } else {
      toast.error("Please enter comment and rating");
    }
  };

  return (
    <>
      <SEO
        title={expert.name}
        img={expert.profileImg.url}
        link={`https://app.balm.ai/${expert.slug}`}
      />
      <Layout>
        <div
          className="edu-instructor-profile-area edu-section-gap bg-color-white"
          style={{ marginTop: "70px" }}
        >
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-4 ">
                {/* loading */}
                {expert && expert.isExpert && (
                  <div className="instructor-profile-left">
                    <div className="inner">
                      <div className="thumbnail">
                        {expert?.profileImg ? (
                          <img
                            src={expert?.profileImg?.url}
                            alt="Team Member"
                          />
                        ) : (
                          <img src={profile} alt="Team Member" />
                        )}
                      </div>
                      <div className="content" style={{ width: "100%" }}>
                        <h5 className="title">{expert.name}</h5>
                        <hr style={{ border: "0.5px solid gray" }} />
                        <span className="subtitle">
                          <FcGraduationCap
                            style={{ margin: "8px", fontSize: "2.5rem" }}
                          />
                          : {expert.specialization}
                        </span>
                        <hr style={{ border: "0.5px solid gray" }} />
                        {expertTimezone && (
                          <span className="subtitle">
                            <FcGlobe
                              style={{ margin: "8px", fontSize: "2.5rem" }}
                            />
                            : {expertTimezone.label}
                          </span>
                        )}
                        <hr style={{ border: "0.5px solid gray" }} />
                        <span className="subtitle">
                          <FcHeadset
                            style={{ margin: "8px", fontSize: "2.5rem" }}
                          />
                          :{" "}
                          <div style={{ display: "flex" }}>
                            {expertLanguage &&
                              expertLanguage.map((s) => (
                                <span
                                  key={s.cat}
                                  style={{
                                    padding: "2px",
                                    backgroundColor: "#99FFFF",
                                    margin: "2px",
                                    borderRadius: "3px",
                                  }}
                                >
                                  {s.key}
                                </span>
                              ))}
                          </div>
                        </span>
                        <hr style={{ border: "0.5px solid gray" }} />
                        <div className="contact-with-info"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-8">
                <div className="instructor-profile-right">
                  <div className="inner">
                    {/* loading */}
                    {expert && expert.isExpert ? (
                      <div className="section-title text-start">
                        <span className="pre-title">About Me</span>
                        <h3 className="title">Hello, I’m {expert.name}</h3>
                        <p className="description mt--40">{expert.about}</p>
                      </div>
                    ) : (
                      <div style={{ padding: "3rem" }}>
                        <div className="loading-bar">
                          <i
                            className={"fa fa-spinner fa-spin"}
                            style={{ margin: "1rem" }}
                          ></i>
                          loading
                        </div>
                      </div>
                    )}

                    <div className="course-statistic-wrapper bg-color-primary ptb--20 mt--65 radius-small">
                      <h6 className="text-center color-white">
                        Book a Session Now
                      </h6>
                      <hr></hr>
                      <div className="meeting-select-wrapper">
                        <div className="meeting-select-wrap-top">
                          <div style={{ width: "100%" }}>
                            <Select
                              placeholder="select duration"
                              // defaultValue={duration}
                              onChange={setDuration}
                              options={durationOptions}
                            />
                          </div>
                          <div>
                            {finalPrice > 0 && finalPrice !== 0 && (
                              <div
                                className="meeting-select-wrap-top-info"
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Price:&nbsp;${finalPrice.toString()}
                              </div>
                            )}
                            {finalPrice < 1 && finalPrice === 0 && (
                              <div
                                className="meeting-select-wrap-top-info"
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Price:&nbsp;Free
                              </div>
                            )}
                          </div>
                          <div className="selected-date">
                            {userTimezone && (
                              <div
                                className="meeting-select-wrap-top-info"
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                {userTimezone}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="meeting-select-wrap-bottom">
                          <div className="meeting-select-wrap">
                            <div className="meeting-select-wrap-calender">
                              <div>
                                <Calendar
                                  onChange={setDate}
                                  value={date}
                                  minDate={tomorrow}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="rightmost-popup-selectedDate">
                            <div>
                              {duration.value === "40" && (
                                <div
                                  className="button-container"
                                  onClick={handleClick}
                                >
                                  <div>
                                    <button
                                      className={
                                        clicked === "00"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="00"
                                    >
                                      00:00
                                    </button>
                                  </div>

                                  <div>
                                    <button
                                      className={
                                        clicked === "40"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="40"
                                    >
                                      00:40
                                    </button>
                                  </div>

                                  <div>
                                    <button
                                      className={
                                        clicked === "80"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="80"
                                    >
                                      01:20
                                    </button>
                                  </div>

                                  <div>
                                    <button
                                      className={
                                        clicked === "120"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="120"
                                    >
                                      02:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "160"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="160"
                                    >
                                      02:40
                                    </button>
                                  </div>

                                  <div>
                                    <button
                                      className={
                                        clicked === "200"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="200"
                                    >
                                      03:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "240"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="240"
                                    >
                                      04:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "280"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="280"
                                    >
                                      04:40
                                    </button>
                                  </div>

                                  <div>
                                    <button
                                      className={
                                        clicked === "320"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="320"
                                    >
                                      05:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "360"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="360"
                                    >
                                      06:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "400"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="400"
                                    >
                                      06:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "440"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="440"
                                    >
                                      07:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "480"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="480"
                                    >
                                      08:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "520"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="520"
                                    >
                                      08:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "560"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="560"
                                    >
                                      09:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "600"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="600"
                                    >
                                      10:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "640"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="640"
                                    >
                                      10:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "680"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="680"
                                    >
                                      11:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "720"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="720"
                                    >
                                      12:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "760"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="760"
                                    >
                                      12:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "800"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="800"
                                    >
                                      13:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "840"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="840"
                                    >
                                      14:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "880"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="880"
                                    >
                                      14:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "920"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="920"
                                    >
                                      15:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "960"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="960"
                                    >
                                      16:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1000"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1000"
                                    >
                                      16:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1040"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1040"
                                    >
                                      17:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1080"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1080"
                                    >
                                      18:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1120"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1120"
                                    >
                                      18:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1160"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1160"
                                    >
                                      19:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1200"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1200"
                                    >
                                      20:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1240"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1240"
                                    >
                                      20:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1280"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1280"
                                    >
                                      21:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1320"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1320"
                                    >
                                      22:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1360"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1360"
                                    >
                                      22:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1440"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1440"
                                    >
                                      23:20
                                    </button>
                                  </div>
                                </div>
                              )}
                              {/* {duration.value === "60" && (
                                <div
                                  className="button-container"
                                  onClick={handleClick}
                                >
                                  <div>
                                    <button
                                      className={
                                        clicked === "00"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="00"
                                    >
                                      00:00
                                    </button>
                                  </div>

                                  <div>
                                    <button
                                      className={
                                        clicked === "60"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="60"
                                    >
                                      1:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "120"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="120"
                                    >
                                      2:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "180"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="180"
                                    >
                                      3:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "240"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="240"
                                    >
                                      4:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "300"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="300"
                                    >
                                      5:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "360"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="360"
                                    >
                                      6:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "420"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="420"
                                    >
                                      7:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "480"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="480"
                                    >
                                      8:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "540"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="540"
                                    >
                                      9:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "600"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="600"
                                    >
                                      10:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "660"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="660"
                                    >
                                      11:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "720"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="720"
                                    >
                                      12:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "780"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="780"
                                    >
                                      13:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "840"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="840"
                                    >
                                      14:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "900"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="900"
                                    >
                                      15:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "960"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="960"
                                    >
                                      16:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1020"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1020"
                                    >
                                      17:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1080"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1080"
                                    >
                                      18:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1140"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1140"
                                    >
                                      19:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1200"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1200"
                                    >
                                      20:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1260"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1260"
                                    >
                                      21:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1320"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1320"
                                    >
                                      22:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1380"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1380"
                                    >
                                      23:00
                                    </button>
                                  </div>
                                </div>
                              )} */}
                              {duration.value === "80" && (
                                <div
                                  className="button-container"
                                  onClick={handleClick}
                                >
                                  <div>
                                    <button
                                      className={
                                        clicked === "00"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="00"
                                    >
                                      00:00
                                    </button>
                                  </div>

                                  <div>
                                    <button
                                      className={
                                        clicked === "80"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="80"
                                    >
                                      1:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "160"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="160"
                                    >
                                      2:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "240"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="240"
                                    >
                                      4:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "320"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="320"
                                    >
                                      5:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "400"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="400"
                                    >
                                      6:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "480"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="480"
                                    >
                                      8:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "560"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="560"
                                    >
                                      9:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "640"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="640"
                                    >
                                      10:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "720"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="720"
                                    >
                                      12:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "800"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="800"
                                    >
                                      13:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "880"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="880"
                                    >
                                      14:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "960"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="960"
                                    >
                                      16:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1040"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1040"
                                    >
                                      17:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1120"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1120"
                                    >
                                      18:40
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1200"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1200"
                                    >
                                      20:00
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1280"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1280"
                                    >
                                      21:20
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className={
                                        clicked === "1360"
                                          ? "newSetTime"
                                          : "setTime"
                                      }
                                      value="1360"
                                    >
                                      22:40
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="meeting-schudule-submit">
                        {date &&
                          duration &&
                          clicked &&
                          startTime &&
                          userInfo && (
                            <div
                              style={{
                                width: "90%",
                                textAlign: "center",
                                color: "white",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Hi {userInfo.name} You are about to schedule a{" "}
                              {duration.label} balm meeting with {expert.name}{" "}
                              on {startTime.substr(0, 10)}
                              {/* <Moment parse="YYYY-MM-DD HH:mm" date={startTime} /> */}
                            </div>
                          )}

                        <button
                          disabled={!active}
                          // style={{ marginTop: "2rem" }}
                          className={
                            active ? "available-onClick" : "unavailable-onClick"
                          }
                          onClick={meetingScheduleHandler}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>

                    {courses && courses.length > 0 && (
                      <div className="edu-course-wrapper pt--65">
                        <ul
                          className="edu-course-tab nav nav-tabs"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <button
                              className=" edu-btn"
                              type="button"
                              // disabled
                              aria-label="Reviews"
                              onClick={() => handleTab()}
                            >
                              Reviews
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content">
                          {contentTab && (
                            <div className={`tab-pane fade show active  `}>
                              <div className="course-tab-content">
                                {reviews && reviews.length > 0 ? (
                                  <div>
                                    {reviews?.map((data, index) => (
                                      <div
                                        className="comment-wrapper pt--40"
                                        key={index}
                                      >
                                        <div className="edu-comment">
                                          <div className="thumbnail">
                                            <img
                                              src={data?.img}
                                              alt="customer Thumb"
                                            />
                                          </div>
                                          <div className="comment-content">
                                            <div className="comment-top">
                                              <span
                                                style={{}}
                                                className="pre-title"
                                              >
                                                {data?.name}
                                              </span>
                                              <div className="rating eduvibe-course-rating-stars">
                                                <i className="icon-Star"></i>
                                                <i className="icon-Star"></i>
                                                <i className="icon-Star"></i>
                                                <i className="icon-Star"></i>
                                                <i className="icon-Star"></i>
                                              </div>
                                            </div>
                                            <p>{data?.comment}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div style={{ padding: "3rem" }}>
                                    <div className="error-bar">
                                      No Reviews Yet
                                    </div>
                                  </div>
                                )}

                                <hr />
                                <div className="section-title">
                                  {/* {userInfo ? ( */}
                                  <form
                                    className="form"
                                    onSubmit={submitHandler}
                                  >
                                    <div>
                                      <span className="pre-title">
                                        Write a review
                                      </span>
                                    </div>
                                    <div>
                                      <label htmlFor="rating">Rating</label>
                                      <select
                                        id="rating"
                                        value={rating}
                                        onChange={(e) =>
                                          setRating(e.target.value)
                                        }
                                      >
                                        <option value="">Select...</option>
                                        <option value="1">1- Poor</option>
                                        <option value="2">2- Fair</option>
                                        <option value="3">3- Good</option>
                                        <option value="4">4- Very good</option>
                                        <option value="5">5- Excelent</option>
                                      </select>
                                    </div>
                                    <div>
                                      <label htmlFor="comment">Comment</label>
                                      <textarea
                                        id="comment"
                                        value={comment}
                                        onChange={(e) =>
                                          setComment(e.target.value)
                                        }
                                      ></textarea>
                                    </div>
                                    <div>
                                      <label />
                                      <button
                                        className="edu-btn btn-medium mt--10"
                                        type="submit"
                                      >
                                        {loadingReviewCreate
                                          ? "Loading"
                                          : "Submit"}
                                      </button>
                                    </div>
                                    <div></div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ExpertPage;
