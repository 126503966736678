/* eslint-disable react-hooks/exhaustive-deps */
import "./earning.css";
import axios from "axios";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

export default function Earning() {
 
  const [loading, setLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});

  // redux
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const { _id } = userInfo;

  const fetchAccount = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_KEY}expert/accountdetail`,
        { _id },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      await dispatch({ type: "EARNING_DETAILS_SUCCESS", payload: data });
      setAccountDetails(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && _id) {
      fetchAccount();
    }
  }, [userInfo, _id]);

  return (
    <>
      {loading ? (
        <div style={{ padding: "3rem"}}>
          <div className="loading-bar">
            <i
              className={"fa fa-spinner fa-spin"}
              style={{ margin: "1rem" }}
            ></i>
            Loading...
          </div>
        </div>
      ) : (
        <div className="featured">
          <div className="featuredItem">
            <span className="featuredTitle">Total Earnings</span>
            <div className="featuredMoneyContainer">
              {userInfo && accountDetails ? (
                <span className="featuredMoney">${accountDetails.total}</span>
              ) : (
                <span className="featuredMoney">$0</span>
              )}
            </div>
          </div>
          <div className="featuredItem">
            <span className="featuredTitle">Pending Earnings</span>
            <div className="featuredMoneyContainer">
              {userInfo && accountDetails ? (
                <span className="featuredMoney">${accountDetails.pending}</span>
              ) : (
                <span className="featuredMoney">$0</span>
              )}
            </div>
            {/* <span className="featuredSub">will be credited when pending sessions are completed</span> */}
          </div>
          <div className="featuredItem">
            <span className="featuredTitle">WithDrawn</span>
            <div className="featuredMoneyContainer">
              {userInfo && accountDetails ? (
                <span className="featuredMoney">
                  ${accountDetails.withdrawn}
                </span>
              ) : (
                <span className="featuredMoney">$0</span>
              )}
            </div>
            {/* <span className="featuredSub">Compared to last month</span> */}
          </div>
          <div className="featuredItem">
            <span className="featuredTitle">Balance</span>
            <div className="featuredMoneyContainer">
              {userInfo && accountDetails ? (
                <span className="featuredMoney">${accountDetails.balance}</span>
              ) : (
                <span className="featuredMoney">$0</span>
              )}
            </div>
            {/* <span className="featuredSub">Compared to last month</span> */}
          </div>
        </div>
      )}
    </>
  );
}
