import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

const CourseTypeThree = ({ data, classes, bgWhite }) => {
  return (
    <div
      style={{ margin: "10px" }}
      className={`edu-card card-type-1 radius-small ${classes ? classes : ""} ${
        bgWhite === "enable" ? "bg-white" : ""
      }`}
    >
      <div className="inner">
        <div className="thumbnail">
          <Link to={`/expert/${data._id}`}>
            {data?.profileImg?.url ? (
              <img
                src={data?.profileImg?.url}
                alt="expert "
                className="w-100"
              />
            ) : (
              <img
                src={
                  "https://res.cloudinary.com/dxjprordi/image/upload/v1663745238/profil_iflv6m.png"
                }
                alt="expert Member"
                className="w-100"
              />
            )}
          </Link>
          <div className="top-position status-group left-top"></div>
          <div className="wishlist-top-right">
            <BsPatchCheckFill style={{ color: "#0839ea", fontSize: "19px" }} />
          </div>
        </div>
        <div className="content">
          <p className="title">
            <Link to="/expert/62b83db993899262cb83dd3b">
              {/* <i className="icon-file-list-4-line"></i> */}

              {data.name}
            </Link>
          </p>
          <ul className="edu-meta">
            <li>
              <p style={{ margin: "0 0 0 0", fontSize: "1.4rem" }}>
                {data.about.substring(0, 100)}...
              </p>
            </li>
          </ul>

          <div className="edu-rating rating-default">
            {/* <div className="rating eduvibe-course-rating-stars"> */}
              <ReactStars
                count={5}
                value={data?.rating}
                size={20}
                activeColor="#f4c150"
              />
              ,
              {/* <i className="icon-Star"></i>
              <i className="icon-Star"></i>
              <i className="icon-Star"></i>
              <i className="icon-Star"></i>
              <i className="icon-Star"></i> */}
            {/* </div> */}
            <span className="rating-count">{data?.rating}</span>
          </div>
          <div className="card-bottom">
            <div className="price-list price-style-03">
              {data?.fortyMin === 0 ? (
                <div className="price current-price">Free</div>
              ) : (
              <div className="price current-price">${data?.fortyMin}</div>
              )}
              {/* {data.oldPrice && (
                <div className="price old-price">${data.oldPrice}</div>
              )} */}
            </div>
            <ul className="edu-meta meta-01">
              <li>
                {/* <i className="icon-account-circle-line"></i> */}
                per session
                {/* {data.student} Students */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTypeThree;
