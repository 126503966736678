/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const WithdrawTable = () => {

  const [loading, setLoading] = useState(false);
  const [withdraw, setWithdraw] = useState([]);
  // redux
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const { _id } = userInfo;

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}withdraw?expert=${_id}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      //   await dispatch({ type: "EARNING_DETAILS_SUCCESS", payload: data });
      setWithdraw(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && _id) {
      fetchData();
    }
  }, [userInfo, _id]);

  return (
    <>
      <h6 style={{ marginTop: "4rem" }}>Withdrawal Requests</h6>
      <div className="expert-schedule">
        <div>
          <Table className="responsive-table">
            <Thead>
              <Tr>
                <Th style={{ textAlign: "center" }}>Amount</Th>
                <Th style={{ textAlign: "center" }}>Bank</Th>
                <Th style={{ textAlign: "center" }}>status</Th>
                <Th style={{ textAlign: "center" }}>date</Th>
              </Tr>
            </Thead>
            {withdraw && withdraw.length > 0 && (
              <Tbody className="ttbody">
                {withdraw.map((data, index) => (
                  <Tr key={index}>
                    <Td>
                      <div
                        className="row-table"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>${data.amount}</p>
                      </div>
                    </Td>
                    <Td>
                      <div
                        className="row-table"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{data.bankName}</p>
                      </div>
                    </Td>

                    <Td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{data.isPaid ? "Paid" : "Pending"}</p>
                      </div>
                    </Td>
                    <Td>
                      <div
                        className="column-table"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ margin: "1px", padding: "1px" }}>
                          {new Date(data.createdAt).toDateString()}
                        </p>

                        <p
                          style={{
                            color: "#20c997",
                            margin: "1px",
                            padding: "1px",
                          }}
                        >
                          {new Date(data.createdAt).toLocaleTimeString()}
                        </p>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
          <div className="">
            {withdraw.length < 1 && !loading && (
              <div style={{ padding: "3rem"}}>
                <div className="error-bar">No Withdrawal Request Yet</div>
              </div>
            )}
            {loading && (
              <div style={{ padding: "3rem"}}>
                <div className="loading-bar">
                  <i
                    className={"fa fa-spinner fa-spin"}
                    style={{ margin: "1rem" }}
                  ></i>
                  Loading...
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawTable;
