import React from "react";
import { Link } from "react-router-dom";

const TagOne = (props) => {
  return (
    <div
      className={`edu-blog-widget${
        props.style2 === "enable" ? "-2" : ""
      } widget-tags ${props.extraClass || ""}`}
    >
      <div className="inner">
        <h5 className="widget-title">Popular Tags</h5>
        <div className="content">
          <div
            className={`tag-list${
              props.style2 === "enable" ? " bg-shade" : ""
            }`}
          >
            <Link>Relationship</Link>
            <Link>Marriage</Link>
            <Link>Wellness</Link>
            <Link>Lifestyle</Link>
            <Link> Business</Link>
            <Link>Life coach</Link>
            <Link>Finance</Link>
            <Link>Career coach</Link>
            <Link>Female Health</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagOne;
