import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  // redux
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  useEffect(() => {
    if (userInfo.reviews) {
      setReviews(userInfo.reviews);
    }
  }, [userInfo]);

  return (
    <>
      <h6>My Reviews</h6>

      {reviews && reviews.length > 0 ? (
        <div>
          {reviews.map((review, index) => (
            <div className="card pl--10" key={index}>
              <div className="expert-review-content">
                <div className="expert-review-content-top">
                  <div className="expert-review-content-left">
                    <h6>{review?.name}</h6>
                  </div>
                  <div className="expert-review-content-rigth">
                    <ReactStars
                      count={5}
                      value={review?.rating}
                      size={20}
                      activeColor="#f4c150"
                    />
                  </div>
                </div>
                <div className="expert-review-content-bottom">
                  <p>{review?.comment}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ padding: "3rem" }}>
          <div className="error-bar">No Reviews Yet</div>
        </div>
      )}
    </>
  );
};

export default Reviews;
