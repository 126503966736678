import React from "react";
import { Helmet } from "react-helmet";
// import { a } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import SEO from "../../common/SEO";
import founder from "../../assets/images/founder.mp4";
import "./founders.css";
import FooterFounder from "../../common/footer/FooterFounder";

const Founders = () => {

  return (
    <>
      <SEO
        title="Balm For Founders"
        img="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        link="https://Balm.ai/business"
        description="Find and Schedule clearity session with experts"
      />
      <Helmet>
        <meta property="og:title" content="Balm For Business" />
        <meta
          property="og:description"
          content="Find and Schedule clearity session with experts"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        />
        <meta property="og:url" content="https://Balm.ai/business" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Balm For Business" />
        <meta
          name="twitter:description"
          content="Find and Schedule clearity session with experts"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        />
      </Helmet>
      {/* <HeaderTwo styles="header-transparent header-style-2" /> */}
      <div style={{ Width: "100vw" }}>
        <div className="slider-area banner-style-5">
          <div className="founder-main-wrap">
            <div className="founder-wrap d-flex ">
              <div className="video-wrapper">
                <video
                  className="video-container"
                  autoPlay
                  loop
                  muted
                  src={founder}
                  playsInline
                ></video>
              </div>
              <div className="container">
                <div className="banner-5-padding">
                  <div className="row g-5 ">
                    <div className="col-lg-12 order-2 order-lg-1">
                      <div className="inner pt--100 pt_md--0 pt_sm--0">
                        <div className="content text-start">
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <h6 className="title">
                              Empowering Founders to Thrive
                            </h6>
                          </ScrollAnimation>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                          >
                            <p className="description">
                              A Mental Health Solution Tailored for Startups
                            </p>
                          </ScrollAnimation>
                          <div className="read-more-btn">
                            <ScrollAnimation
                              animateIn="fadeInUp"
                              animateOut="fadeInOut"
                              animateOnce={true}
                            >
                              <a
                                href="https://calendly.com/balmhealth/sp"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="founder-btn1"
                              >
                                Schedule a call{" "}
                              </a>
                              <a
                                href="https://api.whatsapp.com/message/HEWWGWPX3GX3B1?autoload=1&app_absent=0"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="founder-btn2"
                              >
                                Chat now{" "}
                              </a>
                            </ScrollAnimation>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* hero ends */}
        {/* empoer starts */}
        <div>
          <div className="container">
            <div className="empowering">
              <div>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4>Empowering you to keep building</h4>
                  <p>
                    Our flexible and convenient coaching services are tailored
                    to fit into your busy schedule, so you can prioritize your
                    mental wellness without sacrificing the demands of your
                    startup. Our holistic approach to mental wellness supports
                    both your personal and professional growth, empowering you
                    to build a sustainable and successful business.
                  </p>
                </ScrollAnimation>
              </div>
              <div>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="empower-img-wrap">
                    <img
                      src="https://rekod.my.canva.site/balm-for-founders/images/5efacc3e3fbdd68a7ee85487658b50c2.png"
                      alt="hi"
                    />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
        {/* empower ends */}
        {/* bennefits starts */}
        <div>
          <div className="founders-benefits">
            <div className="container">
              <div className="pt--100 pb--50">
                <h4>Benefits</h4>
                <p>Mental health solution for founders</p>
                <div className="benefit-card-wrapper">
                  <div className="benefit-card">
                    <img
                      src="https://rekod.my.canva.site/balm-for-founders/images/d30c5dac64ed9e7ca8cb7556f38ac125.png"
                      alt="be"
                    />{" "}
                    <h6>Flexible and convenient coaching</h6>
                    <p>
                      Flexible and convenient coaching services, tailored to fit
                      into your busy schedule.
                    </p>
                  </div>
                  <div className="benefit-card">
                    <img
                      src="https://rekod.my.canva.site/balm-for-founders/images/a06b2ef14f6def8740afe7f34568504b.png"
                      alt="be"
                    />{" "}
                    <h6>Co-founder conflict resolution</h6>
                    <p>
                      Strategies for effective conflict resolution and
                      maintaining a healthy working relationship among
                      stakeholders.
                    </p>
                  </div>
                  <div className="benefit-card">
                    <img
                      src="https://rekod.my.canva.site/balm-for-founders/images/09c5bd56b8509b0e9ec9c7b385de9789.png"
                      alt="be"
                    />{" "}
                    <h6>Executive Leadership coaching</h6>
                    <p>
                      Brainstorming sessions and strategies for business growth,
                      leadership, expansion, and fundraising.
                    </p>
                  </div>
                  <div className="benefit-card">
                    <img
                      src="https://rekod.my.canva.site/balm-for-founders/images/beeed61d2be6b0b6071d1fe18983ad7a.png"
                      alt="be"
                    />{" "}
                    <h6>Mental health and resilience</h6>
                    <p>
                      Support needed for stress management, coping with
                      challenges, and building emotional resilience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* bennefits ends */}
        {/* join us start */}
        <div>
          <div>
            <div className="founder-join-us">
              <div className="container">
                <div className="join-us-content pt--100">
                  <h4>
                    Join us today and start your journey towards greater
                    well-being!
                  </h4>
                  <div className="read-more-btn">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <a
                        href="https://calendly.com/balmhealth/sp"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="founder-btn3"
                      >
                        Schedule a call{" "}
                      </a>
                      <a
                        href="https://api.whatsapp.com/message/HEWWGWPX3GX3B1?autoload=1&app_absent=0"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="founder-btn4"
                      >
                        Chat now{" "}
                      </a>
                    </ScrollAnimation>
                  </div>
                  <div className="join-us-img">
                    <div className="join-us-img-wrap">
                      <img
                        src="https://rekod.my.canva.site/balm-for-founders/images/fbb17d6b427e35ae40b7ae1ba0b2ee27.png"
                        alt="hi"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* join us ends */}
        {/* helping start */}
        <div>
          <div className="helping-founders pt--100 pb--50">
            <div className="container helping-wrap">
              <h4>Helping founders build resilience</h4>
              <h6>Served over 1,000 entrepreneurs</h6>
              <div className="helping-founder-wrap">
                <div>
                  <p>Personalized mental health support</p>
                </div>
                <div>
                  <p>Resources to help you scale your startup</p>
                </div>
                <div>
                  <p>Access to like-minded founders</p>
                </div>
              </div>
              <div className="helping-read-more-btn">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <a
                    href="https://calendly.com/balmhealth/sp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="founder-btn5"
                  >
                    Schedule a call{" "}
                  </a>
                  <a
                    href="https://api.whatsapp.com/message/HEWWGWPX3GX3B1?autoload=1&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="founder-btn6"
                  >
                    Chat now{" "}
                  </a>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterFounder />
    </>
  );
};

export default Founders;
