import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./TimeBar.css";

function TimeBar() {
  const [expertdetails, setExpertdetails] = useState({});
  const [expertTimezone, setExpertTimezone] = useState("");
  const [duration, setDuration] = useState("");
  const date = new Date(expertdetails.startTime).toString().substring(0, 24);
  // redux
  const addMeetingDetails = useSelector((state) => state.addMeetingDetails);
  const { addMeetingInfo } = addMeetingDetails;

  useEffect(() => {
    if (addMeetingInfo) {
      setExpertdetails(addMeetingInfo);
      setExpertTimezone(addMeetingInfo.expertTimezone.value);
      setDuration(addMeetingInfo.timeDuration.value);
    }
  }, [addMeetingInfo]);
  //
  return (
    <div className="main-div-timebar">
      <h2 id="h2-date"> {expertdetails.expertName}</h2>
      <h2 id="h2-date">{duration} Minute Meeting</h2>
      <h4 id="h4-date">🕒 {date}</h4>
      <h4 id="h4-date">🌎 {expertTimezone} </h4>
      <h4 id="h4-date">${expertdetails.finalPrice}</h4>
    </div>
  );
}

export default TimeBar;
// {new Date(start).substring(0, 28)}
// expertdetails.startTime
