import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne17 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="Use Balm to manage your Advisory, Therapy and Counselling Practice
"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Use Balm to manage your Advisory, Therapy and Counselling Practice
          "
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      Use Balm to manage your Advisory, Therapy and Counselling
                      Practice
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.unsplash.com/photo-1588196749597-9ff075ee6b5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8dGhlcmFweXxlbnwwfDB8MHx8&auto=format&fit=crop&w=1000&q=60`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      Mental health is an epidemic that threatens our existence.
                      We live in a world where over 600 million people suffer
                      from depression. Many struggle with their mental health,
                      and this should not be happening.
                    </p>
                    <p>
                      You are gifted with the ability to prevent millions of
                      people from suffering from depression, and other mental
                      health challenges.
                    </p>
                    <p>
                      We believe everyone should have access to affordable,
                      private, and on-demand mental healthcare and with your
                      passion, skills, and experience, you have what it takes to
                      make the world a better place.
                    </p>
                    <p>
                      Our big audacious health goals at Balm require radical
                      collaboration with psychologists, psychiatrists,
                      sociologists, career, relationships and marriage
                      counselors, and when achieved, will improve the lives of
                      billions of people globally.
                    </p>

                    <blockquote className="blockquote-style-1">
                      “Balm is healing the world. Join us as an expert as we
                      join forces to heal others, at your convenience. With
                      Balm, you can find purpose, impact and earn while doing
                      what you love. . Visit
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      started”
                    </blockquote>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Importance-of-Mental-Health-programs-for-your-employees">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                              Importance of Mental Health programs for your
                              employees
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Our-BHAG-at-Balm">
                            <span>Our BHAG at Balm</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne17;
