import React from "react";
import { Helmet } from "react-helmet";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import CourseTypeFilter from "../../components/course/CourseTypeFilter";

const expertsFilter = () => {
  return (
    <>
      <SEO
        title="Expert market-place"
        img="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        link="https://Balm.ai/experts/market-place"
        description="Find and Schedule clearity session with experts"
      />
             <Helmet>
        <meta property="og:title" content="Expert market-place" />
        <meta property="og:description" content="Find and Schedule clearity session with experts" />
        <meta property="og:image" content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg" />
        <meta property="og:url" content="https://Balm.ai/experts/market-place" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Expert market-place" />
        <meta name="twitter:description" content="Find and Schedule clearity session with experts" />
        <meta name="twitter:image" content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg" />
      </Helmet>
      <Layout>
        <BreadcrumbOne
          title="Expert Filter"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Expert Market-place"
        />
        <div className="edu-course-area course-three-wrapper edu-section-gap bg-color-white position-relative">
          <div className="container">
            <CourseTypeFilter showLoadMore="enable" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default expertsFilter;
