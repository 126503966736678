import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import Multiselect from "multiselect-react-dropdown";
// time zonee
import { my_timezones, languages_list } from "../../utils";

import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";

import { updateExpertProfile } from "../../actions/expertActions";
import axios from "axios";
const defaultValue = {
  year: new Date().getFullYear(),
  month: new Date().getMonth(),
  day: new Date().getDay(),
};
const BasicInfo = () => {
  // cloudimage
  const [loadingid, setLoadingid] = useState(false);
  const [loadinglicense, setLoadinglicense] = useState(false);
  const [loadingprofile, setLoadingprofile] = useState(false);
  const [cloudImage, setCloudImage] = useState({});
  const [idImage, setIdImage] = useState({});
  const [licenseImage, setLicenseImage] = useState({});

  // serverstates
  const [_id, set_id] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState();
  const [profileImg, setProfileImg] = useState({});
  const [specialization, setSpecialization] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(defaultValue);
  const [gender, setGender] = useState("");
  const [about, setAbout] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [fortyMin, setFortyMin] = useState();
  const [sixtyMin, setSixtyMin] = useState();
  const [eigtyMin, setEigtyMin] = useState();
  const [idCard, setIdCard] = useState({});
  const [license, setLicense] = useState({});
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [timezone, setTimezone] = useState({});
  const [language, setLanguage] = useState([]);
  const [nextOfKinName, setNextOfKinName] = useState("");
  const [nextOfKinEmail, setNextOfKinEmail] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [nextOfKinRelation, setNextOfKinRelation] = useState("");

  // redux
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const expertUpdateProfile = useSelector((state) => state.expertUpdateProfile);
  const { userInfo } = userSignin;

  // userdetails
  const [userDetails, setUserDetails] = useState(userInfo);
  const [loading, setLoading] = useState(false);

  const fetchAccount = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}expert/expert/${userInfo._id}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setUserDetails(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error("❌  error!", message);
    }
  };
  useEffect(() => {
    if (userInfo) {
      fetchAccount();
    }
  }, [userInfo]);

  useEffect(() => {
    if (userDetails) {
      // ("userinfo====>", userInfo);
      setName(userDetails.name);
      set_id(userDetails._id);
      setPhone(userDetails.phone);
      setProfileImg(userDetails?.profileImg);
      setSpecialization(userDetails.specialization);
      setDateOfBirth(userDetails.dateOfBirth);
      setGender(userDetails.gender);
      setAbout(userDetails.about);
      setLinkedinUrl(userDetails.linkedinUrl);
      setFortyMin(userDetails.fortyMin);
      setSixtyMin(userDetails.sixtyMin);
      setEigtyMin(userDetails.eigtyMin);
      setIdCard(userDetails.idCard);
      setLicense(userDetails.license);
      setAddress1(userDetails.address1);
      setAddress2(userDetails.address2);
      setCity(userDetails.city);
      setState(userDetails.state);
      setCountry(userDetails.country);
      setTimezone(userDetails.timezone);
      setLanguage(userDetails.language);
      setNextOfKinName(userDetails.nextOfKinName);
      setNextOfKinEmail(userDetails.nextOfKinEmail);
      setNextOfKinPhone(userDetails.nextOfKinPhone);
      setNextOfKinRelation(userDetails.nextOfKinRelation);
      setCloudImage(userDetails?.profileImg);
      setIdImage(userDetails.idCard);
      setLicenseImage(userDetails.license);
    }
  }, [userDetails]);

  const uploadID = async (e) => {
    let id = idImage?.public_id || "45refu7t5ffr6r4";

    let file = e.target.files;
    if (file) {
      setLoadingid(true);
      for (let i = 0; i < file.length; i++) {
        Resizer.imageFileResizer(
          file[i],
          720,
          720,
          "JPEG",
          50,
          0,
          (uri) => {
            axios
              .post(` ${process.env.REACT_APP_API_KEY}uploads`, {
                image: uri,
                id,
              })
              .then((res) => {
                setLoadingid(false);
                setIdImage(res.data);
                setIdCard(res.data);
              })
              .catch((err) => {
                setLoadingid(false);

                toast.error("❌ image upload failed 😔", err.response);
              });
          },
          "base64"
        );
      }
    }
  };

  const uploadLicense = async (e) => {
    let id = licenseImage?.public_id || "45refu7t5ffr6r4";

    let file = e.target.files;
    if (file) {
      setLoadinglicense(true);
      for (let i = 0; i < file.length; i++) {
        Resizer.imageFileResizer(
          file[i],
          720,
          720,
          "JPEG",
          50,
          0,
          (uri) => {
            axios
              .post(` ${process.env.REACT_APP_API_KEY}uploads`, {
                image: uri,
                id,
              })
              .then((res) => {
                setLoadinglicense(false);
                setLicenseImage(res.data);
                setLicense(res.data);
              })
              .catch((err) => {
                setLoadinglicense(false);
              });
          },
          "base64"
        );
      }
    }
  };

  const fileUploadAndResize = (e) => {
    let id = cloudImage.public_id;

    let file = e.target.files;
    if (file) {
      setLoadingprofile(true);
      for (let i = 0; i < file.length; i++) {
        Resizer.imageFileResizer(
          file[i],
          720,
          720,
          "JPEG",
          50,
          0,
          (uri) => {
            axios
              .post(` ${process.env.REACT_APP_API_KEY}uploads`, {
                image: uri,
                id,
              })
              .then((res) => {
                setLoadingprofile(false);
                setCloudImage(res?.data);
                setProfileImg(res?.data);
              })
              .catch((err) => {
                setLoadingprofile(false);
              });
          },
          "base64"
        );
      }
    }
  };

  // submit
  const submitHandler = (e) => {
    e.preventDefault();

    let expert = {
      _id,
      name,
      phone,
      profileImg,
      specialization,
      dateOfBirth,
      gender,
      about,
      linkedinUrl,
      fortyMin,
      sixtyMin,
      eigtyMin,
      idCard,
      license,
      address1,
      address2,
      city,
      state,
      country,
      timezone,
      language,
      nextOfKinName,
      nextOfKinEmail,
      nextOfKinPhone,
      nextOfKinRelation,
    };
    dispatch(updateExpertProfile(expert));
  };

  // datepickaer minimum date
  const maximumDate = {
    year: 2005,
    month: 4,
    day: 10,
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="enter your date of birth"
      value={
        dateOfBirth
          ? ` ${dateOfBirth.day}/${dateOfBirth.month}/${dateOfBirth.year}`
          : ""
      }
      style={{
        width: "100%",
        textAlign: "center",
        padding: "1rem 1.5rem",
        fontSize: "2rem",
      }}
      className="my-custom-input-class" // a styling class
    />
  );

  return (
    <>
      {loading ? (
        <div style={{ padding: "3rem" }}>
          <div className="loading-bar">
            <i
              className={"fa fa-spinner fa-spin"}
              style={{ margin: "1rem" }}
            ></i>
            loading
          </div>
        </div>
      ) : (
        <div>
          {/* basic */}
          <div className="expert-basic-info">
            <div className="title">
              <h6>Basic Information</h6>
              <div className="expert-basic-profile">
                <div className="expert-basic-proile-img">
                  {loadingprofile ? (
                    <>loading...</>
                  ) : (
                    <img
                      src={
                        cloudImage?.url ||
                        `https://res.cloudinary.com/balmai/image/upload/v1658915462/uploadPH_gwz3sl.jpg`
                      }
                      alt="Upload"
                    />
                  )}
                </div>

                <div className="expert-basic-profile-btn">
                  <input
                    type="file"
                    // multiple
                    onChange={fileUploadAndResize}
                    accept="images/*"
                    className="btn btn-primary"
                    style={{ color: "white" }}
                    placeholder="Upload photo"
                  />

                  <p>Upload a passport image of yourself. </p>
                  <p> JPG, GIF or PNG allowed. Max size of 1MB</p>
                </div>
              </div>
              <form>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Full Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-input-box">
                    <label>Phone Number *</label>
                    <input
                      type="tel"
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Date of Birth *</label>
                    {/* <DatePicker onChange={(date) => setDateOfBirth(date)} /> */}
                    <DatePicker
                      value={dateOfBirth ? dateOfBirth : null}
                      onChange={setDateOfBirth}
                      renderInput={renderCustomInput}
                      maximumDate={maximumDate}
                      shouldHighlightWeekends
                      inputClassName="my-custom-input"
                    />
                  </div>
                  <div className="form-input-box">
                    <label>Gender *</label>
                    <select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="male">male</option>
                      <option value="female">female</option>
                    </select>
                  </div>
                </div>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Language</label>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: "3px",
                        background: "#f5f5f5f5",
                        color: "#231F40",
                        fontWeight: "600",
                        border: "none",
                      }}
                    >
                      {/* <Select
                    placeholder={language ? language.value : `select language`}
                    onChange={setLanguage}
                    options={languages_list}
                  /> */}

                      <Multiselect
                        displayValue="key"
                        // onKeyPressFn={function noRefCheck() {}}
                        // onRemove={function noRefCheck() {}}
                        // onSearch={function noRefCheck() {}}
                        onSelect={setLanguage}
                        options={languages_list}
                        selectedValues={language}
                      />
                    </div>
                  </div>
                  <div className="form-input-box"></div>
                </div>
              </form>
            </div>
          </div>
          {/* about */}
          <div className="expert-basic-info">
            <div className="title">
              <h6>Professional Information</h6>
              <form>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Linkedin Url</label>
                    <input
                      type="url"
                      required
                      className="form-control"
                      value={linkedinUrl}
                      onChange={(e) => setLinkedinUrl(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Select your category of interest</label>
                    <select
                      value={specialization}
                      onChange={(e) => setSpecialization(e.target.value)}
                    >
                      <option>Select a category</option>
                      <option value="Mental Health">Mental Health</option>
                      <option value="Relationship & Marriage">
                        Relationship & Marriage
                      </option>
                      <option value="Wellness & Lifestyle">
                        Wellness & Lifestyle
                      </option>

                      <option value="Business and life coach">
                        Business and life coach
                      </option>
                      <option
                        value="Finance"
                      >
                        Finance
                      </option>
                      <option
                        value="Female Health"
                      >
                        Female Health
                      </option>
                      <option value="Career coach">Career coach</option>
                      <option value="Peak performance coach">
                        Peak performance coach
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Describe your expertise</label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* rate card */}
          <div className="expert-basic-info">
            <div className="title">
              <h6>Rate Card (set your price per sessions)</h6>
              <form>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Per 40 minutes ($)</label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      value={fortyMin}
                      onChange={(e) => setFortyMin(e.target.value)}
                    />
                  </div>
                  {/* <div className="form-input-box">
                <label>Per 60 minutes ($)</label>
                <input
                  type="number"
                  className="form-control"
                  value={sixtyMin}
                  onChange={(e) => setSixtyMin(e.target.value)}
                />
              </div> */}
                  <div className="form-input-box">
                    <label>Per 80 minutes ($)</label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      value={eigtyMin}
                      onChange={(e) => setEigtyMin(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* clinic info */}
          <div className="expert-basic-info">
            <div className="title">
              <h6>Uploads</h6>
              <form>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Upload a Picture of your Id card</label>
                    <div className="expert-basic-profile">
                      <div className="expert-basic-proile-img">
                        {loadingid ? (
                          <>loading...</>
                        ) : (
                          <img
                            src={
                              idImage
                                ? idImage?.url
                                : `https://res.cloudinary.com/balmai/image/upload/v1658915462/uploadPH_gwz3sl.jpg`
                            }
                            alt="Upload"
                          />
                        )}
                      </div>

                      <div className="expert-basic-profile-btn">
                        <input
                          type="file"
                          // multiple
                          onChange={uploadID}
                          accept="images/*"
                          className="btn btn-primary"
                          style={{ color: "white" }}
                          placeholder="Upload photo"
                        />

                        <p>Allowed JPG, GIF or PNG. Max size of 2MB</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Upload a Picture of your License</label>
                    <div className="expert-basic-profile">
                      <div className="expert-basic-proile-img">
                        {loadinglicense ? (
                          <>loading...</>
                        ) : (
                          <img
                            src={
                              licenseImage
                                ? licenseImage?.url
                                : `https://res.cloudinary.com/balmai/image/upload/v1658915462/uploadPH_gwz3sl.jpg`
                            }
                            alt="Upload"
                          />
                        )}
                      </div>

                      <div className="expert-basic-profile-btn">
                        <input
                          type="file"
                          // multiple
                          onChange={uploadLicense}
                          accept="images/*"
                          className="btn btn-primary"
                          style={{ color: "white" }}
                          placeholder="Upload photo"
                        />

                        <p>Allowed JPG, GIF or PNG. Max size of 2MB</p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* contact details */}
          <div className="expert-basic-info">
            <div className="title">
              <h6>Contact Details</h6>
              <form>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      className="form-control"
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                  <div className="form-input-box">
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      className="form-control"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="form-input-box">
                    <label>State / Province</label>
                    <input
                      type="text"
                      className="form-control"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                  <div className="form-input-box">
                    <label>Timezone</label>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: "3px",
                        background: "#f5f5f5f5",
                        color: "#231F40",
                        fontWeight: "600",
                        border: "none",
                        zIndex: 9,
                      }}
                    >
                      <Select
                        placeholder={timezone?.value || `select timzone`}
                        onChange={setTimezone}
                        options={my_timezones}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* next of kin */}
          <div className="expert-basic-info">
            <div className="title">
              <h6>Reference or next of Kin</h6>
              <form>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={nextOfKinName}
                      onChange={(e) => setNextOfKinName(e.target.value)}
                    />
                  </div>
                  <div className="form-input-box">
                    <label>email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={nextOfKinEmail}
                      onChange={(e) => setNextOfKinEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-input-wrap">
                  <div className="form-input-box">
                    <label>Phone</label>
                    <input
                      type="tel"
                      className="form-control"
                      value={nextOfKinPhone}
                      onChange={(e) => setNextOfKinPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-input-box">
                    <label>Relationship to you</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" e.g brother mother friend imam uncle"
                      value={nextOfKinRelation}
                      onChange={(e) => setNextOfKinRelation(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <button
            className="rn-btn edu-btn  mb--30"
            type="submit"
            onClick={submitHandler}
          >
            {expertUpdateProfile.loading ? "Updating..." : "Save Changes"}
          </button>
        </div>
      )}
    </>
  );
};

export default BasicInfo;
