import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import SEO from "../../common/SEO";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { signin } from "../../actions/userActions";
import { auth, provider } from "../../firebase";
import jwt_decode from "jwt-decode";

function SigninEmail() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(false);

  // redux
  const history = useHistory();
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;

  useEffect(() => {
    if (userInfo && userInfo.isExpert) {
      history.push("/expert-dashboard/profile");
    }
  }, [userInfo, history]);

  const handleGoogleSignIn = (e) => {
    e.preventDefault();
    const checkExpert = { isExpert: true };
    signInWithPopup(auth, provider)
      .then((result) => {
        const userObject = result.user;
        if (userObject) {
          dispatch(
            signin(
              userObject.email,
              userObject.displayName,
              { public_id: userObject.uid, url: userObject.photoURL },
              checkExpert,
              userObject.uid
            )
          );
        } else {
          toast.error("❌ signin failed. try again later");
          history.push("/expert/signin");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  function handleCredentialResponse(response) {
    const userObject = jwt_decode(response.credential);
    const checkExpert = { isExpert: true };
    if (userObject) {
      dispatch(
        signin(
          userObject.email,
          userObject.name,
          { public_id: userObject.picture, url: userObject.picture },
          checkExpert
        )
      );
    } else {
      toast.error("❌ signin failed. try again later");
      history.push("/expert/signin");
    }
  }
  // email sign in
  const submitHandler = async (e) => {
    e.preventDefault();
    if (email.split("@")[1] !== "gmail.com") {
      toast.error("❌ email must be a gmail");
    } else if (password.length < 7) {
      toast.error("❌ password must be at least 8 characters");
    } else {
      setLoad(true);
      const name = email.split("@")[0];
      const checkExpert = { isExpert: true };
      const profileImg = {
        public_id: "12455853754",
        url: "https://res.cloudinary.com/balmai/image/upload/v1668871675/profil_ulvgaq.png",
      };
      try {
        const { data } = await axios.post(
          ` ${process.env.REACT_APP_API_KEY}users/signinemail`,
          { email, name, password, profileImg, checkExpert }
        );
        if (data._id) {
          dispatch({ type: "USER_SIGNIN_SUCCESS", payload: data });
        }
        if (data.message === "ok") {
          setLoad(false);
          setAlert(true);
        }
      } catch (error) {
        setLoad(false);
        toast.error("❌ invalid email");
      }
    }
  };

  const buttonStyle = {
    width: "90%",
    backgroundColor: "white",
    color: "black",
    padding: "10px 20px",
    borderRadius: "4px",
    border: "0.5px solid #ccc",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyle = {
    marginRight: "10px",
  };

  return (
    <div id="main-div">
      <SEO title="Login" />

      <div
        className="login-register-page-wrapper bg-color-white"
        style={{ paddingTop: "20px" }}
      >
        <div className="container checkout-page-style">
          <div className="conatainer">
            {" "}
            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
              <div className="logo" style={{ width: "100" }}>
                <Link to={"/"} style={{ width: "70%" }}>
                  <img
                    style={{ width: "40%", height: "auto" }}
                    className="logo-light"
                    src="https://res.cloudinary.com/balmai/image/upload/v1658584166/balm-web/My_project_4_1_1_qsw5mj.png"
                    alt="Main Logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            // className="row g-5"
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: "40px",
              marginBottom: "80px",
            }}
          >
            <div className="sign-up-form-wrap">
              <div
                className="sign-up-form-info"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h6 style={{ color: "white", textAlign: "center" }}>
                  For Providers
                </h6>
                <p
                  style={{
                    color: "white",
                    fontSize: "1.9rem",
                    textAlign: "center",
                  }}
                >
                  Balm is inviting you to join and offer virtual therapy,
                  coaching, or mentorship sessions to individuals who need your
                  services. By signing up, you can reach a wider audience
                  globally, offer virtual sessions from your preferred location,
                  and receive payment immediately after each session.
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: "1.9rem",
                    textAlign: "center",
                  }}
                >
                  To get started, you need to sign up and create your profile.
                  The platform will provide you with all the necessary support
                  for your success. The platform aims to make a positive impact
                  in the world, and you can be a part of it by joining today.
                </p>
              </div>
              <form className="inner-signup" method="POST">
                <h4 style={{ color: "#385aa2" }}>Expert Sign In</h4>
                <div id="signin-Google-Button" style={{ textAlign: "center" }}>
                  Become an expert and start earning in just few steps
                </div>

                <div
                  id="signin-Google-Button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {alert && (
                    <div className="success-bar">
                      A verification mail has been sent to your email
                    </div>
                  )}
                  {(loading || load) && !alert && (
                    <div className="loading-bar">
                      <i
                        className={"fa fa-spinner fa-spin"}
                        style={{ margin: "1rem" }}
                      ></i>
                      Signing In...
                    </div>
                  )}
                  {!loading && !load && !alert && (
                    <button
                      style={buttonStyle}
                      onClick={(e) => handleGoogleSignIn(e)}
                    >
                      <span style={iconStyle}>
                        <img src="/google.svg" alt="google" width="25px" />
                      </span>
                      Sign in with Google
                    </button>
                  )}
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <h3 style={{ marginBottom: "0px" }} id="h3">
                      or
                    </h3> */}
                  <h3 id="h3">Enter your email to continue.</h3>
                  <input
                    type="email"
                    value={email}
                    required
                    className="input-signin"
                    placeholder="email address"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                  <input
                    type="password"
                    required
                    value={password}
                    className="input-signin"
                    placeholder="password"
                    onChange={(e) => setPassword(e.target.value)}
                  ></input>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <button
                      className="edu-btn btn-medium left-icon header-button mb--10"
                      type="submit"
                      value="Submit"
                      disabled={alert || load}
                      onClick={(e) => submitHandler(e)}
                    >
                      Continue
                    </button>
                    <p className="existing-account" disabled={true}>
                      Want to Sign in as a User instead?
                      <Link to="/user/signin" id="a-signup">
                        {" "}
                        Yes
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </div>
  );
}

export default SigninEmail;
