import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import profile from "../../../data/profil.png";
import profile from "../../data/profil.png";

const CourseTypeOne = ({ data, classes }) => {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 760) {
      setSmall(true);
    }
  }, []);

  return (
    <div
      className={`edu-card card-type-3 radius-small ${classes ? classes : ""}`}
    >
      {data?.slug ? (
        <div className="inner">
          <div className="thumbnail">
            <Link to={`/${data?.slug}`}>
              {data?.profileImg ? (
                <img
                  src={data?.profileImg?.url || profile}
                  alt="expert"
                  className="w-100 exprt-image"
                  loading="lazy"
                  // onError={setImg(profile)}
                />
              ) : (
                <img src={profile} alt="expert Member" className="w-100" />
              )}
            </Link>
            <div className="wishlist-top-right">
              <button className="wishlist-btn">
                <i className="fa fa-certificate"></i>
              </button>
            </div>
            <div className="top-position status-group left-bottom">
              <Link className="eduvibe-status status-03" to={`/${data?.slug}`}>
                {data.specialization}
              </Link>
            </div>
          </div>
          <div className="content">
            <div className="card-top">
              <div className="author-meta">
                <div className="author-thumb">
                  <Link to={`/${data?.slug}`}>
                    {/* <Link to={`/expert/${data?._id}`}> */}
                    <span className="author-title">{data?.name}</span>
                  </Link>
                </div>
              </div>
            </div>
            <p className="title">
              {small ? (
                <Link to={`/${data?.slug}`}>
                  {data?.about.substring(0, 30)}...
                </Link>
              ) : (
                <Link to={`/${data?.slug}`}>{data?.about.substring(0, 114)}...</Link>
              )}
            </p>
            <div className="card-bottom">
              <div className="price-list price-style-02">
                {data.fortyMin === 0 ? (
                  <div className="price current-price">Free</div>
                ) : (
                  <div className="price current-price">${data?.fortyMin}</div>
                )}
              </div>
              <div className="edu-rating rating-default">
                <span className="rating-count">per session</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="inner">
          <div className="thumbnail">
            <Link to={`/expert/${data?._id}`}>
              {data?.profileImg?.url ? (
                <img
                  src={data?.profileImg?.url}
                  alt="expert"
                  className="w-100 exprt-image"
                  loading="lazy"
                />
              ) : (
                <img src={profile} alt="expert Member" className="w-100" />
              )}
            </Link>
            <div className="wishlist-top-right">
              <button className="wishlist-btn">
                <i className="fa fa-certificate"></i>
              </button>
            </div>
            <div className="top-position status-group left-bottom">
              <Link
                className="eduvibe-status status-03"
                to={`/expert/${data?._id}`}
              >
                {data.specialization}
              </Link>
            </div>
          </div>
          <div className="content">
            <div className="card-top">
              <div className="author-meta">
                <div className="author-thumb">
                  <Link to={`/${data?.slug}`}>
                    {/* <Link to={`/expert/${data?._id}`}> */}
                    <span className="author-title">{data?.name}</span>
                  </Link>
                </div>
              </div>
            </div>
            <p className="title">
              {small ? (
                <Link to={`/expert/${data?._id}`}>
                  {data?.about?.substring(0, 30)}...
                </Link>
              ) : (
                <Link to={`/expert/${data?._id}`}>
                  {data?.about?.substring(0, 114)}...
                </Link>
              )}
            </p>
            <div className="card-bottom">
              <div className="price-list price-style-02">
                {data.fortyMin === 0 ? (
                  <div className="price current-price">Free</div>
                ) : (
                  <div className="price current-price">${data?.fortyMin}</div>
                )}
              </div>
              <div className="edu-rating rating-default">
                <span className="rating-count">per session</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CourseTypeOne;
