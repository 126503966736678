import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import SEO from "../common/SEO";
import { signin } from "../actions/userActions";

const VerifyEmail = ({ location }) => {
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [fetching, setFetching] = useState(false)
  const [redirect, setRedirect] = useState("/user-dashboard/appointments");
  const params = useParams();
  const { detail1 } = params;

  // redux
  const history = useHistory();
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;

  const fetchData = async () => {

    try {
      setFetching(true)
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}users/verify/${detail1}`
      );
      const userDetails = jwt_decode(data.verifyToken);
      setUser(userDetails);
      setFetching(false)
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (detail1) {
      fetchData();
    }
  }, [detail1]);

  useEffect(() => {
    if (location && location.state && location.state.from) {
      const from = location.state.from;
      setRedirect(from);
    }
  }, [location]);

  useEffect(() => {
    if (userInfo && userInfo.isExpert) {
      history.push("/expert-dashboard/profile");
    }
  }, [userInfo, history]);
  useEffect(() => {
    if (userInfo && !userInfo.isExpert) {
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password === user.password) {
      dispatch(
        signin(
          user.email,
          user.name,
          user.profileImg,
          user.checkExpert,
          user.password
        )
      );
    } else {
      toast.error("❌ wrong password");
    }
  };
  return (
    <div id="main-div">
      <SEO title="Verify Email" />

      <div className="  bg-color-white" style={{ paddingTop: "20px" }}>
        <div className="container checkout-page-style">
          <div className="conatainer">
            {" "}
            <div className="col-lg-4 col-xl-3 col-md-6 col-6">
              <div className="logo" style={{ width: "100" }}>
                <Link to={"/"} style={{ width: "70%" }}>
                  <img
                    style={{ width: "40%", height: "auto" }}
                    className="logo-light"
                    src="https://res.cloudinary.com/balmai/image/upload/v1658584166/balm-web/My_project_4_1_1_qsw5mj.png"
                    alt="Main Logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="row g-5"
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: "40px",
              marginBottom: "80px",
            }}
          >
            <div>
              <p>
                thanks {user?.email} for choosing balm
                <br />
                enter your password to continue
              </p>
              <form className="form" onSubmit={submitHandler}>
                <input
                  style={{ maxWidth: "400px" }}
                  className="form-control"
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <button
                  className="edu-btn btn-medium left-icon header-button mt--10"
                  type="submit"
                  disabled={fetching}
                >
                  {loading ? "Signing in..." : "Continue"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
