import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne16 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="Importance of Mental Health programs for your employees
"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Importance of Mental Health programs for your employees
          "
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      Importance of Mental Health programs for your employees
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.pexels.com/photos/14989453/pexels-photo-14989453.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      There are Changes in work culture and a lack of mental
                      health support. Stress, anxiety, coupled with limited
                      resources to meet demand, can accumulate and lead to
                      burnout of your employees, and the inability to meet up
                      with expectations of the society, or obligations at home
                      sometimes result in depression, which can then affect
                      productivty at work.
                    </p>
                    <p>
                      Employees are your most valuable asset. Therefore, their
                      physical, mental and emotional wellness need to be
                      prioritized.
                    </p>
                    <p>
                      The good news is that employees who are healthy and happy
                      tend to be more focused and productive than those who are
                      not. Being more focused at work, with less burnout leads
                      to better retention, job satisfaction, and engagement.
                      With Balm, you employees are happier and more productive.
                    </p>

                    <blockquote className="blockquote-style-1">
                      “Balm integrates mental healthcare, coaching, and
                      mentoring for improved professional development and
                      organizational success. Visit
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      started”
                    </blockquote>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Mental-Illness-is-an-Epidemic-that-theratens-our-existence">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>
                              Mental Illness is an Epidemic that theratens our
                              existence
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Use-Balm-to-manage-your-Advisory">
                            <span>
                              Use Balm to manage your Advisory, Therapy and
                              Counselling Practice
                            </span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne16;
