import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const Hero = ({ refToLastComp }) => {
  const scrolltoLast = () => {
    if (refToLastComp.current) {
      refToLastComp.current.scrollIntoView();
    }
  };

  return (
    <div>
      {" "}
      <div className="slider-area banner-style-5 bg-image">
        <div className="wrapper d-flex ">
          <div className="container">
            <div className="banner-5-padding">
              <div className="row g-5 ">
                <div className="col-lg-12 order-2 order-lg-1">
                  <div className="inner pt--100 pt_md--0 pt_sm--0">
                    <div className="content text-start">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <h1 className="title">Balm For Business</h1>
                      </ScrollAnimation>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <p className="description">
                          Comprehensive mental health solution to transform
                          employee engagement, productivity, and business
                          growth.
                        </p>
                      </ScrollAnimation>
                      <div className="read-more-btn">
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                        >
                          <a
                            href="https://calendly.com/balmhealth/sp"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="edu-btn"
                          >
                            Get Started{" "}
                            <i className="icon-arrow-right-line-right"></i>
                          </a>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-color">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <button id="google-one-tap-sign-up"></button>
                <h6>
                  To inspire leaders, motivate employees, & grow businesses
                </h6>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
