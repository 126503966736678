import React from "react";
import { Link } from "react-router-dom";

const CategoryOne = (props) => {
  return (
    <div
      className={`edu-blog-widget${
        props.style2 === "enable" ? "-2" : ""
      } widget-categories ${props.extraClass || ""}`}
    >
      <div className="inner">
        <h5 className="widget-title">Categories</h5>
        <div className="content">
          <ul className="category-list">
            <li>
              <Link>
                Relationship & Marriage
                <span>()</span>
              </Link>
              <Link>
                Wellness & Lifestyle
                <span>()</span>
              </Link>
              <Link>
                Business and life coach
                <span>()</span>
              </Link>
              <Link>
                Finance
                <span>()</span>
              </Link>
              <Link>
                Female Health
                <span>()</span>
              </Link>
              <Link>
                Peak performance coach
                <span>()</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CategoryOne;
