import React, { useEffect } from "react";
import TawkTo from "tawkto-react";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import Home from "./screens/Home/Home";
import PrivateRoute from "./components/PrivateRoute";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import ExpertPage from "./screens/MainUserPage/Expertpage/ExpertPage";
// import EventTypes from "./screens/MainUserPage/EventTypes/EventTypes";
import MeetingScheduler from "./screens/MeetingScheduler/MeetingScheduler";
import SigninEmail from "./screens/SigninEmail/SigninEmail";
import ExpertFilter from "./screens/experts/expertsFilter";
// import "./App.css";
import Error from "./screens/Error";

// EXPERT
import ExpertAppointments from "./screens/expert-dashboard/ExpertAppointments";
import ExpertProfile from "./screens/expert-dashboard/ExpertProfle";
import ExpertEarnings from "./screens/expert-dashboard/ExpertEarnings";
import ExpertReviews from "./screens/expert-dashboard/ExpertReviews";
import ExpertSocial from "./screens/expert-dashboard/ExpertSocilal";

// users
import UserAppointments from "./screens/user/UserAppointments";
import UserProfile from "./screens/user/UserProfle";
import UserChangePassword from "./screens/user/UserChangePassword";

// Import Css Here
import "./apps.css";
import "./assets/scss/style.scss";
import Privacy from "./screens/Privacy";
import TermsOfService from "./screens/TermsOfService";
import UserSigninEmail from "./screens/SigninEmail/UserSigninEmail";
import VerifyEmail from "./screens/VerifyEmail";
import ExpertMainPage from "./screens/MainUserPage/Expertpage/ExpertMainPage";

// blogs
import BlogPosts from "./screens/blog/BlogPosts";
import BlogDetailsOne from "./screens/blog/BlogDetailsOne";
import BlogDetailsOne2 from "./screens/blog/BlogDetailsOne2";
import BlogDetailsOne3 from "./screens/blog/BlogDetailsOne3";
import BlogDetailsOne4 from "./screens/blog/BlogDetailsOne4";
import BlogDetailsOne5 from "./screens/blog/BlogDetailsOne5";
import BlogDetailsOne6 from "./screens/blog/BlogDetailsOne6";
import BlogDetailsOne7 from "./screens/blog/BlogDetailsOne7";
import BlogDetailsOne8 from "./screens/blog/BlogDetailsOne8";
import BlogDetailsOne9 from "./screens/blog/BlogDetailsOne9";
import BlogDetailsOne10 from "./screens/blog/BlogDetailsOne10";
import BlogDetailsOne11 from "./screens/blog/BlogDetailsOne11";
import BlogDetailsOne12 from "./screens/blog/BlogDetailsOne12";
import BlogDetailsOne13 from "./screens/blog/BlogDetailsOne13";
import BlogDetailsOne14 from "./screens/blog/BlogDetailsOne14";
import BlogDetailsOne15 from "./screens/blog/BlogDetailsOne15";
import BlogDetailsOne17 from "./screens/blog/BlogDetailsOne17";
import BlogDetailsOne16 from "./screens/blog/BlogDetailsOne16";
import BlogDetailsOne18 from "./screens/blog/BlogDetailsOne18";
import BlogDetailsOne19 from "./screens/blog/BlogDetailsOne19";
import BlogDetailsOne20 from "./screens/blog/BlogDetailsOne20";
import BlogDetailsOne21 from "./screens/blog/BlogDetailsOne21";
import ForBusiness from "./screens/business/ForBusiness";
import BlogDetailsOne22 from "./screens/blog/BlogDetailsOne22";
import BlogDetailsOne23 from "./screens/blog/BlogDetailsOne23";
import BlogDetailsOne24 from "./screens/blog/BlogDetailsOne24";
import Founders from "./screens/founders/Founders";
import Login from "./screens/SigninEmail/Login";
import WaitList from "./screens/waitlist/WaitList";
import BalanceLife from "./screens/assessment/BalanceLife";
import Bmi from "./screens/bmi/Bmi";
import ResiliencePosts from "./screens/resilience/ResiliencePosts";
import ResilienceDetailsOne from "./screens/resilience/ResilienceDetailsOne";
import BlogDetailsOne25 from "./screens/blog/BlogDetailsOne25";
import BlogDetailsOne26 from "./screens/blog/BlogDetailsOne26";
import BlogDetailsOne27 from "./screens/blog/BlogDetailsOne27";

function App(props) {
  useEffect(() => {
    var tawk = new TawkTo("61325058649e0a0a5cd47aa9", "1fem8jmrv");

    tawk.onStatusChange((status) => {});
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop>
          <ToastContainer
            transition={Slide}
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            icon
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Switch>
            {/* <Route exact path="/"> {loggedIn ? <Redirect to="/user" /> : <Home/>}</Route> */}
            <Route exact={true} path="/" component={Home}></Route>
            {/* <Route exact={true} path="/" component={NewHome}></Route> */}
            <Route
              exact={true}
              path="/expert/signin"
              component={SigninEmail}
            ></Route>
            <Route
              exact={true}
              path="/user/signin"
              component={UserSigninEmail}
            ></Route>
            <Route exact={true} path="/login" component={Login}></Route>

            {/* business for balm reverse */}
            <Route
              exact={true}
              path="/business"
              component={ForBusiness}
            ></Route>

            {/* balance life assessment */}
            <Route
              exact={true}
              path="/balance-life"
              component={BalanceLife}
            ></Route>

            {/* body mass index */}
            <Route exact={true} path="/bmi" component={Bmi}></Route>

            {/*founder for balm reverse */}
            <Route exact={true} path="/founders" component={Founders}></Route>

            {/* wiatlist for balm reverse */}
            <Route exact={true} path="/waitlist" component={WaitList}></Route>

            {/* resilience */}
            <Route
              exact={true}
              path="/resilience"
              component={ResiliencePosts}
            ></Route>

            {/* experts */}
            <PrivateRoute
              exact={true}
              path="/expert-dashboard/appointments"
              component={ExpertAppointments}
            ></PrivateRoute>
            <PrivateRoute
              exact={true}
              path="/expert-dashboard/profile"
              component={ExpertProfile}
            ></PrivateRoute>
            <PrivateRoute
              exact={true}
              path="/expert-dashboard/earnings"
              component={ExpertEarnings}
            ></PrivateRoute>
            {/* <Route
              exact={true}
              path="/expert-dashboard/earnings"
              component={ExpertEarnings}
            ></Route> */}
            <PrivateRoute
              exact={true}
              path="/expert-dashboard/reviews"
              component={ExpertReviews}
            ></PrivateRoute>
            <PrivateRoute
              exact={true}
              path="/expert-dashboard/social"
              component={ExpertSocial}
            ></PrivateRoute>

            {/* user dashboard */}
            <PrivateRoute
              exact={true}
              path="/user-dashboard/appointments"
              component={UserAppointments}
            ></PrivateRoute>
            <PrivateRoute
              exact={true}
              path="/user-dashboard/profile"
              component={UserProfile}
            ></PrivateRoute>
            <PrivateRoute
              exact={true}
              path="/user-dashboard/change-password"
              component={UserChangePassword}
            ></PrivateRoute>
            <Route
              exact={true}
              path="/expert/:id"
              component={ExpertPage}
            ></Route>
            <Route
              exact={true}
              path="/:slug"
              component={ExpertMainPage}
            ></Route>
            <Route
              exact={true}
              path="/auth/verify_emali/:detail1"
              component={VerifyEmail}
            ></Route>

            <Route
              exact={true}
              path="/meeting/expert=:expert/event=:id"
              component={MeetingScheduler}
            ></Route>

            <Route
              exact={true}
              path="/experts/market-place"
              component={ExpertFilter}
            ></Route>

            {/* resilience */}
            <Route
              exact={true}
              path="/resilience/post/tell-us-about-yourself-and-what-you-do-in-the-sports-training-industry"
              component={ResilienceDetailsOne}
            ></Route>

            {/* blog */}
            <Route
              exact={true}
              path="/blog/posts"
              component={BlogPosts}
            ></Route>

            <Route
              exact={true}
              path="/blog/post/How-to-find-your-path-to-mental-wellness"
              component={BlogDetailsOne}
            ></Route>

            <Route
              exact={true}
              path="/blog/post/Support-needed-on-your-journey to-better-mental-health"
              component={BlogDetailsOne2}
            ></Route>
            <Route
              exact={true}
              path="/blog/post/Empowering-you-to-take-control-of-your-mental-health"
              component={BlogDetailsOne3}
            />
            <Route
              exact={true}
              path="/blog/post/The-Art-of-Financial-Discipline"
              component={BlogDetailsOne4}
            />
            <Route
              exact={true}
              path="/blog/post/How-to-make-a-good-marital-decision"
              component={BlogDetailsOne5}
            />
            <Route
              exact={true}
              path="/blog/post/How-to-over-come-heartbreak"
              component={BlogDetailsOne6}
            />
            <Route
              exact={true}
              path="/blog/post/How-to-get-married-to-the-right-person"
              component={BlogDetailsOne7}
            />
            <Route
              exact={true}
              path="/blog/post/How-do-you-identify-a-person-who-is-depressed"
              component={BlogDetailsOne8}
            />
            <Route
              exact={true}
              path="/blog/post/Encouragement-for-one-who-lost-a-loved-one"
              component={BlogDetailsOne9}
            />
            <Route
              exact={true}
              path="/blog/post/Encouragement-for-startup-founders"
              component={BlogDetailsOne10}
            />
            <Route
              exact={true}
              path="/blog/post/Mental-health-for-employees"
              component={BlogDetailsOne11}
            />
            <Route
              exact={true}
              path="/blog/post/Managing-your-psychology-as-a-founder"
              component={BlogDetailsOne12}
            />
            <Route
              exact={true}
              path="/blog/post/Our-mission-at-Balm"
              component={BlogDetailsOne13}
            />
            <Route
              exact={true}
              path="/blog/post/Our-Innovation-story-at-Balm"
              component={BlogDetailsOne14}
            />
            <Route
              exact={true}
              path="/blog/post/Mental-Illness-is-an-Epidemic-that-theratens-our-existence"
              component={BlogDetailsOne15}
            />
            <Route
              exact={true}
              path="/blog/post/Importance-of-Mental-Health-programs-for-your-employees"
              component={BlogDetailsOne16}
            />
            <Route
              exact={true}
              path="/blog/post/Use-Balm-to-manage-your-Advisory"
              component={BlogDetailsOne17}
            />
            <Route
              exact={true}
              path="/blog/post/Our-BHAG-at-Balm"
              component={BlogDetailsOne18}
            />
            <Route
              exact={true}
              path="/blog/post/Psychological-support-for-you-and-your-employees"
              component={BlogDetailsOne19}
            />
            <Route
              exact={true}
              path="/blog/post/importanceofmentalhealthprograms"
              component={BlogDetailsOne20}
            />
            <Route
              exact={true}
              path="/blog/post/Companies-With-Excellent-Mental-Health-Benefit-Programs"
              component={BlogDetailsOne21}
            />
            <Route
              exact={true}
              path="/blog/post/Examples-of-a-Workplace-Wellness-Program-to-Draw-Inspiration-From"
              component={BlogDetailsOne22}
            />
            <Route
              exact={true}
              path="/blog/post/Expert-Tips-on-How-to-Take-Care-of-Your-Mental-Health-as-a-Founder"
              component={BlogDetailsOne23}
            />
            <Route
              exact={true}
              path="/blog/post/Ways-to-Practice-Self-Love"
              component={BlogDetailsOne24}
            />
            <Route
              exact={true}
              path="/blog/post/The-Evolution-of-Mental-Health-and-the-Emergence-of-AI"
              component={BlogDetailsOne25}
            />
            <Route
              exact={true}
              path="/blog/post/Why-Optimism-Might-Be-the-Secret-to-aLong-and-Healthy-Life"
              component={BlogDetailsOne26}
            />
            <Route
              exact={true}
              path="/blog/post/Mental-Resilience-by-Femi-Abioye"
              component={BlogDetailsOne27}
            />

            <Route
              path="/terms-of-service/balm"
              component={TermsOfService}
              exact={true}
            ></Route>
            <Route
              path="/privacy-policy/balm"
              component={Privacy}
              exact={true}
            ></Route>
            <Route path="/error/404" component={Error} exact={true}></Route>
            <Redirect to="/error/404" />
          </Switch>
        </ScrollToTop>
      </div>
    </BrowserRouter>
  );
}

export default App;
