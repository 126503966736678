import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateExpertProfile } from "../../actions/expertActions";

export const SocialMedia = () => {
  const [_id, set_id] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");

  // redux
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const expertUpdateProfile = useSelector((state) => state.expertUpdateProfile);
  const { userInfo } = userSignin;
  useEffect(() => {
    if (userInfo) {
      set_id(userInfo._id);
      setTwitterUrl(userInfo.twitterUrl);
      setInstagramUrl(userInfo.instagramUrl);
      setLinkedinUrl(userInfo.linkedinUrl);
    }
  }, [userInfo]);

  // submit
  const submitHandler = (e) => {
    e.preventDefault();

    let expert = {
      _id,
      twitterUrl,
      instagramUrl,
      linkedinUrl,
    };
    dispatch(updateExpertProfile(expert));
  };

  return (
    <>
      <div className="expert-basic-info">
        <div className="title">
          <h6>Social Media</h6>
          <form onSubmit={submitHandler}>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Twitter Url</label>
                <input
                  type="url"
                  // required
                  className="form-control"
                  value={twitterUrl}
                  onChange={(e) => setTwitterUrl(e.target.value)}
                />
              </div>
            </div>
            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Instagram Url</label>
                <input
                  type="url"
                  // required
                  className="form-control"
                  value={instagramUrl}
                  onChange={(e) => setInstagramUrl(e.target.value)}
                />
              </div>
            </div>

            <div className="form-input-wrap">
              <div className="form-input-box">
                <label>Linkedin Url</label>
                <input
                  type="url"
                  required
                  className="form-control"
                  value={linkedinUrl}
                  onChange={(e) => setLinkedinUrl(e.target.value)}
                />
              </div>
            </div>
            <button
              className="rn-btn edu-btn mt--30 mb--30"
              type="submit"
              // onClick={submitHandler}
            >
              {expertUpdateProfile.loading ? "Updating..." : "Save Changes"}
              {/* Save Changes */}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
