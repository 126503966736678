import React from "react";
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
const Error = () => {
  return (
    <>
      <SEO title="404 Not Found" />
      <div className="edu-error-area eduvibe-404-page edu-error-style">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <div className="content text-center">
                <div className="thumbnail mb--60">
                  <img
                    src="https://res.cloudinary.com/balmai/image/upload/v1656168319/balm-web/404_arcsms.png"
                    alt="404 Images"
                  />
                </div>
                <h3 className="title">Oops... Page Not Found!</h3>
                <p className="description">
                  Please return to the site's homepage, It looks like nothing
                  was found at this location
                </p>
                <div className="backto-home-btn">
                  <Link to="/" className="edu-btn">
                    Back To Home<i className="icon-arrow-right-line-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
