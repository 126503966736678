import { Link } from "react-router-dom";

import { CgProfile } from "react-icons/cg";
import { FaStar, FaMoneyBillAlt, FaShareAlt } from "react-icons/fa";
import { AiFillWechat } from "react-icons/ai";

import { BsFillCalendarCheckFill } from "react-icons/bs";


import Layout from "../../common/Layout";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";

import Reviews from "../../components/Expert/Reviews";
import AccountStatus from "../../components/Expert/AccountStatus";

const ExpertReviews = () => {
  return (
    <>
      <SEO title="my dashboard" />

      <Layout>
        <BreadcrumbOne
          title="Expert Dashboard"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="expert-dasboard"
        />
        <div className="expert-dashboard">
          <div>
            <aside className="expert-dashboard-nav">
              <AccountStatus />
              <nav
                className="expert-dashboard-navigation"
                style={{ borderTop: "1px solid lightgray" }}
              >
                <ul className="expert-dashboard-nav-ul">
                  <Link
                    to="/expert-dashboard/appointments"
                    className="expert-dashboard-nav-item "
                  >
                    {" "}
                    <BsFillCalendarCheckFill /> Apointments
                  </Link>
                  <Link
                    to="/expert-dashboard/profile"
                    className="/expert-dashboard-nav-item"
                  >
                    <CgProfile /> Profile Update
                  </Link>

                  <a
                    href="/expert-dashboard/earnings"
                    className="expert-dashboard-nav-item"
                  >
                    <FaMoneyBillAlt /> Earnings
                  </a>
                  <Link
                    to="/expert-dashboard/reviews"
                    className="expert-dashboard-nav-item active"
                  >
                    <FaStar /> Reviews
                  </Link>
                  <Link
                    to="/expert-dashboard/social"
                    className="expert-dashboard-nav-item"
                  >
                    <FaShareAlt /> Social Links
                  </Link>
                  <Link to="#" className="expert-dashboard-nav-item">
                    <AiFillWechat /> Messages
                  </Link>
                  {/* <Link to="#" className="expert-dashboard-nav-item">
                    <HiOutlineLogout /> Log out
                  </Link> */}
                </ul>
              </nav>
            </aside>
          </div>
          <div className="expert-dashboard-body">
            {/* <BasicInfo/> */}
            <Reviews />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ExpertReviews;
