import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SectionTitle from "../sectionTitle/SectionTitle";
import CourseTypeThree from "../../components/course/CourseTypeThree";
import CourseTypeOne from "../course/CourseTypeOne";

// import CourseData from '../../data/course/CourseData.json';

const HomeThreeCourse = (props) => {
  const InnerPagesSliderParams = {
    autoplay: true,
    autoplaySpeed: 0,
    speed: 20000,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: true,
    infinite: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="edu-counterup-and-course-area">
      {/* <CounterUpOne /> */}

      <div className="edu-course-area eduvibe-home-three-course counterup-overlay-top bg-image edu-section-gapTop edu-section-gapBottom">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-10">
              <SectionTitle
                classes="text-start"
                slogan={props.slogan}
                title={props.title}
              />
            </div>
            {/* <button className=" col-lg-2 catergory-slider-view-more">
                view more
              <i className="icon-arrow-right-line"></i>
            </button> */}
          </div>
          {/* <div className="row g-5 mt--10">
            {props.Data.map((item, index) => (
              <div className="col-6 col-sm-6 col-xl-3 col-md-4" key={index}>
                <CourseTypeOne data={item} />
              </div>
            ))}
          </div> */}
          <div className="row g-5 mt--25" style={{ overflow: "hidden" }}>
            <Slider {...InnerPagesSliderParams}>
              {props.Data.map((item, index) => (
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-6 col-sm-6 col-xl-3 col-md-4"
                  animateOnce={true}
                  key={index}
                >
                  <div
                    style={{ marginLeft: "0.9rem", marginRight: "0.9rem" }}
                    key={item.id}
                  >
                    <CourseTypeOne data={item} />
                  </div>
                </ScrollAnimation>
              ))}
            </Slider>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="load-more-btn mt--60 text-center">
                <Link className="edu-btn" to="/experts/market-place">
                  Find Experts
                  <i className="icon-arrow-right-line-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeThreeCourse;
