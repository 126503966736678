import React from "react";
import { Link } from "react-router-dom";
import ScrollTopButton from "./ScrollTopButton";
import { FaWhatsapp } from "react-icons/fa";

const FooterOne = () => {
  return (
    <>
      <footer className="eduvibe-footer-one edu-footer footer-style-default">
        <div className="footer-top">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="edu-footer-widget">
                  <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img
                        className="logo-light"
                        style={{ height: "auto", width: "20vw" }}
                        src="https://res.cloudinary.com/dxjprordi/image/upload/v1654283038/balm/My_project_copy_1_leyj7n.png"
                        alt="Footer Logo"
                      />
                    </Link>
                  </div>
                  <p className="description">
                    Balm.ai leverages technology to personalize preventive
                    healthcare, empowering individuals to manage their health
                    proactively.
                  </p>
                  <ul className="social-share">
                    <li>
                      <a
                        href="https://www.facebook.com/balmdotai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="icon-Fb"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/balmdotai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/message/HEWWGWPX3GX3B1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="">
                          <FaWhatsapp />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/balmdotai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="icon-Twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget explore-widget">
                  <h5 className="widget-title">Explore</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      {/* <li>
                        <Link to="/signin">
                          <i className="icon-Double-arrow"></i>Join our Team
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/blog/posts">
                          <i className="icon-Double-arrow"></i>Blog & News
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms-of-service/balm">
                          <i className="icon-Double-arrow"></i>Terms of Service
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy/balm">
                          <i className="icon-Double-arrow"></i>Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/post/How-to-find-your-path-to-mental-wellness">
                          <i className="icon-Double-arrow"></i>Latest
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget quick-link-widget">
                  <h5 className="widget-title">Useful Links</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/experts/market-place">
                          <i className="icon-Double-arrow"></i>Find Experts
                        </Link>
                      </li>
                      <li>
                        <Link to="/expert/signin">
                          <i className="icon-Double-arrow"></i>Become an Expert
                        </Link>
                      </li>
                      <li>
                        <Link to="/business">
                          <i className="icon-Double-arrow"></i>Balm for Business
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://forms.gle/7y9gTGqgvZSCF6Xe6"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="icon-Double-arrow"></i>Career
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget">
                  <h5 className="widget-title">Contact Info</h5>
                  <div className="inner">
                    <div className="widget-information">
                      <ul className="information-list">
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: +16692471992">+16692471992</a>
                        </li>

                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a
                            target="_blank"
                            href="mailto: Hello@balm.ai"
                            rel="noreferrer"
                          >
                            hello@balm.ai
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-md-block d-none"></div>
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    `© {new Date().getFullYear()} Balm Technologies Inc. | All
                    rights reserved`
                  </p>
                  {/* <p>
                    Copyright {new Date().getFullYear()} <span>Balm</span>{" "}
                    health . All Rights Reserved
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default FooterOne;
