import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne19 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="Psychological support for you and your employees

"
        img="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8RW1wbG95ZWVzfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
        description="As a leader, you inspire, influence and guide your team to
participate in a shared goal. Employees are your most
valuable assets in your organization. Therefore, their
physical,.."
        link="https://balm.ai/blog/post/Psychological-support-for-you-and-your-employees"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Psychological support for you and your employees

          "
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">
                      Psychological support for you and your employees
                    </h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8RW1wbG95ZWVzfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      As a leader, you inspire, influence and guide your team to
                      participate in a shared goal. Employees are your most
                      valuable assets in your organization. Therefore, their
                      physical, mental and emotional wellness need to be
                      prioritized. Employees who are healthy and happy tend to
                      be more focused and productive.
                      <br />
                      We believe your employees’ mental health is the key to
                      sustainable growth, for both your teams and your company.
                      We provide alternative therapy and mentorship for your
                      team or clients, for better retention, increase
                      productivity, employee motivation, job satisfaction, and
                      engagement.
                    </p>
                    <p>
                      With Balm, your employees are happier, more productive,
                      have more job satisfaction, and engagement via a
                      psychological support program, coaching, and mentoring for
                      improved professional development of your team, while
                      promoting organizational success, as well as improved
                      organizational culture.
                    </p>

                    <blockquote className="blockquote-style-1">
                      “Let’s help you create an empowered workforce, culture,
                      and enabling work environment for your employees to be
                      their best selves.
                      <br />
                      To get started, schedule a free consultation{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://calendly.com/balmhealth/sp"
                      >
                        here
                      </a>{" "}
                    </blockquote>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Our-BHAG-at-Balm">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>Our BHAG at Balm</span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/importanceofmentalhealthprograms">
                            <span>
                              The Importance of Mental Health Programs for Your
                              Employees
                            </span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne19;
