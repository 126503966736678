/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Earning from "./earnings/Earning.js";
import WithdrawTable from "./earnings/WithdrawTable.js";

const Earnings = () => {
  const [isView, setIsView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expertName, setExpertName] = useState("");
  const [expertId, setExpertId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState(34748545696);
  const [bankName, setBankName] = useState("");
  const [amount, setAmount] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  // redux
  const userSignin = useSelector((state) => state.userSignin);
  const earningDetails = useSelector((state) => state.earningDetails);
  const { userInfo } = userSignin;
  const { data } = earningDetails;

  useEffect(() => {
    if (userInfo) {
      setExpertId(userInfo._id);
      setExpertName(userInfo.name);
    }
  }, [userInfo]);

  const withdrawRequestHandler = async (e) => {
    e.preventDefault();

    if (!expertName) {
      toast.warn("❌ check your details 😔");
    } else if (!expertId) {
      toast.warn("❌ error! please check your network  and try again.😔");
    } else if (!accountName) {
      toast.warn("❌ Please Select Time 😔");
    } else if (!userInfo) {
      toast.info("📍 Please Sign in is required 😔");
      history.push("/user/signin", { from: `/expert-dashboard/earnings` });
    } else if (!userInfo.isExpert) {
      toast.info("📍 Please Sign up with user account to Schedule 😔");
      history.push("/expert/signin", { from: `expert-dashboard/earnings` });
    } else if (!bankName) {
      toast.warn("❌ error! please enter your bank name 😔");
    } else if (accountNumber.length < 9) {
      toast.warn("❌  error!, please enter valid account number😔");
    } else if (amount > data.total - 10) {
      toast.error("❌  error!, withdrawal amount exceeds your current limit😔");
    } else if (
      !expertName ||
      !expertId ||
      !accountName ||
      !userInfo ||
      !bankName ||
      !amount
    ) {
      toast.error(
        "❌ error! please check your account details/network  and try again.😔"
      );
    } else {
      setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_KEY}withdraw`,
          {
            expertId,
            expertName,
            accountName,
            accountNumber,
            bankName,
            amount,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        await dispatch({ type: "WITHDRAW_DETAILS_REQUEST", payload: data });

        setLoading(false);
        setIsView(false);
        toast.success(
          "✅ success! your withdrawal request has been received we will get back to you shortly.😊"
        );
      } catch (error) {
        setLoading(false);
        toast.error("❌ something went wrong");
      }
    }
  };

  return (
    <>
      {isView ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 23,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            overflowY: "scroll",
          }}
        >
          <div
            className="checkout-page-style"
            style={{
              alignItems: "center",
              width: "80vw",
              height: "auto",
              flexWrap: "wrap",
              background: "#ffff",
              display: "flex",
              justifyContent: "center",
              borderRadius: "10px",
              padding: "30px 10px",
              position: "relative",
            }}
          >
            <p
              className="earnings-close-modal"
              onClick={() => setIsView(false)}
            >
              x
            </p>
            <form
              className="inner-withdraw"
              // method="POST"
              // type="submit"
              // onSubmit={withdrawRequestHandler}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                  padding: "10px 10px",
                }}
              >
                enter your account details
              </p>
              {/* <div style={{ padding: "1.5rem", margin: "1rem" }}> */}
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  padding: "2px 5px",
                }}
              >
                📌NOTE: Account details must match profile details
              </p>
              {/* </div> */}
              {loading && (
                <div style={{ padding: "3rem" }}>
                  <div className="loading-bar">
                    <i
                      className={"fa fa-spinner fa-spin"}
                      style={{ margin: "1rem" }}
                    ></i>
                    Loading...
                  </div>
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    width: "90%",
                    height: "auto",
                    textAlign: "left",
                    padding: "3px",
                  }}
                >
                  Account Name:
                </label>
                <input
                  required={true}
                  id="name"
                  type="text"
                  value={accountName}
                  className="input-signin"
                  onChange={(e) => setAccountName(e.target.value)}
                ></input>
                <label
                  style={{
                    width: "90%",
                    height: "auto",
                    textAlign: "left",
                    padding: "3px",
                  }}
                >
                  Account Number:
                </label>
                <input
                  required={true}
                  id="account number"
                  type="Number"
                  value={accountNumber}
                  className="input-signin"
                  onChange={(e) => setAccountNumber(e.target.value)}
                ></input>
                <label
                  style={{
                    width: "90%",
                    height: "auto",
                    textAlign: "left",

                    padding: "3px",
                  }}
                >
                  Bank Name:
                </label>
                <input
                  required={true}
                  id="bank name"
                  type="text"
                  value={bankName}
                  className="input-signin"
                  onChange={(e) => setBankName(e.target.value)}
                ></input>
                <label
                  style={{
                    width: "90%",
                    height: "auto",
                    textAlign: "left",
                    padding: "3px",
                  }}
                >
                  Amount:
                </label>
                <input
                  required={true}
                  id="amount"
                  type="number"
                  value={amount}
                  className="input-signin"
                  onChange={(e) => setAmount(e.target.value)}
                ></input>
                <button
                  className="rn-btn edu-btn mt--30"
                  type="submit"
                  onClick={withdrawRequestHandler}
                >
                  Continue withdraw
                </button>
                <p></p>
                {/* </div> */}
              </div>
            </form>
            <p></p>
          </div>
        </div>
      ) : (
        ""
      )}
      <h6>Earnings</h6>

      <div style={{ padding: "1rem" }} className="expert-schedule">
        <div
          className="outer-most-container-event"
          // style={{ overflowX: "scroll" }}
        >
          <div className="main-container-event">
            <div className="upper-div-event"></div>

            <div className="lower-div-event">
              <Earning />
              <div style={{ padding: "1.5rem", margin: "1rem" }}>
                <span className="featuredSub">
                  📌NOTE: pending earnings will be credited when pending
                  sessions are completed
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="main-container-event">
          <div className="upper-div-event"></div>

          <div className="lower-div-event">
            <button
              style={{ margin: "2.3rem" }}
              className="rn-btn edu-btn "
              type="submit"
              onClick={() => setIsView(true)}
            >
              request withdrawal
            </button>
          </div>
        </div>
      </div>

      <WithdrawTable />
    </>
  );
};

export default Earnings;
