/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { listExperts } from "../../actions/userActions";
import CourseTypeOne from "./CourseTypeOne";
import Select from "react-select";
import { my_timezones } from "../../utils";

const options = [
  { value: "Mental Health", label: "Mental Health" },
  { value: "Relationship & Marriage", label: "Relationship & Marriage" },
  { value: "Wellness & Lifestyle", label: "Wellness & Lifestyle" },
  { value: "Business and life coach", label: "Business and life coach" },
];

const CourseTypeFilter = ({ itemToShow, showLoadMore, incrementPerClick }) => {
  const [noMorePost, setNoMorePost] = useState(true);
  const [visibleItems, setVisibleItems] = useState([]);
  const [category, setcategory] = useState({});
  const [timezone, setTimezone] = useState({});

  // fetching experts
  const dispatch = useDispatch();
  const expertList = useSelector((state) => state.expertList);
  const { loading, experts } = expertList;

  useEffect(() => {
    dispatch(listExperts({}));
  }, [dispatch]);

  useEffect(() => {
    if (experts) {
      setVisibleItems(experts);
    }
  }, [experts]);
  const filterExperts = async () => {
    if (experts) {
      const results = await experts.filter((expert) => {
        return (
          expert.specialization === category.value ||
          expert.timezone.value === timezone.value
        );
      });
      setVisibleItems(results);
    }
  };
  useEffect(() => {
    filterExperts();
  }, [category, timezone]);

  return (
    <>
      <div
        className="row g-5 align-items-center mb--30"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="col-lg-6">
          <div
            className="section-title text-start"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span className="pre-title">Search by category</span>
            <div
              style={{
                width: "80%",
                borderRadius: "3px",
                background: "#f5f5f5f5",
                color: "#231F40",
                fontWeight: "600",
                border: "none",
                zIndex: 9,
              }}
            >
              <Select
                defaultValue={category}
                onChange={setcategory}
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="section-title text-start time-zone-filter">
            <div
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                marginRight: "0",
                alignItems: "flex-start",
              }}
            >
              <span className="pre-title">Search by Timezone</span>
              <div
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  background: "#f5f5f5f5",
                  color: "#231F40",
                  fontWeight: "600",
                  border: "none",
                  zIndex: 9,
                }}
              >
                <Select
                  defaultValue={timezone}
                  onChange={setTimezone}
                  options={my_timezones}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {visibleItems && visibleItems.length > 0 ? (
        <div className="row g-5">
          {visibleItems.map((item) => (
            <div className="col-6 col-sm-6 col-xl-3 col-md-4" key={item.id}>
              <CourseTypeOne data={item} />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}

      {!loading && visibleItems.length < 1 && (
        <div className="row g-5">
          <div style={{ padding: "3rem" }}>
            <div className="error-bar">No expert found</div>
          </div>
        </div>
      )}

      {loading && visibleItems.length < 1 && (
        <div className="row g-5">
          <div style={{ padding: "3rem" }}>
            <div className="loading-bar">
              <i
                className={"fa fa-spinner fa-spin"}
                style={{ margin: "1rem" }}
              ></i>
              Loading...
            </div>
          </div>
        </div>
      )}

      {showLoadMore === "enable" && (
        <div className="row text-center mt--60">
          <div className="col-lg-12">
            <button
              className="edu-btn"
              // onClick={handleLoadMoreBtn}
              disabled={noMorePost ? "disabled" : null}
            >
              {noMorePost ? (
                "All Experts Displayed"
              ) : (
                <span>
                  Load More
                  <span className="eduvibe-spin-icon">
                    <FaSpinner />
                  </span>
                </span>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseTypeFilter;
