import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostData from "../../data/blog/PostData.json";

const BlogDetailsOne13 = () => {
  const data = PostData[1];
  return (
    <>
      <SEO
        title="Our mission at Balm.ai
"
      />
      <Layout>
        <BreadcrumbOne
          title="Blog"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Our mission at Balm.ai
          "
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group"></div>
                      <ul className="blog-meta">
                        <li className="blog-author">
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                            src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                            alt="Blog Images"
                          />{" "}
                          <Link to={`/NonyelimOkolie`}>Nonyelim Okolie</Link>
                        </li>
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.date}
                        </li>

                        <li>
                          <i className="icon-time-line"></i>
                          {data.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">Our mission at Balm.ai</h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small  mb--30"
                        src={`https://images.pexels.com/photos/6289027/pexels-photo-6289027.jpeg?auto=compress&cs=tinysrgb&w=400`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content">
                    <p>
                      My name is Nonye Ekpe, a co-founder at Balm.ai. We are on
                      a mission to bring happiness to the world. We support
                      founders and professionals by connecting them to solution
                      providers to improve their mental health and productivity.
                    </p>
                    <p>
                      Some founders struggle with their mental health and we
                      believe mental health care needs to be affordable,
                      real-time, and private. We are building a platform that
                      makes it 10x faster to speak with a therapists and
                      experts.
                    </p>
                    <p>
                      Entrepreneurs are in a self-achieving world. Sometimes
                      stress and anxiety can accumulate and lead to burnout.
                      Burnout is related to the workplace specifically.
                      Sometimes, resources don't meet demand and can lead to
                      burnout. Not being able to keep up leads to depression,
                      unhappiness, disability, and other diseases.
                    </p>
                    <p>
                      We connect mental health experts and therapists into an
                      organized, global community, leveraging network effects,
                      trusted peer support, and artificial intelligence. We are
                      leveraging technology to build global collaborations. We
                      collaborate with a global network of founders, investors,
                      partners, therapists, and individuals who share similar
                      interests and ambitions in bringing happiness to the
                      world.{" "}
                    </p>
                    <p>
                      I believe together we can help founders have peace of mind
                      as they innovate and invent the future with their startups
                      and companies. Our big hair audacious health goals at Balm
                      Technologies Inc require radical collaboration, and when
                      achieved, founders will improve the lives of billions of
                      people globally. Balm is on a mission to connect the mind,
                      spirit, and soul of a founder or professional in the
                      pursuit of greatness.
                    </p>
                    <p>
                      We live in a world where over 600 million people suffer
                      from depression and an epidemic of burnout threatens our
                      innovators. These are the world’s best entrepreneurs –
                      people who share transformational mindset principles and a
                      commitment to building a viable economy.{" "}
                    </p>
                    <p>
                      Using telemedicine and smartphones to expand the reach of
                      mental health services, with an understanding that good
                      emotional well-being will positively affect the founder’s
                      quality of life.
                    </p>
                    <p>
                      Mental health care needs to be affordable, real time and
                      private. We are building a platform that makes it 10x
                      faster to speak with a therapist.{" "}
                    </p>
                    <blockquote className="blockquote-style-1">
                      “ Our experts are focused on Life, Business, Career,
                      Welness, Mental health, Relationship, and marriage, To get
                      started, visit{" "}
                      <Link to="/experts/market-place">www.balm.ai</Link> to get
                      tailored advice.”
                    </blockquote>

                    <p>
                      With Balm.ai, you develop the ability to unleash your
                      inner potentials by speaking with experts who are happy to
                      listen to you and provide answers to life’s difficult
                      questions and provide solutions to challenges you are
                      currently experiencing in any aspect of your life.
                    </p>
                  </div>
                  <hr />

                  <div className="blog-author-wrapper">
                    <div className="thumbnail">
                      <img
                        src="https://res.cloudinary.com/balmai/image/upload/v1675095650/balm-web/nonye_fghjfo.jpg"
                        alt="Author Images"
                      />
                    </div>
                    <div className="author-content">
                      <h6 className="title">Nonyelim Okolie</h6>
                      <p>
                        A goal-driven executive, with over eight years of
                        extensive experience in management, and business
                        leadership, executing a business vision that improve
                        performance, profitability, and growth.
                      </p>
                      <ul className="social-share icon-transparent">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/nonye/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-linkedin"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/NonyelimEkpe"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icon-Twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <Link to="/blog/post/Managing-your-psychology-as-a-founder">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>Managing your psychology as a founder</span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <Link to="/blog/post/Our-Innovation-story-at-Balm">
                            <span>Our Innovation story at Balm.ai</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne13;
