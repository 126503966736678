import React, { useState } from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterOne from "../../common/footer/FooterOne";
import "./waitlist.css";
import { toast } from "react-toastify";

const WaitList = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("http://localhost:5000/api/v15/getaccess", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save email.");
        }
        setLoading(false);
        return response.json();
      })
      .then((data) => {
        toast.success(data?.message);
        setEmail("");
        setLoading(false);
        // Handle success, if needed
      })
      .catch((error) => {
        toast.error(error?.message);
        setLoading(false);
        // Handle error, if needed
      });
  };
  return (
    <>
      {" "}
      <SEO
        title="Balm.ai waitlist"
        img="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        link="https://Balm.ai/business"
        description="Find and Schedule clearity session with experts"
      />
      <HeaderTwo styles="header-transparent header-style-2" />
      <div
        style={{ Width: "100vw", paddingTop: "150px", paddingBottom: "150px" }}
      >
        <div className="container">
          <div className="waitlist-wrap">
            <div className="waitlist-left">
              <h4>
                <span>Balm.ai </span>
                is the first AI therapy platform that will revolutionize the way
                we interact with our mental health. With real-time access to our
                confidential, intuitive conversational agents and human experts,
                Balm.ai personalizes your therapy experience for a lifetime.
              </h4>
            </div>
            <div className="waitlist-right">
              <form onSubmit={handleClick}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
                <button
                  type="submit"
                  className="edu-btn btn-medium left-icon header-button"
                >
                  {loading ? "Loading..." : "Join the waitlist"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default WaitList;
