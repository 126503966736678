import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineLogout } from "react-icons/hi";

const ExpertNav = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");

  // redux
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  useEffect(() => {
    if (userInfo) {
      setName(userInfo?.name);
      setImage(userInfo?.profileImg?.url);
    }
  }, [userInfo]);
  return (
    <>
      {userInfo && !userInfo.isExpert ? (
        <ul className="mainmenu" style={{ borderTop: "1px solid #606770" }}>
          <li>
            <div
              className="expert-dashboard-nav-top"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="expert-dashboard-img-wrap"
                style={{ width: "8rem", height: "8rem" }}
              >
                <img
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  src={image}
                  alt="Main profile"
                />
              </div>
              <div
                className="expert-dashboard-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6>{name}</h6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>
              </div>
            </div>
          </li>
          <li className="">
            <Link to="/user-dashboard/appointments">Apointments</Link>
          </li>
          <li className="">
            <Link to="/user-dashboard/profile">Profile Update</Link>
          </li>
          <li className="">
            <Link to="/experts/market-place">Book a session</Link>
          </li>
          <li className="">
            <a
              href="https://drive.google.com/drive/u/4/folders/1fq6_EGde8uGAc6CZnnHXKioA6XZOxb26"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              Resources
            </a>
          </li>
          <li className="">
            <a
              href="https://talk-with-ai-d29.vercel.app/chat"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              Fertility AI Chatbot
            </a>
          </li>
          <li className="">
            <a
              href="mailto:hello@balm.ai"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              Contact support
            </a>
          </li>

          <li className="">
            <Link to="">
              <HiOutlineLogout /> Log out
            </Link>
          </li>
        </ul>
      ) : (
        " "
      )}
    </>
  );
};
export default ExpertNav;
