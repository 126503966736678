import React, { useState, useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../sectionTitle/SectionTitle";

const accordionItems = [
  {
    id: 1,
    title: "What is Balm.ai? ",
    body: "Balm.ai is a comprehensive weight management platform designed to help you achieve sustainable weight loss and improve your overall health and well-being. Balm helps you have a healthier relationship with yourself as well as with your body and the food it consumes.",
  },
  {
    id: 2,
    title: "What makes Balm.ai different?",
    body: "Balm.ai addresses the seven key elements for successful weight loss identified by research: Group Support, Practical Nutrition Education, External Encouragement, Medical Guidance, Meaningful Incentive, Realistic Exercise, and Reducing and Processing Anxiety. Most programs only focus on a few of these elements, limiting their effectiveness.",
  },
  {
    id: 3,
    title: "What are the different subscription options?",
    body: "Balm.ai offers a freemium model with basic features available for free. Premium subscriptions provide access to additional features and personalized support.",
  },
  {
    id: 4,
    title: "How can I get started with Balm.ai?",
    body: "Visit our website at www.balm.ai to learn more and sign up as a customer or as a healthcare provider. Let's regain your confidence together!",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button"
    >
      {children}
    </button>
  );
};

const AboutTwo = () => {
  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <div className="edu-accordion-area eduvibe-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5">
          <div className="col-lg-6 gallery-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="thumbnail img-sm-display-none">
                  <img
                    className="radius-small img-sm-display-none w-100"
                    src="https://res.cloudinary.com/dxjprordi/image/upload/v1654258545/balm/pexels-anna-shvets-4672710_cecxsq.jpg"
                    alt="About Thumb 1"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                <div className="thumbnail ">
                  <img
                    className="radius-small w-100"
                    src="https://res.cloudinary.com/dxjprordi/image/upload/v1654258585/balm/pexels-anna-shvets-5262737_yvuayg.jpg"
                    alt="About Thumb 2"
                  />
                </div>
                <div className="thumbnail mt--15">
                  <img
                    className="radius-small w-100"
                    src="https://res.cloudinary.com/balmai/image/upload/v1665916557/national-cancer-institute-VJVsEnR_vNE-unsplash-min_hsndgu.jpg"
                    alt="About Thumb 3"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <SectionTitle
              classes="text-start mb--40"
              slogan="Frequently Asked Questions"
              title="Get Every General Answers From Here"
            />
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              className="accordion-style-1"
              animateOnce={true}
            >
              <Accordion bsPrefix="edu-accordion" defaultActiveKey="" flush>
                {accordionItems.map((accordion, i) => (
                  <Accordion.Item
                    eventKey={i.toString()}
                    key={i}
                    onClick={() => toggleActive(i.toString())}
                    bsPrefix={`edu-accordion-item ${
                      activeId === i.toString() ? "active-item" : ""
                    }`}
                  >
                    <div className="edu-accordion-header">
                      <CustomToggle eventKey={i.toString()}>
                        {accordion.title}
                      </CustomToggle>
                    </div>
                    <Accordion.Body bsPrefix="edu-accordion-body">
                      {accordion.body}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
