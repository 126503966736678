import React from "react";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import SEO from "../../common/SEO";
import About1 from "./About1";
import About2 from "./About2";
import Hero from "./Hero";
import NextSteps from "./NextSteps";
import OurExperts from "./OurExperts";
import Pricing from "./Pricing";

const ForBusiness = () => {
  const ref = useRef(null);

  return (
    <>
      <SEO
        title="Balm For Business"
        img="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        link="https://Balm.ai/business"
        description="Find and Schedule clearity session with experts"
      />
      <Helmet>
        <meta property="og:title" content="Balm For Business" />
        <meta
          property="og:description"
          content="Find and Schedule clearity session with experts"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        />
        <meta property="og:url" content="https://Balm.ai/business" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Balm For Business" />
        <meta
          name="twitter:description"
          content="Find and Schedule clearity session with experts"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675801709/images_wwerdw.jpg"
        />
      </Helmet>
      <HeaderTwo styles="header-transparent header-style-2" />
      <Hero refToLastComp={ref} />
      <About1 title="PSYCHOLOGICAL SUPPORT FOR MANAGERS AND EMPLOYEES" />
      <About2 title="Training Topics" />
      {/* <About3 title="More Training Topics" color="white" /> */}
      <OurExperts title="MEET SOME OF OUR EXPERTS" />
      {/* <Pricing title="PRICING PLANS" ref={ref} /> */}
      <NextSteps title="Next Steps" />
      <FooterOne />
    </>
  );
};

export default ForBusiness;
