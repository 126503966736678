/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const AccountStatus = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [specialization, setSpecialization] = useState("");

  // redux
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const { _id } = userInfo;

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setImage(userInfo?.profileImg?.url);
      // setStatus(userInfo.accountStatus);
      setSpecialization(userInfo.specialization);
    }
  }, [userInfo]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAccount = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_KEY}expert/accountstatus`,
        { _id },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setStatus(data);
      setLoading(false)
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
    }
  };

  useEffect(() => {
    if (userInfo) {
      fetchAccount();
    }
  }, [userInfo]);
  return (
    <>
      <div className="expert-dashboard-nav-top">
        <div className="expert-dashboard-img-wrap">
          <img src={image} alt="profile" />
        </div>
        <div className="expert-dashboard-details">
          <h6>{name}</h6>
          <p>{specialization}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>Account Status: </p>
        {status && (
          <p
            style={{
              background: "rgba(77, 230, 138, 0.12)",
              borderRadius: "5px",
              color: "green",
              marginLeft: "0.6rem",
              padding: "5px",
            }}
          >
            approved
          </p>
        )}
        {!status && !loading && (
          <p
            style={{
              background: "rgba(242, 17, 54, 0.12)",
              borderRadius: "5px",
              color: "red",
              marginLeft: "0.6rem",
              padding: "5px",
            }}
          >
            pending
          </p>
        )}
      </div>
      {!status && !loading && (
        <p
          style={{
            background: "#ebf1fe",
            borderRadius: "5px",
            color: "#2a7ade",
            margin: "0.6rem",
            padding: "5px",
            textAlign: "center",
          }}
        >
          Please update your profile details, social links and await approved.
          Ignore if already updated.
        </p>
      )}
    </>
  );
};

export default AccountStatus;
