import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listExperts } from "../../actions/userActions";
import { Helmet } from "react-helmet";

import Banner from "../../components/Banner/Banner";
import HomeFiveCategories from "../../components/home-five/HomeFiveCategories";
import HomeThreeCourse from "../../components/home-five/HomeThreeCourse";
// import "./Home.css";
import AboutTwo from "../../components/about/AboutTwo";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import TestimonialSectionTwo from "../../components/testimonial-section/TestimonialSectionTwo";
import NewsLetterFormTwo from "../../components/newsletter/NewsLetterTwo";
import FooterOne from "../../common/footer/FooterOne";
import { useState } from "react";
import HowItWorks from "../../components/howItWorks/HowItWorks";
import { data, data2, data3 } from "./data";

function Home() {
  const [allExpert, setAllExpert] = useState([]);

  // fetching experts
  const dispatch = useDispatch();
  const expertList = useSelector((state) => state.expertList);
  const { experts } = expertList;

  useEffect(() => {
    dispatch(listExperts({}));
  }, [dispatch]);

  useEffect(() => {
    setAllExpert(experts);
  }, [experts]);
  // end fetching experts

  return (
    <div>
      <SEO
        title="Home"
        img="https://res.cloudinary.com/balmai/image/upload/v1675802736/Picture1_v6ab0u.jpg"
        link="https://Balm.ai/"
        description="Bringing Happiness to the World"
      />
      <Helmet>
        <meta property="og:title" content="Home" />
        <meta
          property="og:description"
          content="Bringing Happiness to the World"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675802736/Picture1_v6ab0u.jpg"
        />
        <meta property="og:url" content="https://Balm.ai/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Home" />
        <meta
          name="twitter:description"
          content="Bringing Happiness to the World"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/balmai/image/upload/v1675802736/Picture1_v6ab0u.jpg"
        />
      </Helmet>
      <HeaderTwo styles="header-transparent header-style-2" />
      <Banner />
      {/*  */}
      <HowItWorks data={data} />
      <HowItWorks data={data2} />
      <HowItWorks data={data3} />
      {/*  */}
      <HomeFiveCategories />
      {allExpert && allExpert.length > 0 ? (
        <HomeThreeCourse
          slogan="Top Experts."
          title="Access to the best has never been easier"
          Data={allExpert}
        />
      ) : (
        ""
      )}
      <AboutTwo />

      <TestimonialSectionTwo />
      {/* <HomeThreeEvent /> */}
      <NewsLetterFormTwo />
      <FooterOne />
    </div>
  );
}

export default Home;
